<template>
  <section id="top">
    <div class="container">
      <div class="row">
        <div class="col-6 col-md-3 logo">
          <router-link to="/">
            <img src="../../static/assets/popy-logo.png" alt="PopyCar" class="logo-hizala">
          </router-link>
        </div>
        <div class="col-6 col-md-9 opt">
          <div class="links" style="margin-top:0px !important">

            <a href="tel:4445649" class="social tel">
              <svg style="height:15px" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/></svg>
              444 5 649</a>
            <div class="divider"></div>
            <a href="" class="lngActive mdHidden" title="Türkçe">TR</a>

            <router-link   v-if="!GetServer?.member" class ="mLogin" to="/uye-girisi" style="margin-top:0px"><button id="btnLogin" v-if="!GetServer?.member" type="button" >
                     <svg style="height:15px" fill="red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"/></svg>
 Giriş</button></router-link>


            <router-link v-if="GetServer && GetServer.member" to="/panelim"><a  class="mLogin" title="Panelim" ><svg style="height:10px" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"/></svg> </a></router-link>
            <a v-if="GetServer && GetServer.member" class="mLogin" v-on:click="LogoutMethod"><i class="fas fa-sign-out-alt"></i> </a>
            <!--<a href="" class="lng" title="English">EN</a>-->
          </div>
          <div class="rMenu">
            <a style="margin-top:-10px" href="javascript:void(0)" v-on:click="toggleMenu" class="btnMenu ">
              <svg style="height:45px; " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path v-if="MenuIsOpen"  fill="white" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
              <path v-else fill="white" d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/>

              </svg>
              </a>
          </div>

          <div class="rUser">
            <router-link   v-if="!GetServer?.member" to="/uye-girisi"><button id="btnLogin" v-if="!GetServer?.member" type="button" >
                     <svg style="height:15px" fill="red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"/></svg>
 Giriş Yap</button></router-link>
            <router-link   v-if="!GetServer?.member" to="/uye-ol"><button type="button" id="btnRegister"><i class="far fa-user"></i> Üye Ol</button></router-link>

            <router-link v-if="GetServer && GetServer.member" to="/panelim"><button type="button" id="btnLogin"><i class="fas fa-user-alt"></i> Panelim</button></router-link>
            <button v-if="GetServer && GetServer.member" type="button" id="btnRegister" v-on:click="LogoutMethod"><i class="fas fa-sign-out-alt"></i> Çıkış</button>
          </div>
        </div>
        <aside v-bind:class="{ 'open': MenuIsOpen }">
        <nav>
          <ul>
            <li @click="tagManagerMainClik('Ana Sayfa','/','1')"><router-link @click.native="closeMenu()" to="/" class="btnMenu whiteLink">Ana Sayfa</router-link></li>
            <li @click="tagManagerMainClik('Üye Ol','uye-ol','2')"><router-link @click.native="closeMenu()" to="/uye-ol" class="btnMenu whiteLink">Üye Ol</router-link></li>
            <li @click="tagManagerMainClik('Hakkımızda','hakkimizda','2')"><router-link @click.native="closeMenu()" to="/hakkimizda" class="btnMenu whiteLink">Hakkımızda</router-link></li>
            <li @click="tagManagerMainClik('Filo','filo','5')"><router-link @click.native="closeMenu()" to="/filo" class="btnMenu whiteLink">Araçlar</router-link></li>
            <li @click="tagManagerMainClik('Kampanyalar','kampanyalar','3')"><router-link @click.native="closeMenu()" to="/kampanyalar" class="btnMenu whiteLink">Kampanyalar</router-link></li>
            <li @click="tagManagerMainClik('Ayın Fırsatları','ayin-firsatlari','11')"><router-link @click.native="closeMenu()" to="/ayin-firsatlari" class="btnMenu whiteLink">Ayın Fırsatları</router-link></li>
            <li @click="tagManagerMainClik('İş Birlikleri','is-birlikleri','12')"><router-link @click.native="closeMenu()" to="/is-birlikleri" class="btnMenu whiteLink">İş Birlikleri</router-link></li>
            <li @click="tagManagerMainClik('Duyurular','duyurular','13')"><router-link @click.native="closeMenu()" to="/duyurular" class="btnMenu whiteLink">Duyurular</router-link></li>
            <li @click="tagManagerMainClik('Şubeler','subeler','4')"><router-link @click.native="closeMenu()" to="/subeler" class="btnMenu whiteLink">Şubeler</router-link></li>
            <li @click="tagManagerMainClik('Bireysel Kiralama Hizmeti','bireysel-kiralama-hizmeti','6')"><router-link @click.native="closeMenu()" to="/bireysel-kiralama-hizmeti" class="btnMenu whiteLink">Hizmetler</router-link></li>
            <li @click="tagManagerMainClik('Kurumsal Kiralama','kurumsal-kiralama','7')"><router-link @click.native="closeMenu()" to="/kurumsal-kiralama" class="btnMenu whiteLink">Kurumsal Kiralama</router-link></li>
            <li @click="tagManagerMainClik('Bayimiz Olun','bayimiz-olun','8')"><router-link @click.native="closeMenu()" to="/bayimiz-olun" class="btnMenu whiteLink">Bayimiz Olun</router-link></li>
            <li @click="tagManagerMainClik('Sıkça Sorulan Sorular','sss','9')"><router-link @click.native="closeMenu()" to="/sss" class="btnMenu whiteLink">Sıkça Sorulan Sorular</router-link></li>
            <li @click="tagManagerMainClik('İletişim','iletisim','10')"><router-link @click.native="closeMenu()" to="/iletisim" class="btnMenu whiteLink">İletişim</router-link></li>
            <!-- <li><router-link @click.native="closeMenu()" to="/kvkk" class="btnMenu whiteLink">Kişisel Verilerin Korunması Kanunu</router-link></li> -->
            <!-- <li><router-link @click.native="closeMenu()" to="/gizlilik" class="btnMenu whiteLink">Gizlilik</router-link></li> -->
            <!-- <li><router-link @click.native="closeMenu()" to="/cerez-politikasi" class="btnMenu whiteLink">Çerez Politikası</router-link></li> -->
            <!-- <li><router-link @click.native="closeMenu()" to="/etik-kurallar" class="btnMenu whiteLink">Etik Kurallar</router-link></li> -->
          </ul>
        </nav>
      </aside>
      </div>
    </div>
    <!--<div right width="400">
      <router-link to="/" ><span>Ana Sayfa</span></router-link>
      <router-link to="/bayimiz-olun" ><span>Bayimiz Olun</span></router-link>
      <router-link to="/subeler" ><span>Şubeler</span></router-link>
      <router-link to="/araclar" ><span>Araçlar</span></router-link>
      <router-link to="/kampanyalar" ><span>Kampanyalar</span></router-link>
      <router-link to="/hizmetler" ><span>Hizmetler</span></router-link>
      <router-link to="/hakkimizda" ><span>Hakkımızda</span></router-link>
      <router-link to="/iletisim" ><span>İletişim</span></router-link>
      <router-link to="/kvkk" ><span>Kişisel Verilerin Korunması</span></router-link>
      <router-link to="/gizlilik" ><span>Gizlilik</span></router-link>
      <router-link to="/cerezler" ><span>Çerez Politikası</span></router-link>
      <router-link to="/etik-kurallar" ><span>Etik Kurallar</span></router-link>
      <router-link to="/sss" ><span>Sıkça Sorulan Sorular</span></router-link>

    </div>-->
  </section>

</template>

<script>
import axios from 'axios'
import store from '../store'
import {mapState} from 'vuex'
export default {
  name: 'Top',
  data: () => {
    return {
      isLoading: true,
      fullPage: false,
      loader: 'bars',
      color: '#d2202f',
      MenuIsOpen: false
    }
  },
  computed: mapState({
    GetServer: state => state.GetServerData,

  }),
  methods: {
    LogoutMethod (e) {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        loader: this.loader,
        color: this.color,
        opacity: 0.90,
        height: this.height,
        width: this.width
      })
      axios.get(process.env.VUE_APP_API_URL+'logout.php', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }}).then(response => {
        this.$forceUpdate();
        loader.hide()
        response.data.basarili==1 && this.$router.history.current.path=='/' ? window.location.reload() : this.$router.push('/')
      })
    },
    closeMenu() {
      this.MenuIsOpen=false

            },
    openMenu() {
      this.MenuIsOpen=true
    },
    toggleMenu() {
      this.MenuIsOpen=!this.MenuIsOpen
    },
    tagManagerMainClik(Name,Url,Id) {
    // this.$gtm.trackEvent({
    //   event: "mainMenuClicks",
    //     mainmenuinfo: {
    //       menutext: Name,
    //           menuurl: "http://popy-php.localhost/"+Url,
    //           columnid: Id
    //     }
    // });
  }
  }
}
</script>
<style>
.whiteLink{
  color: #ffffff!important;
}
</style>
