<template>
  <section id="subPage">
    <div class="container cont contBox">
      <div class="row ">
        <div class="col-md-12">
            <nav class="navbar navbar-expand-lg navbar-light">
            <span class="navbar-brand" href="#">İletişim</span>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><span class="nav-link disabled" >İletişim</span></li>
              </ul>
            </div>
          </nav>
        </div>
         <hr>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <md-card>
                    <md-card-header>
                        <div class="md-title">İLETİŞİM BİLGİLERİ</div>
                    </md-card-header>

                    <md-card-content>
                        <p><strong>Genel Müdürlük</strong></p>
                        <p class="contact">
                            <span>Fatih Sultan Mehmet Mah. Poligon Cd. BuYaka Kuleler Kule:3 Kat:6 Daire No 37-42 Ümraniye/İstanbul</span>
                            <br>
                            <span>Genel İletişim: <strong><a href="mailto:info@popycar.com">info@popycar.com</a></strong></span>
                            <br>
                            <span>Kurumsal Kiralama: <strong><a href="mailto:kurumsalkiralama@popycar.com">kurumsalkiralama@popycar.com</a></strong></span>
                        </p>
                        <hr>
                        <p><strong>Rezervasyon</strong></p>
                        <p class="contact">
                            <span><strong><a href="tel:4445649">444 56 49</a></strong></span>
                            <br>
                            <span>Bireysel Kiralama: <strong><a href="mailto:popy@popycar.com">popy@popycar.com</a></strong></span>
                        </p>
                        <hr>
                        <p><strong> Yol Yardım</strong></p>
                        <p class="contact">
                            <span><strong><a href="tel:4445649">444 56 49</a></strong></span>
                            <br>
                        </p>
                        <hr>
                        <p><strong>Yurtdışı Aramaları</strong></p>
                        <p class="contact">
                            <span><strong><a href="tel:+4445649">444 56 49</a></strong></span>
                            <br>
                        </p>
                    </md-card-content>
                    </md-card>
                </div>
            </div>
        </div>
        <div class="col-md-6">
           <div class="row">
            <div class="col-md-12">
                <md-card>
                <md-card-header>
                    <div class="md-title">İLETİŞİM FORMU</div>
                </md-card-header>
                <md-card-content>
                  <div class="alert alert-success" role="alert" v-if="hataMesajiDurum==1 && hataMesaji!=''">
                    <strong>{{hataMesajiBaslik}}</strong> {{hataMesaji}}
                  </div>
                  <div class="alert alert-danger" role="alert" v-if="hataMesajiDurum==0 && hataMesaji!=''">
                    <strong>{{hataMesajiBaslik}}</strong> {{hataMesaji}}
                  </div>
                  <form @submit.prevent="handleSubmit" v-if="hataMesajiDurum==0">
                      <div class="row" ref="formContainer">
                          <div class="col">
                              <div class="form-row">
                                  <div class="form-group col-md-6">
                                      <label for="ad">AD*</label>
                                      <input type="text" v-model="user.ad" class="form-control" id="ad" name="ad" :class="{ 'is-invalid': submitted && $v.user.ad.$error }">
                                      <div v-if="submitted && !$v.user.ad.required" class="invalid-feedback">Adınızı giriniz</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                      <label for="soyad">SOYAD*</label>
                                      <input type="text" v-model="user.soyad" class="form-control" id="soyad" name="soyad" :class="{ 'is-invalid': submitted && $v.user.soyad.$error }" >
                                      <div v-if="submitted && !$v.user.soyad.required" class="invalid-feedback">Soyadınızı giriniz</div>
                                  </div>
                              </div>

                              <div class="form-row">
                                  <div class="form-group col-md-6">
                                      <label for="eposta">E-POSTA ADRESİ*</label>
                                      <input type="email" v-model="user.eposta" class="form-control" id="eposta" name="eposta" :class="{ 'is-invalid': submitted && $v.user.eposta.$error }">
                                      <div v-if="submitted && $v.user.eposta.$error" class="invalid-feedback">
                                          <span v-if="!$v.user.eposta.required">E-posta adresinizi giriniz</span>
                                          <span v-if="!$v.user.eposta.email">Geçerli bir e-posta adresi giriniz</span>
                                      </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                      <label for="gsm">TELEFON NUMARASI*</label>
                                      <input type="tel" v-model="user.gsm" class="form-control" id="gsm" name="gsm" :class="{ 'is-invalid': submitted && $v.user.gsm.$error }">
                                      <div v-if="submitted && !$v.user.gsm.required" class="invalid-feedback">Telefon numaranızı giriniz</div>
                                  </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-md-12">
                                  <label for="konu">KONU*</label>
                                    <select name="konu" v-model="user.konu" id="konu" class="form-control" :class="{ 'is-invalid': submitted && $v.user.konu.$error }">
                                    <option value="">Konu Seçiniz</option>
                                    <option value="Rezervasyon">Rezervasyon</option>
                                    <option value="Talep, Öneri, Şikayet">Talep, Öneri, Şikayet </option>
                                  </select>
                                  <div v-if="submitted && !$v.user.konu.required" class="invalid-feedback">Konu seçiniz</div>
                              </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-md-12">
                                      <label for="mesaj">MESAJINIZ*</label>
                                      <textarea class="form-control" v-model="user.mesaj" id="mesaj" name="mesaj" :class="{ 'is-invalid': submitted && $v.user.mesaj.$error }"></textarea>
                                      <div v-if="submitted && !$v.user.mesaj.required" class="invalid-feedback">Mesajınızı Yazınız</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                            <div class="col">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <button type="submit" class="btnRed float-right" style="padding-top:4px!important;">Gönder <i class="fas fa-long-arrow-alt-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </form>
                </md-card-content>
                </md-card>
            </div>
           </div>
        </div>
      </div>
         <div class="row map">
        <div class="col-md-4 align-self-center">
          <h4>Harita Görünümü</h4>
          <a target="_blank" :href="'http://maps.google.com/maps?q=' +markers.lat+',' + markers.lng" class="redLink"  title="">Haritayı büyük görüntüle<i class="fas fa-long-arrow-alt-right"></i></a>
        </div>
        <div class="col-md-8">
          <div id="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1504.956881219897!2d29.124038716412695!3d41.02714260874559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac8d8f38b5e1d%3A0xf83e93f3cee26a84!2sPopy%20Rent%20a%20Car%20-%20BuYaka%20Ofisi!5e0!3m2!1str!2str!4v1657779064153!5m2!1str!2str" width="800" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'
export default {
  name: "contact",
  metaInfo: () => ({
    title: 'İletişim',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'PopyCar genel müdürlüğü adres, telefon bilgileri ve iletişim formu üzerinden bize ulaşabilirsiniz.' }
    ]
  }),
  data: () => ({
      hataMesajiDurum: '',
      hataMesaji: false,
      hataMesajiBaslik: false,
      isLoading: false,
      fullPage: true,
      loader: 'bars',
      color: '#d2202f',
      user: {
          ad: '',
          soyad: '',
          eposta: '',
          gsm: '',
          konu: '',
          mesaj: ''
      },

        submitted: false,
        center: { lat: 41.010794, lng: 29.037845 },
        zoom: 16,
        markers: { lat: 41.010794, lng: 29.037845 },
        mapLoaded: true,
    }),
    validations: {
      user: {
        ad: { required },
        soyad: { required },
        eposta: { required, email },
        gsm: { required },
        konu: { required },
        mesaj: { required }
      }
    },
  methods: {
    handleSubmit (e) {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        loader: this.loader,
        color: this.color,
        opacity: 0.90,
      })
      var params = JSON.stringify(this.user);
      axios.post(process.env.VUE_APP_API_URL+'iletisim.php', params, {withCredentials: true, headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      }}).then(response => {
        loader.hide()
        this.hataMesajiDurum=response.data.basarili
        this.hataMesaji=response.data.mesaj
        this.hataMesajiBaslik=response.data.baslik

        /** İLETİŞİM FORMU BAŞARILI İSE ÇALIŞACAK */
        // if (response.data.basarili==1){
        //   this.$gtm.trackEvent({
        //     event: "getMessage",
        //       getmessageinfo: {
        //             name: this.user.ad,
        //             surname: this.user.soyad,
        //             title: this.user.konu,
        //             'e-mailaddress': this.user.eposta,
        //             phone: this.user.gsm,
        //             message: this.user.mesaj,
        //       }
        //   })
        // }
      })
    }
  }
}
</script>

<style scoped>
.row.map{
  margin-top: 50px;
}
  .videoClass{
    width: 100%;
    height: 390px;
  }
  iframe:not(.md-image) {
    height: 315px!important;
}
</style>
