<template>
  <section id="subPage">
    <div class="container cont">
      <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand">{{ $route.name}}</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">{{ $route.name}}</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6" style="padding-top:3%; padding-left:10%">
            <router-link to="/uye-ol"><img src="../../static/popy_giris.png"></router-link>
          </div>
        <div class="col-md-6">
          <div class="modal-body">
            <form @submit.prevent="LoginMethod" class="vld-parent" ref="formContainer">
              <div class="form-group col-md-12">
                <label for="eposta">E-POSTA ADRESİNİZ</label>
                <input type="email" v-model="user.eposta" class="form-control" id="eposta" name="eposta" placeholder="E-posta adresi" :class="{ 'is-invalid': submitted && $v.user.eposta.$error }">
                <div v-if="submitted && $v.user.eposta.$error" class="invalid-feedback">
                  <span v-if="!$v.user.eposta.required">E-posta adresinizi giriniz</span>
                  <span v-if="!$v.user.eposta.email">Geçerli bir e-posta adresi giriniz</span>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="Password">PAROLANIZ</label>
                <input type="password" v-model="user.Password" class="form-control" id="Password" name="Password" placeholder="Şifrenizi girin" :class="{ 'is-invalid': submitted && $v.user.Password.$error }">
                <div v-if="submitted && $v.user.Password.$error" class="invalid-feedback">
                  <span v-if="!$v.user.Password.required">Parolanızı oluşturunuz</span>
                </div>
              </div>
                <div v-if="wrongPassword">
                  <div class="alert alert-danger">Hatalı e-posta ve parola girdiniz.</div>
                </div>
              <div class="form-group col-md-12">
                <button type="submit" class="btn btnRed" style="padding-top:4px !important;">GİRİŞ YAP</button>
                <button type="button" class="btn btnGray" style="padding-top:4px !important;" @click="uyeOlmadanDevam()">ÜYE OLMADAN DEVAM ET</button>
              </div>
              <div class="form-group text-center">
                <router-link to="/uye-ol" class="btnMenu">Üye Ol</router-link><br>
                <router-link to="/parolami-unuttum" class="btnMenu">Parolamı Unuttum</router-link>
              </div>
            </form>
          </div>
          </div>
          </div>
      </form>
    </div>
  </section>
</template>
<script>
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import axios from 'axios';
import store from '../store';

axios.defaults.withCredentials = true;

export default {
  name: 'login',
  metaInfo: () => ({
    title: 'Üye Girişi',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Üye Girişi' }
    ]
  }),
  data: () => {
    return {
      user: {
        eposta: '',
        Password: ''
      },
      submitted: false,
      isLoading: true,
      fullPage: false,
      loader: 'bars',
      color: '#d2202f',
      wrongPassword: false,
      uyeOlmadanDevamEt: '1'
    }
  },
  validations: {
    user: {
      eposta: { required, email },
      Password: { required }
    }
  },
  methods: {
    LoginMethod (e) {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid){
        return
      }
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        loader: this.loader,
        color: this.color,
        opacity: 0.90,
        height: this.height,
        width: this.width
      })
      var params = JSON.stringify(this.user)
      axios.post(process.env.VUE_APP_API_URL+'login.php', params,{headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      }}).then(response => {
        if (response.data.basarili==1){
        /** AŞAĞIDAKİ ÜYE GİRİŞ BAŞARILI İSE ÇALIŞACAK */
          // this.$gtm.trackEvent({
          //   event: "loginSuccess",
          //     registerinfo: {
          //       status: "Success"
          //     }
          // })
          this.$router.push(this.$router.back())
        } else {
          this.wrongPassword=true
        }
        loader.hide()
      })
    },
    uyeOlmadanDevam(){
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        loader: this.loader,
        color: this.color,
        opacity: 0.90,
        height: this.height,
        width: this.width
      })
      var params = JSON.stringify(this.uyeOlmadanDevamEt)

    var _this = this

      axios.get(process.env.VUE_APP_API_URL+'login.php?uyeOlmadanDevamEt='+this.uyeOlmadanDevamEt,{withCredentials: true, headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      }}).then(response => {
       // this.$router.push(this.$router.back())
         if (response.data.basarili==1){
          store.commit('UyeOlmadanDevamFunc', { uyeOlmadanDevamEt: 1});
          this.$router.push(this.$router.back())
        } else {
          this.wrongPassword=true
        }
        loader.hide()
      })
    }
  }
}
</script>

<style scoped>

</style>
