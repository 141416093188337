<template>
  <footer>
    <section id="hastag" class="">
      <div class="container">
        <div class="row vertical-center">
          <div class="col-md-6 text-center">
            <p>
              <a href="tel:4445649" class="whiteLink"
                ><span><i class="fas fa-phone-alt"></i> 444 5 649</span></a
              >
            </p>
          </div>
          <div class="col-md-6 text-center">
            <p>
              <a href="mailto:info@popycar.com" class="whiteLink"
                ><span
                  ><i class="fas fa-envelope"></i> info@popycar.com</span
                ></a
              >
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="light">
      <div class="container">
        <h4 class="text-center" style="margin-bottom: 20px">
          Araç Kiralama Şubeleri
        </h4>
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <ul>
              <li
                v-for="result in sonuc[0]"
                v-bind:data="result"
                v-bind:key="result.Id"
              >
                <router-link :to="`/${result.Title_SEO}/${result.Id}`">{{
                  result.StationName
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-xs-12">
            <ul>
              <li
                v-for="result in sonuc[1]"
                v-bind:data="result"
                v-bind:key="result.Id"
              >
                <router-link :to="`/${result.Title_SEO}/${result.Id}`">{{
                  result.StationName
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-xs-12">
            <ul>
              <li
                v-for="result in sonuc[2]"
                v-bind:data="result"
                v-bind:key="result.Id"
              >
                <router-link :to="`/${result.Title_SEO}/${result.Id}`">{{
                  result.StationName
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-xs-12">
            <ul>
              <li
                v-for="result in sonuc[3]"
                v-bind:data="result"
                v-bind:key="result.Id"
              >
                <router-link :to="`/${result.Title_SEO}/${result.Id}`">{{
                  result.StationName
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="dark">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-xs-12">
            <ul>
              <li @click="tagManagerFooterClik('Ana Sayfa', '/', '1')">
                <router-link to="/" class="btnMenu whiteLink"
                  >Ana Sayfa</router-link
                >
              </li>
              <li
                @click="
                  tagManagerFooterClik('Bayimiz Olun', 'bayimiz-olun', '2')
                "
              >
                <router-link to="/bayimiz-olun" class="btnMenu whiteLink"
                  >Bayimiz Olun</router-link
                >
              </li>
              <li @click="tagManagerFooterClik('Şubeler', 'subeler', '3')">
                <router-link to="/subeler" class="btnMenu whiteLink"
                  >Şubeler</router-link
                >
              </li>
              <!-- li @click="tagManagerFooterClik('Araçlar','araclar','4')"><router-link to="/filo" class="btnMenu whiteLink">Filo</router-link></li> -->
            </ul>
          </div>
          <div class="col-md-4 col-xs-12">
            <ul>
              <li
                @click="tagManagerFooterClik('Kampanyalar', 'kampanyalar', '5')"
              >
                <router-link to="/kampanyalar" class="btnMenu whiteLink"
                  >Kampanyalar</router-link
                >
              </li>
              <li
                @click="
                  tagManagerFooterClik(
                    'Ayın Fırsatları',
                    'ayin-firsatlari',
                    '13'
                  )
                "
              >
                <router-link to="/ayin-firsatlari" class="btnMenu whiteLink"
                  >Ayın Fırsatları</router-link
                >
              </li>
              <li
                @click="
                  tagManagerFooterClik(
                    'Hizmetler',
                    'bireysel-kiralama-hizmeti',
                    '6'
                  )
                "
              >
                <router-link
                  to="/bireysel-kiralama-hizmeti"
                  class="btnMenu whiteLink"
                  >Hizmetler</router-link
                >
              </li>
              <li
                @click="tagManagerFooterClik('Hakkımızda', 'hakkımızda', '7')"
              >
                <router-link to="/hakkimizda" class="btnMenu whiteLink"
                  >Hakkımızda</router-link
                >
              </li>
              <li @click="tagManagerFooterClik('İletişim', 'iletisim', '8')">
                <router-link to="/iletisim" class="btnMenu whiteLink"
                  >İletişim</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-md-4 col-xs-12">
            <ul>
              <li
                @click="
                  tagManagerFooterClik(
                    'Kişisel Verilerin Korunması Kanunu',
                    'kvkk',
                    '9'
                  )
                "
              >
                <router-link to="/kvkk" class="btnMenu whiteLink"
                  >KİŞİSEL VERİLERİN KORUNMASI</router-link
                >
              </li>
              <li @click="tagManagerFooterClik('Gizlilik', 'gizlilik', '10')">
                <router-link to="/gizlilik" class="btnMenu whiteLink"
                  >GİZLİLİK</router-link
                >
              </li>
              <li
                @click="
                  tagManagerFooterClik('Sıkça Sorulan Sorular', 'sss', '12')
                "
              >
                <router-link to="/sss" class="btnMenu whiteLink"
                  >Sıkça Sorulan Sorular</router-link
                >
              </li>
              <li>
                <router-link
                  to="/genel-kiralama-kosullari"
                  class="btnMenu whiteLink"
                  >Genel Kiralama Koşulları</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p>
              <span>Tüm Hakları Saklıdır.</span> Copyright 2022 © POPYCAR Car
              Rental
            </p>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import axios from 'axios'
export default {
name: "Footer",
data: () => ({
  sonuc: []
}),
mounted() {
    axios.get(process.env.VUE_APP_API_URL+'lokasyonlar.php?lokasyonList=1&Parcala',{withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
      }}).then(response => {
        if(response?.data?.length>0){
          this.sonuc = response.data;
        }
      });
},
methods: {
  // tagManagerFooterClik(Name,Url,Id) {
  //   // this.$gtm.trackEvent({
  //   //   event: "footerMenuClicks",
  //   //   footermenuinfo: {
  //   //     menutext: Name,
  //   //         menuurl: "https://www.popycar.com/"+Url,
  //   //         columnid: Id
  //   //   }
  //   // });
  // }
}
}
</script>
