<template>
  <section id="subPage">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="carFilter">
            <form>
              <div class="form-group">
                <label for="carGear">VİTES</label>
                <select class="form-control" id="carGear" v-model="transmission">
                  <option value="all">Seçim Yapılmadı</option>
                  <option value="Otomatik">Otomatik</option>
                  <option value="Manuel">Manuel</option>
                  <option value="Yarı Otomatik">Yarı Otomatik</option>
                </select>
             <!--    <label for="carGear">Vites</label>
                <md-select v-model="transmission" name="transmission" id="transmission">
                  <md-option value="all">Seçim Yapılmadı</md-option>
                  <md-option value="Otomatik">Otomatik</md-option>
                  <md-option value="Manuel">Manuel</md-option>
                  <md-option value="Yarı Otomatik">Yarı Otomatik</md-option>
                </md-select>-->
              </div>
              <div class="form-group">
                <label for="carFuel">YAKIT TİPİ</label>
                <select class="form-control" id="carFuel" v-model="fueltype">
                  <option value="all">Seçim Yapılmadı</option>
                  <option value="Dizel">Dizel</option>
                  <option value="Benzin">Benzin</option>
                </select>
              </div>
              <div class="form-group">
                <label>SINIF</label>
                <select class="form-control" id="carClass" v-model="segment">
                  <option value="all">Seçim Yapılmadı</option>
                  <option value="B">B Segment</option>
                  <option value="B+">B+ Segment</option>
                  <option value="C">C Segment</option>
                  <option value="C+">C+ Segment</option>
                  <option value="D">D Segment</option>
                  <option value="D+">D+ Segment</option>
                  <option value="E">E Segment</option>
                  <option value="E+">E+ Segment</option>
                  <option value="Luxury">Luxury</option>
                  <option value="SUV">SUV</option>
                </select>
              </div>
              <div class="form-group">
                <label for="carPessenger">YOLCU KAPASİTESİ</label>
                <select class="form-control" id="carPessenger" v-model="passengercapacity">
                  <option value="all">Seçim Yapılmadı</option>
                  <option value="4">4 Kişilik</option>
                  <option value="5">5 Kişilik</option>
                </select>
              </div>
              <div class="form-group">
                <label for="carLuggage">BAGAJ KAPASİTESİ</label>
                <select class="form-control" id="carLuggage" v-model="baggagecapacity">
                  <option value="all">Seçim Yapılmadı</option>
                  <option value="3">3 Valiz</option>
                  <option value="4">4 Valiz</option>
                  <option value="5">5 Valiz</option>
                </select>
              </div>
              <!-- <div class="form-group">
                <label for="carLuggage">Fiyat Aralığı (Günlük)</label>
                <input type="text" class="js-range-slider" name="my_range" value="" />
                <input type="hidden" id="carPriceFrom"><input type="hidden" id="carPriceTo">
                <div class="minmax">
                  <div id="carPriceFromLabel"></div>
                  <div id="carPriceToLabel"></div>
                </div>
              </div> -->
            </form>
          </div>
        </div>
        <div class="col-md-8 cont">
          <div  v-for="result in filteredCars" v-bind:data="result" v-bind:key="result.Id">
          <md-toolbar class="md-transparent car">
            <div class="md-toolbar-row">
              <h3 class="md-title">{{result.SubGroupName}} <small>veya benzeri</small></h3>

            </div>
            <div class="md-layout md-gutter md-alignment-center">
            <div class="md-layout-item pic md-small-size-100 md-xsmall-size-100 ">
              <img v-bind:src="`${result.imageUrl}`" v-bind:alt="`Kiralık ${result.SubGroupName} ${result.FuelType} ${result.Transmission}`" style="">
            </div>
            <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
              <ul>
                <li class="fuelType">{{result.FuelType}}</li>
                <li class="gear">{{result.Transmission}} Vites</li>
                <li class="age">+{{result.DriverMinAge}} Yaş</li>
                <li class="license">+{{result.DriverMinLicenceYear}} Yıl Sürücü Belgesi</li>
                <li class="luggage">{{result.BaggageCapacity}} B</li>
                <li class="pessenger">{{result.PassengerCapacity}} Kişi</li>
              </ul>
              <span class="newPrice float-left">{{result.ProvisionAmountTRY}} TL<i>Provizyon Tutarı</i></span>
              <router-link class="btnRed float-right" to="/" >ŞİMDİ KİRALA <i class="fas fa-long-arrow-alt-right"></i></router-link>
            </div></div>
          </md-toolbar>
          </div>


          <div class="row" v-if="!filteredCars.length">
            <div class="col-md-12">
              <md-empty-state
                md-label="Araç Bulunamadı"
                md-description="Aradığınız kriterlere uygun araç bulunamadı lütfen farklı seçimlerle ilerleyiniz.">
              </md-empty-state>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
export default {
  name: "fleet",
  metaInfo: () => ({
    title: 'Araç Filosu',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: "PopyCar'a ait araç filosu hakkında bilgi almak, araçları incelemek ve aradığınız aracı kolayca bulmak için tıklayın!" }
    ]
  }),
  data: () => ({
    sonuc: [],
    isLoading: false,
    fullPage: true,
    loader: 'bars',
    color: '#d2202f',
    transmission: 'all',
    fueltype: 'all',
    passengercapacity: 'all',
    segment: 'all',
    baggagecapacity: 'all'
  }),
  mounted () {
    let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
    });
    axios.get(process.env.VUE_APP_API_URL+'araclar.php', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }}).then(response => {
      this.sonuc = response.data;
      loader.hide()
    });
  },
  computed: {
    filteredCars: function() {
      var Transmission = this.transmission;
      var FuelType = this.fueltype;
      var PassengerCapacity = this.passengercapacity;
      var Segment = this.segment;
      var BaggageCapacity = this.baggagecapacity;
      if(Transmission === 'all' && FuelType === 'all' && PassengerCapacity === 'all' && Segment === 'all' && BaggageCapacity === 'all') {
        return this.sonuc;
      } else {
        return this.sonuc.filter(function(result) {
          return (Transmission === 'all' || result.Transmission === Transmission) && (FuelType === 'all'  || result.FuelType === FuelType) && (PassengerCapacity === 'all'  || result.PassengerCapacity === PassengerCapacity) && (Segment === 'all'  || result.Segment === Segment) && (BaggageCapacity === 'all'  || result.BaggageCapacity === BaggageCapacity);
        });
      }
    }
  }
}
</script>
