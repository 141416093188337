<template>
<section id="subPage">
    <div class="container cont contBox">
        <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">ETİK KURALLAR</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Etik Kurallar</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
        <div class="row box">
            <div class="col-md-12">
<p><b>ETİK KURALLAR</b></p>
<p>PopyCar Oto Kiralama A.Ş, tüm ilişkilerini ve işlemlerini “İş Etik Kurallarına” uyumlu şekilde
 gerçekleştirmeyi görev edinmiştir. Bu doğrultuda, Vizyonumuz, araç kiralama sektörünün müşteri
 memnuniyeti yüksek, en güvenilir, değer yaratan, kaliteden ödün vermeden marka bilinirliliğini
 artırmaktır.</p>
<p>Şirket, tüm iş süreçlerinde, ilişkilerinde, her türlü iş ve faaliyetlerinde uygulanması için aşağıda “İş
Etik Kuralları”nı belirtmiştir. “İş Etik Kuralları” PopyCar Oto Kiralama bünyesindeki çalışan ve
yöneticilerin benimsemesi ve uygulaması beklenen, şirket politikaları ve diğer uygulamaları
tamamlayan, disiplin süreçlerini düzenleyen kurallar bütünüdür.</p>
<p><b>GENEL İLKELER</b></p>
<p>- PopyCar Oto Kiralama A.Ş. olarak, tüm iş faaliyetlerimizde adil, dürüst ve şeffaflık ile hareket ederiz.</p>
<p>- Sunulan ürün ve hizmetlerde asla bilerek başkalarına zarar verilmez,</p>
<p>- Müşteri güvenini kazanmak öncelikli değerimiz arasındadır,</p>
<p>- Hediye ve usulsüzlük izlenimi yaratacak hiçbir faaliyet kabul edilmez,</p>
<p>- İş ortaklarımızın haklarına saygılı hareket eder,</p>
<p>- Paydaşlarımız ile ilişkilerde ilgili yasalar ve yönetmelikler uygun hareket etmek,</p>
<p>- Mali tablolara ve şirket politikalarına ilişkin doğru bilginin zamanında paylaşılması,</p>
<p>- Rakipler ve kuruluşlar ile ilişkilerinde dikkatli ve özenli davranır,</p>
<p>- Yasadışı ya da suç oluşturacak faaliyetlere girmez.</p>
<p>- PopyCar Oto Kiralama tüm faaliyetlerinde yasalara uygun şekilde gerçekleştirir,</p>
<p>- Yasal yükümlülüklerin eksiksiz ve zamanın gerçekleşmesi için gerekli önlemleri alır,</p>
<p>- Siyasi partilere ya da adaylara bağış vb. yardımlarda bulunulamaz,</p>
<p>- İş süreçlerinde edinilen özel bilgiler zorunlu haller dışında üçüncü şahıslar ile paylaşılmaz.</p>
<p>- Her türlü müşteri şikâyeti en kısa sürede çözüme kavuşturulması için Müşteri Şikâyetleri
Birimi’ne iletilir.</p>
<p>- Müşterilere sağlanan hizmet ve ürünler konusunda verilen tüm sözlere ve anlaşmalara uyar.</p>
<p>- Çevreye önem veren, çevre dostu faaliyetlerin yaygınlaştırılmasına destek vermek,</p>
<p><b>ÇALIŞANLARA İLİŞKİN İLKELER</b></p>

<p>- Kanunlara, mevzuata ve şirket içi politikalara uygun hareket etmek,</p>

<p>İş süreçlerimizde eşitlikçi uygulamalarda bulunmak,</p>
<p>- Tüm çalışan ve çalışan adaylarımıza fırsat eşitliğini sağlamak,</p>
<p>- Nitelikli genç ve deneyimli profesyonelleri şirketimize katılımını sağlamak,</p>
<p>- Adil ücret politikasının uygulanması,</p>
<p>- Etkin performans değerlendirme sisteminin sağlamak,</p>
<p>- İşe alım, terfi vb. süreçlerinde adil hareket etmek,</p>
<p>- Çalışan haklarına saygı göstermek,</p>
<p>- Ayrımcılık, baskı altında çalışma ve tacizin her türlüsüne karşı gelmek,</p>
<p>- Çalışan bağlılığını geliştirmeye yönelik uygulamalarda bulunmak,</p>
<p>- Güvenli çalışma koşullarının sağlanması,</p>
<p>- Çocuk işçiliğine karşı etkin mücadele,</p>
<p>- Kişisel Verilerin Korunması kapsamında kanuni zorunluluklar dışında çalışan verilerinin</p>
<p>3.şahıslar ile paylaşılmaması,</p>
<p>- Haksız kazanç sağlamamak ve haksız kazanç oluşturabilecek davranışlardan kaçınmak,</p>
<p>- Şirket tüm maddi varlıklarının şahsi malı gibi korunmasına özen göstermek,</p>
<p>- İş süreçlerimizde İş Etik Kurallarına uygun hareket etmek,</p>
<p>- İş ortamını ve düzenini bozacak davranışlardan kaçınmak,</p>
<p>- Yetkisi bulunmayan bir sürece dair şirket için beyan ve yazışmada bulunmamak,</p>
<p>- Çıkar çatışması oluşturacak davranışlardan uzak durmak,</p>
<p><b>PopyCar Oto Kiralama A.Ş.</b> </p>
            </div>
        </div>
    </div>
</section>
</template>
<script>
export default {
  name: "ethicalrules",
  metaInfo: () => ({
    title: 'Etik Kurallar',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Etik Kurallar' }
    ]
  }),
  data: () =>{
    return {
    }
  }
}
</script>

<style scoped>
.btn-link {
    color: #212529 !important;
    font-weight: 600;
}
</style>
