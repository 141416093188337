<template>
  <section id="subPage">
    <div class="container cont">
      <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand">{{ $route.name}}</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">{{ $route.name}}</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
        <div class="row">
            <div class="col-md-6" v-if="tokenKontrol==true">
                <form @submit.prevent="ForgotPasswordMethod" class="vld-parent" ref="formContainer">
                    <div class="form-group col-md-12">
                        <label for="eposta">YENİ PAROLA</label>
                        <input type="password" v-model="user.Password" class="form-control" id="Password" name="Password" :class="{ 'is-invalid': submitted && $v.user.Password.$error }">
                        <div v-if="submitted && $v.user.Password.$error" class="invalid-feedback">
                          <span v-if="!$v.user.Password.required">Parolanızı oluşturunuz</span>
                          <span v-if="!$v.user.Password.minLength">Parolanız en az 6 karakterden oluşmalıdır</span>
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <label for="eposta">YENİ PAROLA <small>TEKRAR</small></label>
                        <input type="password" v-model="user.confirmPassword" class="form-control" id="confirmPassword" name="confirmPassword" :class="{ 'is-invalid': submitted && $v.user.confirmPassword.$error }">
                        <div v-if="submitted && $v.user.confirmPassword.$error" class="invalid-feedback">
                          <span v-if="!$v.user.confirmPassword.required">Parolanızı tekrar yazınız</span>
                          <span v-else-if="!$v.user.confirmPassword.sameAsPassword">Parolanız eşleşmedi.</span>
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <div class="alert alert-success" role="alert" v-if="hataMesajiDurum==1 && hataMesaji!=''">
                            <strong>{{hataMesajiBaslik}}</strong>: {{hataMesaji}}
                        </div>
                        <div class="alert alert-danger" role="alert" v-if="hataMesajiDurum==0 && hataMesaji!=''">
                            <strong>{{hataMesajiBaslik}}</strong>: {{hataMesaji}}
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <button type="submit" class="btn btnRed" style="padding-top:3px !important">OLUŞTUR</button>
                    </div>
                </form>
            </div>
            <div class="col-md-12" v-if="tokenKontrol==false">
                <div class="alert alert-danger" role="alert">
                   {{tokenKontrolMesaj}}
                </div>
            </div>
        </div>
    </div>
  </section>
</template>
<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import axios from 'axios'
export default {
  name: 'forgotnewpassword',
  metaInfo: () => ({
    title: 'Yeni Parola Oluştur',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Parolamı Unuttum' }
    ]
  }),
  data: () => {
    return {
      user: {
        Password: '',
        confirmPassword: '',
        Token: ''
      },
      submitted: false,
      isLoading: true,
      fullPage: false,
      loader: 'bars',
      color: '#d2202f',
      hataMesajiDurum: '',
      hataMesaji: false,
      hataMesajiBaslik: false,
      tokenKontrol: false,
      tokenKontrolMesaj: ''
    }
  },
  validations: {
    user: {
      Password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs('Password') }
    }
  },
  mounted (){
    axios.get(process.env.VUE_APP_API_URL+'parolamiUnuttumTokenSorgula.php?token='+this.$route.params.token, {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(sonuc => {
        this.tokenKontrol=sonuc.data.basarili
        this.tokenKontrolMesaj=sonuc.data.mesaj
    })
  },
  methods: {
    ForgotPasswordMethod (e) {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid){
        return
      }
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        loader: this.loader,
        color: this.color,
        opacity: 0.90
      })
      this.user.Token=this.$route.params.token

      axios.post(process.env.VUE_APP_API_URL+'parolamiUnuttumYeniParolaOlustur.php', JSON.stringify(this.user), {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*' }}).then(response => {
        this.hataMesajiDurum=response.data.basarili
        this.hataMesaji=response.data.mesaj
        this.hataMesajiBaslik=response.data.baslik
        loader.hide()
      })
    }
  }
}
</script>

<style>

</style>
