<template>
  <div id="app">
    <Top></Top>
      <router-view :key="$route.fullPath"></router-view>
    <Footer></Footer>

    <div class="modal fade" id="modalLogin" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ÜYE GİRİŞİ</h5>
            <button type="button" class="close modal-content-button-iefix" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="LoginMethod" class="vld-parent" ref="formContainer">
              <div class="form-group col-md-12">
                <label for="eposta">E-POSTA ADRESİNİZ</label>
                <input type="email" v-model="user.eposta" class="form-control form-control-iefix" id="eposta" name="eposta" placeholder="E-posta adresi" :class="{ 'is-invalid': submitted && $v.user.eposta.$error }">
                <div v-if="submitted && $v.user.eposta.$error" class="invalid-feedback">
                  <span v-if="!$v.user.eposta.required">E-posta adresinizi giriniz</span>
                  <span v-if="!$v.user.eposta.email">Geçerli bir e-posta adresi giriniz</span>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="Password">PAROLANIZ</label>
                <input type="password" v-model="user.Password" class="form-control form-control-iefix" id="Password" name="Password" placeholder="Şifrenizi girin" :class="{ 'is-invalid': submitted && $v.user.Password.$error }">
                <div v-if="submitted && $v.user.Password.$error" class="invalid-feedback">
                  <span v-if="!$v.user.Password.required">Parolanızı oluşturunuz</span>
                </div>
              </div>
                <div v-if="wrongPassword"><div class="alert alert-danger">Hatalı e-posta ve parola girdiniz.</div></div>
              <div class="form-group">
                <button type="submit" class="btn btnRed">Giriş Yap</button>
              </div>
              <div class="form-group text-center">
                <router-link to="/parolami-unuttum" style="padding: 10px 25px 25px 25px;" class="close" data-dismiss="modal" aria-label="Close">Parolamı Unuttum</router-link>
              </div>
            </form>
          </div>
          <div class="modal-footer modal-footer-iefix">
            <p>PopyCar avantajlarından yararlanmak için hemen üye olun!</p>
            <div style="clear:both"></div>
            <router-link to="/uye-ol" class="close" data-dismiss="modal" aria-label="Close"><button type="button" class="btn btnGray">Üye Ol</button></router-link>
          </div>
        </div>
      </div>
    </div>
   <div class="alert text-center cookiealert" role="alert" style="font-size: 12px;" v-if="Cookie!=1">
      Bu site Çerez kullanmaktadır. Site içeriği ve reklamlarını tercih ve ihtiyaçlarınız doğrultusunda size yönelik özelleştirilmiş hizmetler sunabilmek, site performans ve işlevselliğini artırabilmek ve site kullanım trafiğimizi analiz etmek için çerezleri kullanıyoruz. Ayrıca sitemizi kullanmanız kapsamında elde edilen ilgili bilgileri, kendilerine sağladığınız veya hizmetlerini kullanımınızdan topladıkları diğer bilgilerle birleştirebilecek sosyal medya, reklam ve analiz ortaklarımızla paylaşıyoruz. Seçtiğiniz çerez türleri çerçevesinde kişisel verilerinizin işlenmesine rıza göstermiş sayılacaksınız. Aşağıda belirtilen seçeneklerden seçmediğiniz çerez türlerinin işleme faaliyetine izin verilmeyecektir. Ziyaretçilerin Çerez tercihlerini değiştirme imkanı her zaman saklıdır.<br><router-link to="/cerez-politikasi" target="_blank">Daha fazla bilgi için Çerez ve gizlilik bildirimimizi okuyun. Ayarlar için tıklayın.</router-link>
      <button type="button" class="btn btn-primary btn-sm acceptcookies" @click="CookieAccept()" style="background-color: #cf0a2c;border-color: #c10425;margin-left:15px;">
          Kabul Ediyorum
      </button>
    </div>
  </div>
</template>
<script>
import Top from './components/Top'
import Footer from './components/Footer'
import MenuPanelim from './components/MenuPanelim'
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    Top,
    Footer
  },
  data: () => {
    return {
      user: {
        eposta: '',
        Password: ''
      },
      submitted: false,
      isLoading: true,
      fullPage: false,
      loader: 'bars',
      color: '#d2202f',
      wrongPassword: false,
      Cookie: localStorage.getItem('Cookiess')
    }
  },
  validations: {
    user: {
      eposta: { required, email },
      Password: { required }
    }
  },
  mounted() {
    if (this.$store?.state?.GetServerData?.member && this.$store?.state?.GetServerData?.member?.Id){
    /** AŞAĞIDAKİ KOD TÜM SAYFALARDA ÇALIŞACAK */
    // this.$gtm.trackEvent({
    //     event: 'pageLoad',
    //     userinfo: {
    //       'isMember': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.isMemberUser,
    //       'isLoggedIn': "Yes",
    //       'ee-User-memberId': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.Id,
    //       'phoneNum': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.Phone,
    //       'ee-User-email': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.Mail,
    //       'emailDomain': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.domainMail,
    //       'hashedPhoneNum': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.HashPhone,
    //       'hashedEmail': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.HashMail,
    //       'age': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.Age,
    //       'gender': '',
    //       'firstSaleDate': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.ilkKiralama,
    //       'lastSaleDate': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.sonKiralama,
    //       'dateOfBirth': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.Birthday,
    //       'numOfPreviousSales': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.toplamKiralama,
    //       'ee-User-totalMemberValue': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.toplamKiralamaTutari,
    //       'ee-User-averageMemberValue': this.$store.state.GetServerData.member && this.$store.state.GetServerData.member.toplamKiralamaOrtalama,
    //       'ee-Hit-pageType':this.$route.fullPath
    //     }
    //   });
    }
  },
  methods: {
    LoginMethod (e) {
      this.submitted = true
      this.wrongPassword = false
      this.$v.$touch()
      if (this.$v.$invalid){
        return
      }
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        loader: this.loader,
        color: this.color,
        opacity: 0.90,
        height: this.height,
        width: this.width
      })
      var params = JSON.stringify(this.user)
      axios.post(process.env.APIURL+'login.php', params, {withCredentials: true, headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
          }}).then(response => {
          loader.hide()
          if (response.data.basarili==1){
            /** AŞAĞIDAKİ ÜYE GİRİŞ BAŞARILI İSE ÇALIŞACAK */
            // this.$gtm.trackEvent({
            //   event: "loginSuccess",
            //     registerinfo: {
            //       status: "Success"
            //     }
            // })
            this.$router.go()
          } else {
            this.wrongPassword=true
          }
        })
    },
    CookieAccept() {
      localStorage.setItem('Cookiess', '1');
      //localStorage.removeItem('Cookie');
      this.Cookie=localStorage.getItem('Cookiess');
    }
  }
}
</script>

<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
.cookiealert {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px 10px;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    background-color: #e4e4e4;
    box-shadow: 0 -3px 4px 0 hsla(0,0%,84.7%,.5);
    z-index: 5;
    margin-bottom: 0 !important;
}
</style>
