import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Signup from '@/components/Signup'
import Login from '@/components/Login'
import Vehicles from '@/components/Vehicles'
import Fleet from '@/components/Fleet'
import About from '@/components/About'
import Offices from '@/components/Offices'
import PersonnelRentalsService from '@/components/PersonnelRentalsService'
import Contact from '@/components/Contact'
import OfficeDetail from '@/components/OfficeDetail'
import Campaigns from '@/components/Campaigns'
import CampaignDetail from '@/components/CampaignDetail'
import OpportunityOfMonth from '@/components/OpportunityOfMonth'
import OpportunityOfMonthDetail from '@/components/OpportunityOfMonthDetail'
import Announcements from '@/components/Announcements'
import AnnouncementsDetail from '@/components/AnnouncementsDetail'
import Cooperations from '@/components/Cooperations'
import CooperationsDetail from '@/components/CooperationsDetail'
import Panelim from '@/components/Panelim'
import BussinesRentals from '@/components/BussinesRentals'
import BussinesRentalService from '@/components/BussinesRentalService'
import ProfilePasswordUpdate from '@/components/ProfilePasswordUpdate'
import ProfileReservation from '@/components/ProfileReservation'
import ProfileReservationDetail from '@/components/ProfileReservationDetail'
import ProfileLease from '@/components/ProfileLease'
import ProfileLeaseDetail from '@/components/ProfileLeaseDetail'
import ProfileTraffic from '@/components/ProfileTraffic'
import Extras from '@/components/Extras'
import BillingInformation from '@/components/BillingInformation'
import Payment from '@/components/Payment'
import PaymentCompleted from '@/components/PaymentCompleted'
import BeOurDealer from '@/components/BeOurDealer'
import Faq from '@/components/Faq'
import Privacy from '@/components/Privacy'
import GeneralAgreement from '@/components/GeneralAgreement'
import CookiePolicy from '@/components/CookiePolicy'
import ForgotPassword from '@/components/ForgotPassword'
import ForgotNewPassword from '@/components/ForgotNewPassword'
import EthicalRules from '@/components/EthicalRules'
import Kvkk from '@/components/Kvkk'
import KvkData from '@/components/KvkData'
import MarketingData from '@/components/MarketingData'
import NotFound from '../components/404'
import Axios from 'axios'
import store from '../store'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },
  routes: [
    {
      path: '/',
      name: 'Ana Sayfa',
      component: Home
    },
    {
      path: '/uye-ol',
      name: 'Üye Ol',
      component: Signup
    },
    {
      path: '/uye-girisi',
      name: 'Üye Girişi',
      component: Login
    },
    {
      path: '/kiralik-araclar/:alisYeri/:iadeYeri/:alisTarihi/:iadeTarihi',
      name: 'Araçlar',
      component: Vehicles
    },
    {
      path: '/iletisim',
      name: 'İletişim',
      component: Contact
    },

    {
      path: '/ekstralar/:SubGroupId/:SubGroupShortName',
      name: 'Ekstralar',
      component: Extras
    },
    {
      path: '/faturabilgileri/:SubGroupId/:SubGroupShortName',
      name: 'Fatura Bilgileri',
      component: BillingInformation,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/odeme/:SubGroupId/:SubGroupShortName',
      name: 'Ödeme Bilgileri',
      component: Payment,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/odeme_tamamla',
      name: 'Ödeme Tamamla',
      component: PaymentCompleted,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/hakkimizda',
      name: 'Hakkımızda',
      component: About
    },
    {
      path: '/subeler',
      name: 'Şubeler',
      component: Offices
    },
    {
      path: '/sube_detay',
      name: 'Ofis Detay',
      component: OfficeDetail
    },
    {
      path: '/bireysel-kiralama-hizmeti',
      name: 'Hizmetlerimiz',
      component: PersonnelRentalsService
    },
    {
      path: '/kurumsal-kiralama-hizmeti',
      name: 'Kurumsal Kiralama Hizmeti',
      component: BussinesRentalService
    },
    {
      path: '/kampanyalar',
      name: 'Kampanyalar',
      component: Campaigns
    },
    {
      path: '/bayimiz-olun',
      name: 'Bayimiz Olun',
      component: BeOurDealer
    },
    {
      path: '/kurumsal-kiralama',
      name: 'Kurumsal Kiralama',
      component: BussinesRentals
    },
    {
      path: '/sss',
      name: 'Sıkça Sorulan Sorular',
      component: Faq
    },
    {
      path: '/gizlilik',
      name: 'Gizlilik',
      component: Privacy
    },
    {
      path: '/genel-kiralama-kosullari',
      name: 'Genel Kiralama Koşulları',
      component: GeneralAgreement
    },

    {
      path: '/cerez-politikasi',
      name: 'Çerez Politikası',
      component: CookiePolicy
    },
    {
      path: '/kvkk',
      name: 'Kişisel Verilerin Korunması Kanunu',
      component: Kvkk
    },
    {
      path: '/pazarlama-metni',
      name: 'Pazarlama İzni Metni',
      component: MarketingData
    },
    {
      path: '/veri-sahibi-form',
      name: 'Veri Sahibi Form',
      component: KvkData
    },
    {
      path: '/ayin-firsatlari',
      name: 'Ayın Fırsatları',
      component: OpportunityOfMonth
    },
    {
      path: '/ayin-firsatlari/:Title_SEO/:Id',
      name: 'Ayın Fırsatları Detay',
      component: OpportunityOfMonthDetail
    },
    {
      path: '/duyurular',
      name: 'Duyurular',
      component: Announcements
    },
    {
      path: '/duyurular/:Title_SEO/:Id',
      name: 'Duyurular Detay',
      component: AnnouncementsDetail
    },
    {
      path: '/is-birlikleri',
      name: 'İş Birlikleri',
      component: Cooperations
    },
    {
      path: '/is-birlikleri/:Title_SEO/:Id',
      name: 'İş Birlikleri Detay',
      component: CooperationsDetail
    },
    {
      path: '/kampanya/:Title_SEO/:Id',
      name: 'Kampanya Detay',
      component: CampaignDetail
    },
    {
      path: '/:Title_SEO/:Id',
      name: 'Lokasyon Detay',
      component: OfficeDetail,
    },
    {
      path: '/parolami-unuttum',
      name: 'Parolamı Unuttum',
      component: ForgotPassword,
    },
    {
      path: '/panelim',
      name: 'Panelim',
      component: Panelim,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/parola-guncelle',
      name: 'Parola Güncelle',
      component: ProfilePasswordUpdate,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/rezervasyonlarim',
      name: 'Rezervasyonlarım',
      component: ProfileReservation,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/rezervasyon-detay-:rezervasyon_id',
      name: 'Rezervasyon Detayı',
      component: ProfileReservationDetail,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/kiralamalarim',
      name: 'Kiralamalarım',
      component: ProfileLease,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/kiralama-detay-:kiralama_id',
      name: 'Kiralama Detayı',
      component: ProfileLeaseDetail,
      meta: {
        authRequired: true
      }
    },
    {
        path: '/filo',
        name: 'Araç Filomuz',
        component: Fleet,
        meta: {
          gtm: 'filo'
        }
      },

    {
      path: '/trafik-cezalarim',
      name: 'Trafik Cezalarım',
      component: ProfileTraffic,
      meta: {
        authRequired: true
      }
    },
    {
      path: '/yeni-parola-olustur-:token',
      name: 'Yeni Parola Oluştur',
      component: ForgotNewPassword
    },
    {
      path: "*",
      meta: {
        layout: "not-found-layout"
      },
      component: NotFound
    },
    /* {
      path: '/a',
      beforeEnter(to, from, next) {
          // Put the full page url including the protocol http(s) below
          window.location = "http://example.com"
      }
    } */
  ]
})
router.beforeEach((to, from, next) => {

  Axios.get(process.env.VUE_APP_API_URL+'session.php',{withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }}).then(response=>{
    if(response.data?.BookingDates?.status == 'success'){
       store.commit('UpdateServerData', response.data);
    }

  })
  if (to.meta.authRequired && !store.getters.IsLogin){
    return next('/uye-girisi');
  }
  next();
})
export default router
