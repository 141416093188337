<template>
  <section id="subPage">
    <div class="container contDetails">
      <div class="row">
        <div class="col-md-4">
          <div class="leftBlockMenu">
            <h2>Hizmetler</h2>
            <ul>
              <li class="active"><a href="/bireysel-kiralama-hizmeti" title=""><i class="fas fa-long-arrow-alt-right"></i>Bireysel Kiralama Hizmeti</a></li>
              <li><a href="/kurumsal-kiralama-hizmeti" title=""><i class="fas fa-long-arrow-alt-right"></i>Kurumsal Kiralama Hizmeti</a></li>
              <li><a href="/bayimiz-olun" title=""><i class="fas fa-long-arrow-alt-right"></i>Bayilik Hizmeti</a></li>
            </ul>
            <div class="clearfix"></div>
          </div>
        </div>
        <div class="col-md-8 cont contProfil">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#">BİREYSEL KİRALAMA HİZMETİ</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Profilim</a></li>
              </ul>
            </div>
          </nav>

          <div class="row">
            <div class="col-md-12">
              <p><strong>Tatilde, iş seyahatlerinizde, istediğiniz zaman, istediğiniz yerde araç kiralayabilmeniz için biz varız!</strong></p>
              <p>Gitmek istediğiniz yere, istediğiniz yoldan ve tam zamanında varmanın keyfini çıkarmak için  bir araç satın almanıza gerek yok.</p>
              <p>Kiralama yapmak için <strong> 444 5 649</strong> çağrı merkezimizi arayabilir veya web sitemiz üzerinden online rezervasyon yapabilirsiniz.</p>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-12 text-center">
                <div>
                  <router-link class="btnGray float-left mr-3" to="/kampanyalar" > KAMPANYALAR</router-link>
                  <router-link class="btnRed float-left" to="/">HEMEN KİRALA </router-link>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
    export default {
   name: "services",
   metaInfo: () => ({
    title: 'Bireysel Kiralama Hizmeti',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: "Bireysel olarak istediğiniz yerde araç kiralayabilmeniz için biz varız! Bireysel araç kiralama hizmeti için avantajlı fiyatlar PopyCar'da!" }
    ]
  })
    }
</script>

<style scoped>
.btnGray{
    padding: 10px 18px 0px 18px !important;
    text-decoration: none !important;
  }
  .btnRed{
    padding: 10px 18px 0px 18px !important;
    text-decoration: none !important;
  }
</style>
