<template>
 <section id="subPage">
	<div class="container">
		<div class="row">
			<div class="col-md-4">
				<MenuPanelim></MenuPanelim>
			</div>
            <div class="col-md-8 cont contProfil" ref="formContainer">
    <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">Parola Güncelle</a>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/panelim">Panelim</router-link></li>
                <li class="nav-item"><span class="nav-link disabled">{{this.$route.name}}</span></li>
            </ul>
        </div>
    </nav>
    <form @submit.prevent="handleSubmit">
        <div class="row">
            <div class="col-md-12">
                <h3>Parola Bilgileri</h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="sifre_1">Yeni Parola*</label>
                        <input type="password" v-model="user.Password" class="form-control" :class="{ 'is-invalid': submitted && $v.user.Password.$error }" placeholder="Parolanızı değiştereceksiniz yazınız.">
                        <div v-if="submitted && $v.user.Password.$error" class="invalid-feedback">
                          <span v-if="!$v.user.Password.required">Parolanızı oluşturunuz</span>
                          <span v-if="!$v.user.Password.minLength">Parolanız en az 6 karakterden oluşmalıdır</span>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="sifre_2">Yeni Parola Doğrulama*</label>
                        <input type="password" v-model="user.confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && $v.user.confirmPassword.$error }" placeholder="Parolanızı değiştereceksiniz yazınız.">
                        <div v-if="submitted && $v.user.confirmPassword.$error" class="invalid-feedback">
                          <span v-if="!$v.user.confirmPassword.required">Parolanızı tekrar yazınız</span>
                          <span v-else-if="!$v.user.confirmPassword.sameAsPassword">Parolanız eşleşmedi.</span>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                    </div>
                    <div class="form-group col-md-6">
                        <button type="submit" class="btnRed float-right" style="padding: 2px 18px 0px 18px !important">Güncelle <i class="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</div>
<md-dialog-alert
      :md-active.sync="hataMesajiDurum"
      :md-title="`${hataMesajiBaslik}`"
      :md-content="`${hataMesaji}`" />
</div>
</section>
</template>
<script>
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import axios from 'axios'
import MenuPanelim from './MenuPanelim'
export default {
  name: 'Panelim',
  metaInfo: () => ({
    title: 'Parolamı Güncelle',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Parolamı Güncelle' }
    ]
  }),
  components: {
    MenuPanelim,
  },
  data: () => {
    return {
    isLoading: false,
    fullPage: true,
    loader: 'bars',
    color: '#d2202f',
      parolamaGuncelle: false,
      user: {
        Password: '',
        confirmPassword: ''
      },
      submitted: false,
      hataMesajiDurum: false,
      hataMesaji: false,
      hataMesajiBaslik: false
    }
  },
  validations: {
    user: {
        Password: { required, minLength: minLength(6) },
        confirmPassword: { required, sameAsPassword: sameAs('Password') }
      },
  },
  methods: {
    handleSubmit (e) {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.formContainer,
        loader: this.loader,
        color: this.color,
        opacity: 0.90,
        /*height: '120px',
        width: '120px',*/
        });
      var params = JSON.stringify(this.user);
      axios.post(process.env.VUE_APP_API_URL+'uyeParolaGuncelle.php', params, {withCredentials: true, headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      }}).then(response => {
        loader.hide()
        if (response.data.basarili==1){
            this.hataMesajiDurum=true
            this.hataMesaji=response.data.mesaj
            this.hataMesajiBaslik=response.data.baslik
            loader.hide()
        } else {
            this.hataMesajiDurum=true
            this.hataMesaji=response.data.mesaj
            this.hataMesajiBaslik=response.data.baslik
            loader.hide()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
