<template>
<section id="subPage">
        <div class="container">
            <div class="row">
                <div class="col-md-8 cont">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">Ödeme Sonuç</a>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav">
                                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only"></span></router-link></li>
                                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Ödeme</a></li>
                            </ul>
                        </div>
                    </nav>
                    <hr class="mb-4">
                    {{ GetServer.odemeTamamlaBasarili }}
                <div class="col-md-12" v-if="GetServer.odemeTamamlaBasarili=='0'" style="text-align: center;margin-top:100px;">

                    <svg style="max-height:200px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M512 32C547.3 32 576 60.65 576 96V128H0V96C0 60.65 28.65 32 64 32H512zM576 416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V224H576V416zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H176C184.8 384 192 376.8 192 368C192 359.2 184.8 352 176 352H112zM240 384H368C376.8 384 384 376.8 384 368C384 359.2 376.8 352 368 352H240C231.2 352 224 359.2 224 368C224 376.8 231.2 384 240 384z"/></svg>
                    <br><br>
                    <h2>{{GetServer.odemeTamamlaHataBaslik}}</h2>
                    <br>
                    <h6>{{GetServer.odemeTamamlaHataMesaji}}</h6>


                    <md-button class="md-primary md-raised" @click="$router.go(-1)">Tekrar Ödeme Yap</md-button>
                </div>
                <div class="col-md-12" v-if="GetServer.odemeTamamlaBasarili=='1'" style="text-align:center">
                    <svg style="max-height:200px; " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/></svg>
                    <br><br>
                    <h2>{{GetServer.odemeTamamlaHataBaslik}}</h2>
                    <br>
                    <h6>{{GetServer.odemeTamamlaHataMesaji}}</h6>
                    <br>
                    <md-button class="md-primary md-raised" to="/rezervasyonlarim">Rezervasyon Detayına Git</md-button>
                </div>
                </div>
                <div class="col-md-4">
                    <div class="leftBlock">
                        <h2> <span></span></h2>
                        <img v-bind:src="`${GetServer.BookingVehicle && GetServer.BookingVehicle.imageUrl}`">
                        <h4>Araç Alış Bilgileri</h4>
                        <p>{{GetServer.BookingVehicle && GetServer.BookingVehicle.tarih_alis}} - {{GetServer.BookingVehicle && GetServer.BookingVehicle.saat_alis}}<br>{{GetServer.BookingDates && GetServer.BookingDates.alisYeri}}</p>
                        <h4>Araç İade Bilgileri</h4>
                        <p>{{GetServer.BookingVehicle && GetServer.BookingVehicle.tarih_iade}} - {{GetServer.BookingVehicle && GetServer.BookingVehicle.saat_iade}}<br>{{GetServer.BookingDates && GetServer.BookingDates.iadeYeri}}</p>
                        <h4>Araç Özellikleri</h4>
                        <p><span>Model</span>: <strong>{{GetServer.BookingVehicle && GetServer.BookingVehicle.SubGroupName}}</strong><br>
                            <span>Yakıt</span>: <strong>{{GetServer.BookingVehicle && GetServer.BookingVehicle.FuelType}}</strong><br>
                            <span>Vites</span>: <strong>{{GetServer.BookingVehicle && GetServer.BookingVehicle.Transmission}}</strong>
                        </p>
                        <div v-if="this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.promosyon">
                            <h4>Promosyon Kodu</h4>
                            <p>Promosyon Kodu : <strong>{{this.$store.state.GetServerData.BookingDates.promosyon}}</strong></p>
                        </div>
                        <h4>Teminat Tutarı</h4>
                        <p>Teminat Tutarı : <strong>{{GetServer.BookingVehicle && GetServer.BookingVehicle.ProvisionAmountTRY}} ₺</strong></p>
                        <h4>Ekstra Ücretler</h4>
                        <p class="ekstralar">
                        <p v-for="ekstra in GetServer.BookingFinance && GetServer.BookingFinance.SecilenEkstralar" :key="ekstra">{{ekstra.EkstraWebName}} : <strong>{{ekstra.EkstraTutar}} TL</strong></p>
                        </p>
                        <h4>Kiralama Tutarı <small><strong> ({{GetServer.BookingVehicle && GetServer.BookingVehicle.Days}} Günlük)</strong></small></h4>
                        <span class="price aracTutari" style="font-size:19px">
                            {{GetServer.odemeTipi=='2' && GetServer.BookingFinance ? GetServer.BookingFinance.KiralamaToplam : GetServer.BookingFinance.KiralamaToplamZiraat}}
                            TL</span>
                        <div v-if="GetServer.BookingVehicle.DropStatu=='1'">
                        <h4>Farklı Lokasyon İade Ücreti</h4>
                        <span class="price DropPrice" style="font-size:19px">{{GetServer.BookingVehicle && GetServer.BookingVehicle.DropPrice}} TL</span>
                        </div>
                        <h4>Genel Toplam </h4>
                        <span class="price genelTutari">{{GetServer.odemeTipi=='2' && GetServer.BookingFinance ? GetServer.BookingFinance.GenelToplam : GetServer.BookingFinance.GenelToplamZiraat}} TL</span>
                        <p>Online Ödeme</p>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import {mapState} from 'vuex'
export default {
    name: "paymentcompleted",
    metaInfo: () => ({
    title: 'Ödeme Sonuç',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Ödeme Sonuç' }
    ]
    }),
    data: () => {
    return {
        tagDatalari: []
    }
    },
    computed: mapState({
        GetServer: state => state.GetServerData,
    }),
    mounted(){
        axios.get(process.env.VUE_APP_API_URL+'rezervasyonKontrol.php', {withCredentials: true, headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*' }}).then(response => {
            if (response.data.cevap==1){
                this.$store.state.GetServerData.BookingFinance.SecilenEkstralar && this.$store.state.GetServerData.BookingFinance.SecilenEkstralar.forEach((r)=>{
                this.tagDatalari.push({
                        id: r.EkstraName,
                        name: r.EkstraWebName,
                        price: r.EkstraTutar,
                        category: 'Rezervasyon',
                        position: r.sira
                    })
                });
                var arr = [
                        this.tagDatalari,
                        {
                            id: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.SubGroupId,
                            name: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.ModelName,
                            price: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.DiscountedDailyPrice,
                            days: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.Days,
                            category: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.MakeName,
                            position: 1,
                            list: "PopYCar / Araçlar / "+this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.MakeName,
                            variant: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.ModelName,
                            brand: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.MakeName,
                            dimension1: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.FuelType,
                            dimension2: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.Transmission,
                            dimension3: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.DriverMinLicenceYear,
                            dimension4: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.DriverMinAge,
                            dimension5: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.PassengerCapacity,
                            dimension6: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.BaggageCapacity,
                            dimension7: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.Segment,
                            dimension8: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.lokasyon_alis,
                            dimension9: this.$store.state.GetServerData.BookingVehicle && this.$store.state.GetServerData.BookingVehicle.lokasyon_iade
                        }]

                var productsArray = [].concat.apply([], arr);
                // this.$gtm.trackEvent({
                //     event: "EEtransaction",
                //     ecommerce: {
                //         checkout: {
                //         actionField: {step: 3},
                //             products: productsArray
                //         }
                //     }   ,
                //     event: "EEtransaction",
                //         ecommerce: {
                //             purchase: {
                //             actionField: {
                //                 id: this.$store.state.GetServerData.ReservationId,
                //                 affiliation: 'PopyCar Online Shopping',
                //                 revenue: this.$store.state.GetServerData.odenenTutar,
                //                 shipping: '0',
                //                 tax: this.$store.state.GetServerData.kdvTutari
                //             },
                //             products: productsArray
                //         }
                //     }
                // });
                // this.$gtm.trackEvent({
                //     event: "EEcheckout",
                //     ecommerce: {
                //         checkout_option: {
                //             actionField: {
                //                 step: 3,
                //                 option: this.$store.state.GetServerData.odemeBanka
                //             }
                //         }
                //     }
                // });
            }
        });
    }
}
</script>

<style scoped>

</style>
