<template>
    <div>
      <section id="subPage">
        <div class="container">
          <div class="row"  v-if="vehicle.SubGroupId>0">
            <div class="col-md-8 cont">
              <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">Ekstra Seçimler</a>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav">
                    <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                    <li class="nav-item"><a href="#" @click="$router.go(-1)" class="nav-link">Araçlar <span class="sr-only">(current)</span></a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                    <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Ekstralar</a></li>
                  </ul>
                </div>
              </nav>
              <form>
              <div class="carOption" v-for="result in extras" v-bind:key="result.ProductName" v-if="result.WebOnlineSelling=='1' && result.Amount>0">
                  <div class="row">
                    <div class="col-2 col-sm-2 col-lg-1">
                      <md-checkbox v-bind:id="`opt_${result.ProductCode}`" v-bind:value="`${result.ProductCode}`" v-bind:name="`${result.ProductName}`" :data-id="`${result.ProductCode}`" :data-title="`${result.Title}`" @change="selectedCheckbox(result.Number,result.Title,result.ucretsizClass)" v-model="extrasAdd" :disabled="result.ProductCode=='wifi_paketi' || result.ProductCode=='OptionWinterTire'">
                      </md-checkbox>
                    </div>
                    <div :class="extraImage(result.ProductCode)"><label v-bind:for="`opt_${result.ProductCode}`"><div></div></label></div>
                      <div class="col-12 col-sm-8 col-lg-10">
                      <label v-bind:for="`opt_${result.ProductCode}`">
                        <h6>{{result.ProductName}}</h6>
                      <p>{{result.Description}}</p>
                      <p class="price" v-if="result.IsFree!=1"><span>{{result.Amount}} TL</span> / Günlük</p>
                      <p class="price" v-if="result.IsFree==1"><span>Ücretsiz</span> / Günlük</p>
                    </label>
                    </div>
                  </div>
              </div>
              <div>
              <router-link class="btnRed float-right" :to="'/faturabilgileri/' + this.$route.params.SubGroupId+'/'+this.$route.params.SubGroupShortName" >Devam Et <i class="fas fa-long-arrow-alt-right"></i></router-link>
                </div>
              </form>
              <div class="pageContent">
                <ul>
                  <li>1-15 gün arası kiralamalarda alınan ekstralar kiralama gün sayısı ile çarpılır.</li>
                  <li>16-30 gün arası kiralamalarda alınan ekstralar sadece 15 gün olarak ücretlendirilir. 15 günden sonrası ücretsizdir.</li>

                  <li>HGS ücreti iade sırasında ayrıca tahsil edilecektir.</li>
                </ul>
              </div>
            </div>
            <div class="col-md-4" ref="formContainer">
              <div class="leftBlock">
                <h2>{{vehicle.SubGroupName}}. <span>{{vehicle.FuelType}} {{vehicle.Transmission}}</span></h2>
                <img v-bind:src="`${vehicle.imageUrl}`">
                <h4>Araç Alış Bilgileri</h4>
                <p>{{vehicle.tarih_alis}} - {{vehicle.saat_alis}}<br>{{vehicle.lokasyon_alis}}</p>
                <h4>Araç İade Bilgileri</h4>
                <p>{{vehicle.tarih_iade}} - {{vehicle.saat_iade}}<br>{{vehicle.lokasyon_iade}}</p>
                <h4>Araç Özellikleri</h4>
                <p>
                  <span>Model</span>: <strong>{{vehicle.SubGroupName}}</strong><br>
                  <span>Yakıt</span>: <strong>{{vehicle.FuelType}}</strong><br>
                  <span>Şanzıman</span>: <strong>{{vehicle.Transmission}}</strong>
                </p>
                  <div v-if="this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.promosyon">
                    <h4>Promosyon Kodu</h4>
                    <p>Promosyon Kodu : <strong>{{this.$store.state.GetServerData.BookingDates.promosyon}}</strong></p>
                  </div>
                <h4>Teminat Tutarı</h4>
                <p>Teminat Tutarı : <strong>{{vehicle.ProvisionAmountTRY}} TL</strong></p>
                <div v-if="checkedExtras">
                <h4>Ekstra Ücretler</h4>
                <p class="ekstralar">
                  <p v-for="ekstra in secilinEkstralar" v-bind:id="`${ekstra.ProductCode}`">{{ekstra.ProductName}} : <strong>{{ekstra.TotalAmount}} TL</strong></p>
                </p>
                </div>
                <h4>Kiralama Tutarı <small><strong> ({{vehicle.Days}} Günlük / Günde : {{vehicle.GunFiyati}} TL)</strong></small></h4>
                <span class="price aracTutari" style="font-size: 16px;text-decoration: line-through;color: #787878;" v-if="vehicle.DiscountedDays">{{vehicle.KiralamaToplamYuksek}} TL</span>
                <span class="price aracTutari" style="font-size:19px">{{kiralamaTutar}} TL</span>
                <div v-if="vehicle.DropStatu=='1'">
                <h4>Farklı Lokasyon İade Ücreti</h4>
                <span class="price DropPrice" style="font-size:19px">{{vehicle.DropPrice}} TL</span>
                </div>
                <h4>Genel Toplam </h4>
                <span class="price genelTutari">{{genelTutar}} TL</span>
                <div>
                  <!-- <button type="button" class="btnGray float-left"><i class="fas fa-long-arrow-alt-left"></i> Düzenle</button> -->
                  <router-link class="btnRed float-right" :to="'/faturabilgileri/' + this.$route.params.SubGroupId+'/'+this.$route.params.SubGroupShortName" >Devam Et </router-link>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>



          <div class="row" v-if="!vehicle.SubGroupId">
            <div class="col-md-12">
<div v-if="!vehicle.SubGroupId" style="text-align:center;">
            <img style="width:25%" src="../../static/assets/img/car-not-found.png"><br>
            <h3>Oturum Süreniz Dolmuştur</h3><br>
            <h6>Oturum süreniz dolduğundan devam edemezsiniz lütfen yeniden sorgulama yapınız.</h6>
          </div>

            </div>
          </div>

        </div>
      </section>
    </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
export default {
  name: "extras",
  metaInfo: () => ({
    title: 'Ekstralar',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Ekstralar' }
    ]
  }),
  data: () => {
    return {
      id: null,
      checkedExtras: false,
      extrasAdd: [],
      extras: [],
      vehicle: [],
      genelTutar: null,
      kiralamaTutar: null,
      isLoading: false,
      fullPage: true,
      loader: 'bars',
      color: '#d2202f',
      ekstralarData : [],
      secilinEkstralar: []
    }
  },
  methods: {
    selectedCheckbox(Id,Name,Price) {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        loader: this.loader,
        color: this.color,
        opacity: 0.98
      });
      const datalar=this.extrasAdd;
      axios.post(process.env.VUE_APP_API_URL+'serviceValidatePrice.php?SubGroupId='+this.$route.params.SubGroupId, {ekstralar : datalar}, {withCredentials: true, headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      }}).then(response => {
        this.secilinEkstralar=response.data.SecilenEkstralar
        this.kiralamaTutar=response.data.KiralamaToplam
        this.genelTutar=response.data.GenelToplam
        if (this.extrasAdd!=""){
            this.checkedExtras = true
            //console.log("Seçilen Ekstra "+ this.extrasAdd)
        } else {
            //console.log("Çıkarılan Ekstra "+this.extrasAdd)
            this.checkedExtras = false
        }
        loader.hide()
      });
      // if (event.target.checked==true){
      //     this.$gtm.trackEvent({
      //     event: "EEaddToCart",
      //       ecommerce: {
      //         currencyCode: 'TRY',
      //         add: {
      //           actionField: {
      //             list: 'PopyCar Car / Araçlar / '+this.vehicle.MakeName
      //           },
      //           products: [{
      //               id: Id,
      //               name: Name,
      //               price: Price,
      //               category: "Ekstra Seçimler",
      //               position: Id
      //           }]
      //         }
      //       }
      //     })
      // }
      // if (event.target.checked==false){
      //   this.$gtm.trackEvent({
      //     event: "EEremoveFromCart",
      //     ecommerce: {
      //       currencyCode: 'TRY',
      //       remove: {
      //         actionField: {'list': 'PopyCar Car / Araçlar /'+this.vehicle.MakeName},
      //         products: [{
      //             id: Id,
      //             name: Name,
      //             price: Price,
      //             category: "Ekstra Seçimler",
      //             position: Id
      //         }]
      //         }
      //     }
      //   })
      // }
    },
    extraImage(data) {
      if (data=='35'){
        return 'col-2 col-sm-2 col-lg-1 insurance';
      } else if (data=='42'){
        return 'col-2 col-sm-2 col-lg-1 insurance';
      } else if (data=='25'){
        return 'col-2 col-sm-2 col-lg-1 insurance';
      } else if (data=='33'){
        return 'col-2 col-sm-2 col-lg-1 insurance';
      } else if (data=='43'){
        return 'col-2 col-sm-2 col-lg-1 insurance';
      } else if (data=='44'){
        return 'col-2 col-sm-2 col-lg-1 insurance';
      } else if (data=='45'){
        return 'col-2 col-sm-2 col-lg-1 insurance';
      } else if (data=='54'){
        return 'col-2 col-sm-2 col-lg-1 insurance';
      } else if (data=='26'){
        return 'col-2 col-sm-2 col-lg-1 driver';
      } else if (data=='36'){
        return 'col-2 col-sm-2 col-lg-1 child';
      } else if (data=='31'){
        return 'col-2 col-sm-2 col-lg-1 navigation';
      }
    }
  },
  mounted() {
    /* EKSTRA SEÇİLİ İSE TEKRARDAN FİYAT HESAPLA BAŞLA */

      if (this.$store.state.GetServerData.BookingFinance && this.$store.state.GetServerData.BookingFinance.SecilenEkstralar){
          this.$store.state.GetServerData.BookingFinance.SecilenEkstralar.forEach((data) => {
            this.extrasAdd.push(data.ProductCode)
            let loader = this.$loading.show({
              container: this.$refs.formContainer,
              loader: this.loader,
              color: this.color,
              opacity: 0.98
            });
            const datalar=this.extrasAdd;
            axios.post(process.env.VUE_APP_API_URL+'serviceValidatePrice.php?SubGroupId='+this.$route.params.SubGroupId, {ekstralar : datalar}, {withCredentials: true, headers: {
              'Cache-Control': 'no-cache',
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
              'Access-Control-Allow-Origin': '*'
            }}).then(response => {
              this.secilinEkstralar=response.data.SecilenEkstralar
              this.kiralamaTutar=response.data.KiralamaToplam
              this.genelTutar=response.data.GenelToplam
                if (this.extrasAdd!=""){
                  this.checkedExtras = true
                } else {
                    this.checkedExtras = false
                }
              loader.hide()
            });
          })
      }
    /* EKSTRA SEÇİLİ İSE TEKRARDAN FİYAT HESAPLA BİTİR */

    let loader = this.$loading.show({
      container: null,
      loader: this.loader,
      color: this.color,
      opacity: 0.98
    });
    axios.get(process.env.VUE_APP_API_URL+'ekstralar.php?SubGroupId='+this.$route.params.SubGroupId+'&SubGroupShortName='+this.$route.params.SubGroupShortName, {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(response => {
      this.vehicle = response.data.arac;
        // this.$gtm.trackEvent({
        //   event: 'EEProductDetail',
        //   ecommerce: {
        //     detail: {
        //       actionField: {
        //         list: 'Popy Car / Araçlar / '+response.data.arac.MakeName
        //       },
        //       products: [{
        //           id: response.data.arac.SubGroupId,
        //           name: response.data.arac.ModelName,
        //           price: response.data.arac.DiscountedDailyPrice,
        //           category: response.data.arac.MakeName,
        //           position: response.data.arac.sira,
        //           list: 'Anasayfa / Araçlar / '+response.data.arac.MakeName,
        //           variant: response.data.arac.ModelName,
        //           brand: response.data.arac.MakeName,
        //           dimension1: response.data.arac.FuelType,
        //           dimension2: response.data.arac.Transmission,
        //           dimension3: response.data.arac.DriverMinLicenceYear,
        //           dimension4: response.data.arac.DriverMinAge,
        //           dimension5: response.data.arac.PassengerCapacity,
        //           dimension6: response.data.arac.BaggageCapacity,
        //           dimension7: response.data.arac.Segment,
        //           dimension8: response.data.arac.lokasyon_alis,
        //           dimension9: response.data.arac.lokasyon_iade
        //       }]
        //     }
        //   }
        // })
        /* Vehicle Add To Card Start */
        // this.$gtm.trackEvent({
        //   event: "EEaddToCart",
        //     ecommerce: {
        //       currencyCode: 'TRY',
        //       add: {
        //         actionField: {
        //           list: 'Popy Car / Araçlar / '+response.data.arac.MakeName
        //         },
        //         products: [{
        //             id: response.data.arac.SubGroupId,
        //             name: response.data.arac.ModelName,
        //             price: response.data.arac.DiscountedDailyPrice,
        //             category: response.data.arac.MakeName,
        //             position: 1,
        //             list: "Popy Car / Araçlar / "+response.data.arac.MakeName,
        //             variant:    response.data.arac.ModelName,
        //             brand:      response.data.arac.MakeName,
        //             dimension1: response.data.arac.FuelType,
        //             dimension2: response.data.arac.Transmission,
        //             dimension3: response.data.arac.DriverMinLicenceYear,
        //             dimension4: response.data.arac.DriverMinAge,
        //             dimension5: response.data.arac.PassengerCapacity,
        //             dimension6: response.data.arac.BaggageCapacity,
        //             dimension7: response.data.arac.Segment,
        //             dimension8: response.data.arac.lokasyon_alis,
        //             dimension9: response.data.arac.lokasyon_iade
        //           }]
        //       }
        //     }
        //   })
          /* Vehicle Add To Card Start */
      if (this.extrasAdd!=""){
      } else {
        this.kiralamaTutar=this.vehicle.KiralamaToplam
        this.genelTutar=this.vehicle.GenelToplam
        this.checkedExtras = false
      }
      this.extras = response.data.arac.Extras;
      loader.hide()
    });
  }
}
</script>
