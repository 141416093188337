<template>
 <section id="subPage">
	<div class="container">
		<div class="row" >
        <div class="col-md-4">
          <MenuPanelim></MenuPanelim>
        </div>
            <div class="col-md-8 cont contProfil">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">Rezervasyonlarım</a>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                           <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/panelim">Panelim</router-link></li>
                <li class="nav-item"><span class="nav-link disabled">{{this.$route.name}}</span></li>
                        </ul>
                    </div>
                </nav>
                  <div class="row" style="text-align:center">
                      <div class="col-md-12">
                        <div v-for="result in sonuc" v-bind:data="result" v-bind:key="result.ReservationId">
                          <router-link class="textDecorationNone" :to="'/rezervasyon-detay-' + result.ReservationId" ><md-toolbar class="md-transparent rezler">
                            <div class="md-toolbar-row md-toolbar-row-iefix">
                              <h3 class="md-title" > {{result.VehicleGroupLong}} <small>veya benzeri</small></h3>
                              <div class="md-toolbar-section-end">
         <div><strong>#{{result.ReservationId}}</strong></div>
        </div>
                            </div>
                            <div class="md-layout md-gutter ">
                              <div class="md-layout-item pic md-size-40 md-small-size-100 md-xsmall-size-100 ">
                                <img v-bind:src="`${result.VehiclePhoto}`">
                              </div>
                              <div class="md-layout-item md-small-size-40 md-xsmall-size-100 md-alignment-top-right">
                                <table style="width:100%;margin-top:20px;margin-left:50px;">
                                  <tr>
                                    <td class="align-text-top">Başlangıç<br> </td>
                                    <td>{{result.StartDate}} {{result.StartHour}} <br> {{result.StartLocation}}</td>
                                  </tr>
                                  <tr>
                                    <td class="align-text-top">Bitiş<br> </td>
                                    <td>{{result.EndDate}} {{result.EndHour}}<br> {{result.StartLocation}}</td>
                                  </tr>
                                </table>

                              </div>
                              <div class="md-layout-item pic md-size-20 md-small-size-100 md-xsmall-size-100 md-alignment-right">
                               <div class="kacGun"><strong>{{result.Days}}</strong> Gün</div>
                              </div>
                            </div>
                          </md-toolbar></router-link>
                          </div>
                          <div class="row" v-if="!sonuc.length">
                    <div class="col-md-12">
                      <img style="width:35%" src="../../static/assets/img/car-not-found.png"><br>
                      <h3>Rezervasyon bulunamadı</h3><br>
                      <md-button class="md-primary md-raised" to="/">HEMEN ARAÇ KİRALA</md-button>
                    </div>
                  </div>
                      </div>
                  </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
import axios from 'axios'
import MenuPanelim from './MenuPanelim'
export default {
  name: 'profilereservation',
  metaInfo: () => ({
    title: 'Rezervasyonlarım',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Rezervasyonlarım' }
    ]
  }),
  components: {
    MenuPanelim,
  },
  data: () => ({
    sonuc: [],
    isLoading: false,
    fullPage: true,
    loader: 'bars',
    color: '#d2202f',
  }),
  mounted () {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
      /*height: '120px',
      width: '120px',*/
    });
    axios.get('https://taiga.rentsys.net/API/WEB/MyReservations.php?Auth='+this.$store.state.GetServerData.member.Token, {withCredentials: true, headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }}).then(response => {
      this.sonuc = response.data;
      loader.hide()
    });
  },
}
</script>

<style scoped>
  .rezler {
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 10px 20px 30px 20px;
}
.md-list-item-content {
    min-height: 18px;
}
.textDecorationNone{
  text-decoration: none!important;
}
.kacGun{
  font-size: 26px;
  padding-top: 40px;
  text-align: right;

}
</style>
