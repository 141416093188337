<template>
  <section id="subPage">
    <div class="container cont contDetails navbarTwo">
      <div class="row ">
        <div class="col-md-4">
          <img v-bind:src="`${ThumbnailPhoto}`" v-bind:alt="`${Title}`" class="w-100 md-elevation-11" style="margin-bottom:20px;">
        </div>
        <div class="col-md-8">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;white-space: unset;">{{Title}}</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/duyurular">Duyurular</router-link></li>
                <li class="nav-item"><span class="nav-link disabled">{{Title}}</span></li>
              </ul>
            </div>
          </nav>
          <div class="row campaignDetails">
            <div class="col-md-12">
              <div v-html="Text"></div>
              <div>
                <img v-if="this.$route.params.Id=='179' || this.$route.params.Id=='186'" class="img-responsive" style="margin-top:15px;" src="../../static/assets/img/central-pegasus-bolbol-puan-kampanyasi.jpg">
                <router-link class="btnGray float-left mr-3" to="/duyurular" ><i class="fas fa-long-arrow-alt-left"></i> Diğer Duyurular</router-link>
                <router-link class="btnRed float-left mr-3" to="/" v-if="!RentSYSKampanyaId">Hemen Kirala <i class="fas fa-long-arrow-alt-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 KampanyaBooking " style="margin-top:30px;" v-if="RentSYSKampanyaId">
          <div class="container md-elevation-11" style="background-color: rgb(160, 160, 160) !important;padding:20px;">
            <div class="theme-search-area-tabs">
              <div class="_c-w _ta-mob-c">
                <h1 class="theme-search-area-tabs-title">{{Title}}</h1>
              </div>
              <div class="tabbable">

                <div class="tab-content _pt-20">
                  <div class="tab-pane active" id="SearchBooking">
                    <div class="theme-search-area theme-search-area-stacked">
                      <form name="Searchbox" @submit.prevent="submit">
                        <div class="theme-search-area-form">
                          <div class="row">
                            <div class="col-md-5">
                              <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-no-border theme-search-area-section-mr">
                                <div class="theme-search-area-section-inner">
                                  <i class="theme-search-area-section-icon fas fa-map-marker-alt"></i>
                                  <md-autocomplete v-model="selectedAlisYeri"  :md-options="alisYeri" md-layout="box" md-dense class="theme-search-area-section-input KampanyaBookinginput"
                                                  >
                                    <label>Nereden Alacaksınız?</label>
                                  </md-autocomplete>
                                </div>
                              </div>
                              <div v-if="farkliLokasyon==true" class="farkliIade-input theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-no-border theme-search-area-section-mr">
                                <div class="theme-search-area-section-inner">
                                  <i class="theme-search-area-section-icon fas fa-map-marker-alt"></i>
                                  <md-autocomplete v-model="selectedIadeYeri"  :md-options="alisYeri"  md-layout="box"  md-dense class="theme-search-area-section-input KampanyaBookinginput">
                                    <label>Nereye Bırakacaksınız?</label>
                                  </md-autocomplete>
                                </div>
                              </div>
                              <div class="theme-search-area-options _mob-h theme-search-area-options-white theme-search-area-options-dot-primary-inverse clearfix">
                                <div class="btn-group theme-search-area farkliIade">
                                    <md-checkbox type="checkbox" class="farkliIade_checkbox whiteBorder" v-model="farkliLokasyon" @change="FarkliLokasyonBosalt">Farklı Yere İade</md-checkbox>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-no-border theme-search-area-section-mr">
                                    <div class="theme-search-area-section-inner md-elevation-2">
                                      <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                        <datetime type="datetime" v-model="datetimeAlisTarihi" class="vdatetime-input theme-red col-md-5 theme-search-area-section-input datePickerStart _mob-h KampanyaBookinginput"
                                                  :format="{ year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' }"
                                                  :phrases="{ok: 'Devam', cancel: 'Kapat'}"
                                                  :minute-step="15"
                                                  auto ref="datepickerAlis"
                                                  input-id="datetimeAlisTarihi"
                                                  :week-start="7" placeholder="Alış Tarihi"
                                                  :min-datetime="new Date().toISOString()"
                                                  >
                                        </datetime>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-no-border theme-search-area-section-mr">
                                    <div class="theme-search-area-section-inner md-elevation-2">
                                      <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                        <datetime type="datetime" v-model="datetimeIadeTarihi" class="vdatetime-input theme-red col-md-5 theme-search-area-section-input datePickerEnd _mob-h KampanyaBookinginput"
                                                    :format="{ year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' }"
                                                    :phrases="{ok: 'Devam', cancel: 'Kapat'}"
                                                    :minute-step="15"
                                                    auto ref="datepickerIade"
                                                    input-id="datetimeIadeTarihi"
                                                    :week-start="7" placeholder="İade Tarihi"
                                                    :min-datetime="datetimeAlisTarihi && new Date(datetimeAlisTarihi).toISOString()"
                                                  >
                                        </datetime>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <button type="submit" class="theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved md-elevation-1">ARA</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <md-dialog-alert
      :md-active.sync="hataMesajiDurum"
      :md-title="`${hataMesajiBaslik}`"
      :md-content="`${hataMesaji}`" />
    </div>
  </section>
</template>

<script>
import { Datetime } from 'vue-datetime'
import { Settings } from 'luxon'
import store from '../store'
import {mapState} from 'vuex'
import axios from 'axios'
export default {
  name: 'announcements-detail',
 metaInfo() {
    return {
    title: this.seoTitle,
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: this.seoDescription }
    ]
    }
  },
  components: {
    datetime: Datetime
  },
  computed: mapState({
    GetServer: state => state.GetServerData,
  }),
  data () {
    return {
      isLoading: false,
      fullPage: true,
        farkliLokasyon: this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.alisYeri !=  this.$store.state.GetServerData.BookingDates.iadeYeri ? true : false,
        datetime: Datetime,
        datetimeAlisTarihi: this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.pickupdate,
        datetimeIadeTarihi: this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.dropoffdate,
        selectedAlisYeri: this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.alisYeri,
        selectedIadeYeri: this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.alisYeri !=  this.$store.state.GetServerData.BookingDates.iadeYeri ? this.$store.state.GetServerData.BookingDates.iadeYeri : '',
        alisYeri: [],
        CalismaSaati: '',
        hataMesajiDurum: false,
        hataMesaji: false,
        hataMesajiBaslik: false,
        Kampanya : [],
      loader: 'bars',
      color: '#d2202f',
      Image: null,
      Text: null,
      Title: null,
      ThumbnailPhoto: null,
      RentSYSKampanyaId: null,
      seoTitle: '',
      seoDescription: ''
    }
  },
  mounted() {
    let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
    });
    axios.get(process.env.VUE_APP_API_URL+'kampanyalar.php?duyuruList=2&kampanyaId='+this.$route.params.Id,{withCredentials: true, headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Access-Control-Allow-Origin': '*'
    }}).then(response => {
      this.Image = response.data[0].Image
      this.Text = response.data[0].Text
      this.Title = response.data[0].Title
      this.RentSYSKampanyaId = response.data[0].RentSYSKampanyaId
      this.ThumbnailPhoto = response.data[0].ThumbnailPhoto
      this.seoTitle = response.data[0].seoTitle
      this.seoDescription = response.data[0].seoDescription
      loader.hide()
    });
  },

  created () {
      let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
      /*height: '120px',
      width: '120px',*/
      });
    var locationList = []
    axios.get(process.env.VUE_APP_API_URL+'serviceSearchingStations.php?query=1', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }}).then(sonuc => {
      for (var i = 0; i < sonuc.data.length; i++) {
        locationList[i] = sonuc.data[i].Name
      }
      this.alisYeri = locationList
    })
   loader.hide()
  },
  methods: {
    openIade() {
      if(this.$refs.datepickerIade){
        this.$refs.datepickerIade.open(event);
      }
    },
    openAlis() {
      if(this.$refs.datepickerAlis){
    	  this.$refs.datepickerAlis.open(event);
      }
    },
    kapandi: function(){alert('kapandi')},
    submit: function () {
      let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
      /*height: '120px',
      width: '120px',*/
      });
      let farkliLokasyonSecimi;
      this.farkliLokasyon && this.farkliLokasyon==true ? farkliLokasyonSecimi='1' : farkliLokasyonSecimi='0'
      this.selectedAlisYeri && this.selectedIadeYeri==null ? this.selectedIadeYeri=this.selectedAlisYeri : ''
      axios.get(process.env.VUE_APP_API_URL+'serviceHoursDay.php?PickUpStation=' + this.selectedAlisYeri + '&DropOffStation=' + this.selectedIadeYeri + '&pickupdate=' + this.datetimeAlisTarihi + '&dropoffdate=' + this.datetimeIadeTarihi + '&Promo=&KampanyaId='+this.RentSYSKampanyaId+'&farkliLokasyon='+farkliLokasyonSecimi, {withCredentials: true, headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      }}).then(res => {
        if (res.data.status=='success') {
          this.$router.push('/kiralik-araclar/' + res.data.alisCode + '/' + res.data.iadeCode + '/' + this.datetimeAlisTarihi + '/' + this.datetimeIadeTarihi)
          loader.hide()
          //this.$store.state.StoreLokasyon.AlisYeri=this.selectedAlisYeri;
        }
        if (res.data.status=='error'){
          this.hataMesajiDurum = true
          this.hataMesajiBaslik=res.data.baslik
          this.hataMesaji=res.data.mesaj
          loader.hide()
        }
      })
    },
    FarkliLokasyonBosalt:function() {
      if (this.farkliLokasyon==false){
        this.selectedIadeYeri=''
      }
    },
  },
}
</script>

<style >
  .KampanyaBooking.btnGray{
    padding: 10px 18px 0px 18px !important;
    text-decoration: none !important;
  }
  .KampanyaBookinginput input {
    border: none!important;
    background-color: transparent!important;
}
.md-field.md-inline.md-autocomplete-box.md-focused{z-index:0!important;}

</style>
