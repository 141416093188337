<template>
  <section id="subPage">
    <div class="container cont">
      <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand">{{ $route.name}}</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">{{ $route.name}}</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
        <div class="row">
            <div class="col-md-6">
                <form @submit.prevent="ForgotPasswordMethod" class="vld-parent" ref="formContainer">
                    <div class="form-group col-md-12">
                        <label for="eposta">KAYITLI E-POSTA ADRESİNİZ</label>
                        <input type="email" v-model="user.eposta" class="form-control" id="eposta" name="eposta" placeholder="E-posta adresi" :class="{ 'is-invalid': submitted && $v.user.eposta.$error }">
                        <div v-if="submitted && $v.user.eposta.$error" class="invalid-feedback">
                            <span v-if="!$v.user.eposta.required">E-posta adresinizi giriniz</span>
                            <span v-if="!$v.user.eposta.email">Geçerli bir e-posta adresi giriniz</span>
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <div class="alert alert-success" role="alert" v-if="hataMesajiDurum==1 && hataMesaji!=''">
                            <strong>{{hataMesajiBaslik}}</strong>: {{hataMesaji}}
                        </div>
                        <div class="alert alert-danger" role="alert" v-if="hataMesajiDurum==0 && hataMesaji!=''">
                            <strong>{{hataMesajiBaslik}}</strong>: {{hataMesaji}}
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <button type="submit" class="btn btnRed" style="padding-top:3px !important">GÖNDER</button>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <router-link to="/uye-ol"><img src="../../static/assets/img/Central-MTL-CNT-79-Uye-Ol.png"></router-link>
            </div>
        </div>
    </div>
  </section>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'
export default {
  name: 'forgotpassword',
  metaInfo: () => ({
    title: 'Parolamı Unuttum',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Parolamı Unuttum' }
    ]
  }),
  data: () => {
    return {
      user: {
        eposta: '',
      },
      submitted: false,
      isLoading: true,
      fullPage: false,
      loader: 'bars',
      color: '#d2202f',
      hataMesajiDurum: '',
      hataMesaji: false,
      hataMesajiBaslik: false,
    }
  },
  validations: {
    user: {
      eposta: { required, email }
    }
  },
  methods: {
    ForgotPasswordMethod (e) {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid){
        return
      }
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        loader: this.loader,
        color: this.color,
        opacity: 0.90
      })
      var params = JSON.stringify(this.user)
      axios.post(process.env.VUE_APP_API_URL+'parolamiUnuttum.php', params, {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*' }}).then(response => {
        this.hataMesajiDurum=response.data.basarili
        this.hataMesaji=response.data.mesaj
        this.hataMesajiBaslik=response.data.baslik
        loader.hide()
      })
    }
  }
}
</script>

<style>

</style>
