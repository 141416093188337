<template>
  <div>

  <section id="subPage" class="mb-0">
    <div class="container cont">
      <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#">HAKKIMIZDA</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Hakkımızda</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="row corporateTab">
        <div class="col-md-12">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          </ul>
          <div class="navBorder"></div>
        </div>
      </div>
    </div>
  </section>
  <section id="subPageCorporate">
    <div class="container">
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="tab_id_1" role="tabpanel" aria-labelledby="tab_1">
          <div class="row justify-content-md-center">
            <div class="col col-lg-9 text-justify">
              <h1>Hakkımızda</h1>
                <h3>İyi otomobil kullanmak herkesin hakkı</h3>
                <p>Popy Car Araç Kiralama A.Ş. ekibi olarak temel hedefimiz müşterilerimize en son model otomobilleri en uygun fiyatlarla tedarik etmek ve mükemmel operasyon yönetimi ile en yüksek müşteri memnuniyetine ulaşmaktır.</p>
                <h3>Uzmanlık ve güç</h3>
                <p>Popycar Araç Kiralama A.Ş. , otomotiv sektörünün en hızlı büyüyen şirketlerinden biridir. Kurumsal gücümüz ve deneyimimiz mükemmel hizmet iddiamızın temelini oluşturur. </p>
                <h3>Şeffaf Hizmet</h3>
                <p>Popycar Araç Kiralama A.Ş.'de fiyatlar ve koşullar her zaman en şeffaf hali ile sunulur. Müşterilerimiz asla sürprizlerle karşılaşmazlar.</p>
               </div>
          </div>

        </div>

      </div>
    </div>
  </section>
  </div>
</template>

<script>
    export default {
        name: "about",
        metaInfo: () => ({
    title: 'Hakkımızda',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: "PopyCar kuruluş hikayesi, misyon ve vizyon bilgileri ile insan kaynakları iletişim bilgilerini almak için tıklayın!" }
    ]
  })
    }
</script>

<style scoped>

</style>
