<template>
  <section id="subPage">
    <div class="container cont contBox">
      <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#">ŞUBELER</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><span class="nav-link disabled">Şubeler</span></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="row box">
        <div class="col-md-3 details" v-for="(item,key) in sonuc" :key="key">
          <router-link :to="'/'+item.Title_SEO+'/'+item.Id">
            <img :src="require('../../static/assets/img/subeler/'+item.img_url)" :alt="item.StationName+'Araç Kiralama'">
            <h2>{{item.StationName}}</h2>
            <span>Detaylı bilgi <i class="fas fa-long-arrow-alt-right"></i></span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
    export default {
    name: "offices",
    metaInfo: () => ({
    title: 'Araç Kiralama Ofisi',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: "PopyCar şubelerinin adres ve iletişim bilgilerini öğrenmek ve istediğiniz ofisten istediğiniz araca ait kiralama rezervasyonu için tıklayın!" }
    ]
  }),
        metaInfo: {
      title: 'Araç Kiralama Lokasyonları',
      titleTemplate: '%s - PopyCar',
      htmlAttrs: {
        lang: 'tr',
        amp: true
      }
    },
  data: () => ({
    isLoading: false,
    fullPage: true,
    loader: 'bars',
    color: '#d2202f',
    sonuc: []
  }),
  mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
      height: this.height,
      width: this.width,
    });
    axios.get(process.env.VUE_APP_API_URL+'lokasyonlar.php?lokasyonList=1',{withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }}).then(response => {
      this.sonuc = response.data;
      loader.hide()
    });
  }
    }
</script>

<style scoped>

</style>
