<template>
  <div>
  <section id="subPageBanner">
    <div class="container-fluid">
      <div class="row">
        <div class="col text-center">
<md-empty-state
      md-icon="error_outline"
      md-label="Aradığınız Sayfa Bulunamadı!"
      md-description="Hatalı bir sayfaya giriş yaptınız. Ana sayfaya dönmek için aşağıdaki butonu kullanabilirsiniz.">
      <md-button to='/' class="md-accent md-raised">ANA SAYFA</md-button>
    </md-empty-state>

        </div></div></div></section></div>
</template>


<script>
export default {};
</script>
