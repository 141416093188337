<template>
    <section id="subPage">
        <div class="container cont contBox">
            <div class="row ">
                <div class="col-md-12">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">VERİ SAHİBİ FORM</a>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav">
                                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Veri Sahibi Formu</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="row box">
                <div class="col-md-12">

                    <div id="pf1" class="pf w0 h0" data-page-no="1">
                        <div class="pc pc1 w0 h0 opened">
                            <div id="pf1" class="pf w0 h0" data-page-no="1">
                                <div class="pc pc1 w0 h0 opened">
                                    <p style="background: white; margin: 0cm 0cm 30.0pt 0cm;"><strong><span style="font-size: 13.5pt;  color: letter-spacing: .25pt;">1.GENEL BİLGİLENDİRME</span></strong></p>
                                    <p style="background: white; margin: 0cm 0cm 30.0pt 0cm;"><span style="font-size: 13.5pt;  color: letter-spacing: .25pt;">6698 Sayılı Kişisel Verilerin Korunması Kanunu&rsquo;nda (&ldquo;KVK Kanunu&rdquo;) ilgili kişi olarak tanımlanan kişisel veri sahiplerine (&ldquo;Bundan sonra &ldquo;Başvuru Sahibi&rdquo; olarak anılacaktır), KVK Kanunu&rsquo;nun 11&rsquo;inci maddesinde kişisel verilerinin işlenmesine yönelik taleplerde bulunma hakkı verilmiştir.</span></p>
                                    <p style="background: white; margin: 0cm 0cm 30.0pt 0cm;"><span style="font-size: 13.5pt;  color: letter-spacing: .25pt;">KVK Kanunu&rsquo;nun 13&rsquo;&uuml;nc&uuml; maddesinin birinci fıkrası uyarınca; veri sorumlusu olan Şirketimize bu haklara ilişkin olarak yapılacak başvuruların yazılı olarak veya Kişisel Verilerin Korunması Kurulu (&ldquo;Kurul&rdquo;) tarafından belirlenen diğer yöntemlerle tarafımıza iletilmesi gerekmektedir.</span></p>
                                    <p style="background: white; margin: 0cm 0cm 30.0pt 0cm;"><strong><span style="font-size: 13.5pt;  color: letter-spacing: .25pt;">2.BAŞVURU YÖNTEMİ</span></strong></p>
                                    <table style="width: 98.0%; border-collapse: collapse;" width="98%">
                                        <tbody>
                                        <tr style="height: 31.5pt;">
                                            <td style="width: 40.34%; border: solid  1.0pt; background: white; height: 31.5pt;" width="40%">
                                                <p><strong><span style="font-size: 13.5pt; font-family: 'Calibri',sans-serif; color: letter-spacing: .25pt;">Başvuru Yöntemi</span></strong></p>
                                            </td>
                                            <td style="width: 30.64%; border: solid  1.0pt; border-left: none; background: white;  height: 31.5pt;" width="30%">
                                                <p style="text-align: center;"><strong><span style="font-size: 13.5pt; font-family: 'Calibri',sans-serif; color: letter-spacing: .25pt;">Başvurunun Yapılacağı Adres</span></strong></p>
                                            </td>
                                            <td style="width: 29.02%; border: solid  1.0pt; border-left: none; background: white; height: 31.5pt;" width="29%">
                                                <p style="text-align: center;"><strong><span style="font-size: 13.5pt; font-family: 'Calibri',sans-serif; color: letter-spacing: .25pt;">Başvuru Gönderiminde Belirtilecek Bilgi</span></strong></p>
                                            </td>
                                        </tr>
                                        <tr style="height: 60.0pt;">
                                            <td style="width: 40.34%; border: solid  1.0pt; border-top: none; padding: 0cm 3.5pt 0cm 3.5pt; height: 60.0pt;" width="40%">
                                                <p><strong><span style="font-size: 10.0pt;  color: letter-spacing: .25pt;">Şahsen Başvuru [Başvuru sahibinin bizzat gelerek kimliğini tevsik edici belge ile başvurması]</span></strong></p>
                                            </td>
                                            <td style="width: 30.64%; border-top: none; border-left: none; border-bottom: solid  1.0pt; border-right: solid  1.0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 60.0pt;" width="30%">
                                                <p><strong><span style="font-size: 10.0pt;  color: letter-spacing: .25pt;">POPYCAR Araç Kiralama <br />Fatih Sultan Mehmet Mahallesi<br /> Poligon Cd. BuYaka Kuleler Kule:3 Kat:6 Daire No 37-42 Ümraniye/İstanbul</span></strong></p>
                                            </td>
                                            <td style="width: 29.02%; border-top: none; border-left: none; border-bottom: solid  1.0pt; border-right: solid  1.0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 60.0pt;" width="29%">
                                                <p><strong><span style="font-size: 10.0pt;  color: letter-spacing: .25pt;">Zarfın &uuml;zerine &ldquo;Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi&rdquo; yazılacaktır.</span></strong></p>
                                            </td>
                                        </tr>
                                        <tr style="height: 60.0pt;">
                                            <td style="width: 40.34%; border: solid  1.0pt; border-top: none; padding: 0cm 3.5pt 0cm 3.5pt; height: 60.0pt;" width="40%">
                                                <p><strong><span style="font-size: 10.0pt;  color: letter-spacing: .25pt;">Noter Vasıtasıyla Başvuru</span></strong></p>
                                            </td>
                                            <td style="width: 30.64%; border-top: none; border-left: none; border-bottom: solid  1.0pt; border-right: solid  1.0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 60.0pt;" width="30%">
                                                <p><strong><span style="font-size: 10.0pt;  color: letter-spacing: .25pt;">POPYCAR Araç Kiralama<br />Fatih Sultan Mehmet Mahallesi<br /> Poligon Cd. BuYaka Kuleler Kule:3 Kat:6 Daire No 37-42 Ümraniye/İstanbul</span></strong></p>
                                            </td>
                                            <td style="width: 29.02%; border-top: none; border-left: none; border-bottom: solid  1.0pt; border-right: solid  1.0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 60.0pt;" width="29%">
                                                <p><strong><span style="font-size: 10.0pt;  color: letter-spacing: .25pt;">Tebligat zarfına &ldquo;Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi&rdquo; yazılacaktır.</span></strong></p>
                                            </td>
                                        </tr>
                                        <tr style="height: 70.9pt;">
                                            <td style="width: 40.34%; border: solid  1.0pt; border-top: none; padding: 0cm 3.5pt 0cm 3.5pt; height: 70.9pt;" width="40%">
                                                <p><strong><span style="font-size: 10.0pt;  color: letter-spacing: .25pt;">Mobil İmza ya da E-posta İle Başvuru [İlgili kişi tarafından veri sorumlusuna daha önce bildirilen ve veri sorumlusunun sisteminde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle]</span></strong></p>
                                            </td>
                                            <td style="width: 30.64%; border-top: none; border-left: none; border-bottom: solid  1.0pt; border-right: solid  1.0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 70.9pt;" width="30%">
                                                <p><a href="mailto:kvk@popycar.com"><strong><span style="color: text-decoration: none; text-underline: none;">kvkK@popycar.com</span></strong></a></p>
                                            </td>
                                            <td style="width: 29.02%; border-top: none; border-left: none; border-bottom: solid  1.0pt; border-right: solid  1.0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 70.9pt;" width="29%">
                                                <p><strong><span style="font-size: 10.0pt;  color: letter-spacing: .25pt;">E-posta&rsquo;nın konu kısmına &ldquo;Kişisel Verilerin Korunması Kanunu Bilgi Talebi&rdquo; yazılacaktır.</span></strong></p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p style="background: white; margin: 0cm 0cm 30.0pt 0cm;"><span style="font-size: 13.5pt;  color: letter-spacing: .25pt; background: white;">&nbsp;</span></p>
                                    <p style="background: white; margin: 0cm 0cm 30.0pt 0cm;"><span style="font-size: 13.5pt;  color: letter-spacing: .25pt; background: white;">Tarafımıza iletilmiş olan başvurularınız KVK Kanunu&rsquo;nun 13&rsquo;&uuml;nc&uuml; maddesinin 2&rsquo;inci fıkrası gereğince, talebin niteliğine göre talebinizin bizlere ulaştığı tarihten itibaren otuz g&uuml;n i&ccedil;inde yanıtlandırılacaktır. Yanıtlarımız ilgili KVK Kanunu&rsquo;nun 13&rsquo;&uuml;nc&uuml; maddesi h&uuml;km&uuml; gereğince yazılı veya elektronik ortamdan tarafınıza ulaştırılacaktır.</span></p>
                                    <p style="tab-stops: 58.85pt; margin: 1.7pt 0cm .0001pt 22.8pt;"><strong>&nbsp;</strong></p>

                                    <p style="background: white; margin: 0cm 0cm 30.0pt 0cm;"><strong><span style="font-size: 13.5pt;  color: letter-spacing: .25pt;">3.BAŞVURU FORMU</span></strong></p>
                                    <ol>
                                        <li style="text-indent: 0cm; line-height: 106%; tab-stops: 52.45pt; margin: 9.1pt 66.1pt .0001pt 40.8pt;"><strong>Başvuru Sahibi iletişim bilgileri:</strong></li>
                                    </ol>
                                    <p style="margin: .1pt 0cm .0001pt 0cm;"><span style="font-size: 7.5pt;">&nbsp;</span></p>
                                    <table style="margin-left: 5.15pt; border-collapse: collapse; border: none;">
                                        <tbody>
                                        <tr style="height: 56.05pt;">
                                            <td style="width: 106.35pt; border: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 56.05pt;" width="142">
                                                <p><strong>&nbsp;</strong></p>
                                                <p style="margin: 7.5pt 0cm .0001pt 3.2pt;"><strong>İsim Soyisim:</strong></p>
                                            </td>
                                            <td style="width: 418.15pt; border: solid black 1.0pt; border-left: none; padding: 0cm 0cm 0cm 0cm; height: 56.05pt;" width="558">
                                                <p>&nbsp;</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 55.9pt;">
                                            <td style="width: 106.35pt; border: solid black 1.0pt; border-top: none; padding: 0cm 0cm 0cm 0cm; height: 55.9pt;" width="142">
                                                <p><strong>&nbsp;</strong></p>
                                                <p style="margin: 7.5pt 0cm .0001pt 3.2pt;"><strong>T.C. Kimlik Numarası:</strong></p>
                                            </td>
                                            <td style="width: 418.15pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 55.9pt;" width="558">
                                                <p>&nbsp;</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 56.05pt;">
                                            <td style="width: 106.35pt; border: solid black 1.0pt; border-top: none; padding: 0cm 0cm 0cm 0cm; height: 56.05pt;" width="142">
                                                <p><strong>&nbsp;</strong></p>
                                                <p style="margin: 7.65pt 0cm .0001pt 3.2pt;"><strong>Telefon Numarası:</strong></p>
                                            </td>
                                            <td style="width: 418.15pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 56.05pt;" width="558">
                                                <p>&nbsp;</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 56.05pt;">
                                            <td style="width: 106.35pt; border: solid black 1.0pt; border-top: none; padding: 0cm 0cm 0cm 0cm; height: 56.05pt;" width="142">
                                                <p><strong>&nbsp;</strong></p>
                                                <p style="margin: 7.55pt 0cm .0001pt 3.2pt;"><strong>E-posta:</strong></p>
                                            </td>
                                            <td style="width: 418.15pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 56.05pt;" width="558">
                                                <p>&nbsp;</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 55.9pt;">
                                            <td style="width: 106.35pt; border: solid black 1.0pt; border-top: none; padding: 0cm 0cm 0cm 0cm; height: 55.9pt;" width="142">
                                                <p><strong>&nbsp;</strong></p>
                                                <p style="margin: 7.5pt 0cm .0001pt 3.2pt;"><strong>Adres:</strong></p>
                                            </td>
                                            <td style="width: 418.15pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 55.9pt;" width="558">
                                                <p>&nbsp;</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p style="margin: 0cm; margin-bottom: .0001pt;">&nbsp;</p>
                                    <ol>
                                        <li style="text-indent: 0cm; line-height: 106%; tab-stops: 52.45pt; margin: 9.1pt 66.1pt .0001pt 40.8pt;"><strong>Lütfen Şirketimiz ile olan ilişkinizi belirtiniz</strong>. (Müşteri, iş ortağı, &ccedil;alışan adayı, eski &ccedil;alışan, &uuml;&ccedil;&uuml;nc&uuml; taraf firma &ccedil;alışanı, hissedar gibi)</li>
                                    </ol>
                                    <p style="margin: 0cm; margin-bottom: .0001pt;"><span style="font-size: 10.0pt;">&nbsp;</span></p>
                                    <p style="margin: 0cm; margin-bottom: .0001pt;"><span style="font-size: 10.0pt;">&nbsp;</span></p>
                                    <p style="margin: .15pt 0cm .0001pt 0cm;"><span style="font-size: 5.0pt;">&nbsp;</span></p>
                                    <table style="border-collapse: collapse; border: none;">
                                        <tbody>
                                        <tr style="height: 31.75pt;">
                                            <td style="border-top: solid black 1.0pt; border-left: solid black 1.0pt; border-bottom: none; border-right: none; padding: 0cm 0cm 0cm 0cm; height: 31.75pt;" width="299">
                                                <p style="margin: 9.8pt 0cm .0001pt 7.85pt;">M&uuml;şteri</p>
                                            </td>
                                            <td style="border-top: solid black 1.0pt; border-left: none; border-bottom: none; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 31.75pt;border-top: solid black 1.0pt; border-left: solid black 1.0pt; border-bottom: none;" width="332">
                                                <p style="margin: 9.8pt 0cm .0001pt 3.9pt;">İş Ortağı</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 34.85pt;">
                                            <td style="border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 34.85pt;border-left: solid black 1.0pt;" width="299">
                                                <p style="margin-top: .3pt;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
                                                <p style="margin-left: 7.85pt;">Ziyaret&ccedil;i</p>
                                            </td>
                                            <td style="border-top: none; border-left: none; border-bottom: solid black 1.0pt; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 34.85pt;">
                                                <p style="margin-top: .3pt;"><span style="font-size: 9.0pt;">&nbsp;</span></p>
                                                <p style="margin-left: 3.9pt;">Diğer</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 30.35pt;">
                                            <td style="border-top: none; border-left: solid black 1.0pt; border-bottom: none; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 30.35pt;" colspan="4">
                                                <p style="line-height: 13.3pt; margin: 1.0pt 167.4pt .0001pt 3.2pt;">Şirketimiz i&ccedil;inde iletişimde olduğunuz birim:...................</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 28.55pt;">
                                            <td style="border: solid black 1.0pt; border-top: none; padding: 0cm 0cm 0cm 0cm; height: 28.55pt;" colspan="4">
                                                <p style="margin: .2pt 0cm .0001pt 3.2pt;">Konu:</p>
                                                <p style="margin-left: 3.2pt;">..............................</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <p style="margin: 0cm; margin-bottom: .0001pt;"><span style="font-size: 10.0pt;">&nbsp;</span></p>
                                    <p style="margin: .55pt 0cm .0001pt 0cm;"><span style="font-size: 8.0pt;">&nbsp;</span></p>
                                    <table style="margin-left: 12.2pt; border-collapse: collapse; border: none;">
                                        <tbody>
                                        <tr style="height: 30.35pt;">
                                            <td style="width: 227.55pt; border-top: solid black 1.0pt; border-left: 1pt solid black; border-bottom: none; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 30.35pt;">
                                                <p style="margin: 7.5pt 0cm .0001pt 4.3pt;">Eski/Mevcut &Ccedil;alışanım</p>
                                            </td>
                                            <td style="width: 251.55pt; border-top: solid black 1.0pt; border-left: none; border-bottom: none; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 30.35pt;">
                                                <p style="margin: 7.5pt 0cm .0001pt 4.4pt;">İş Başvurusu / özge&ccedil;miş Paylaşımı Yaptım Tarih</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 22.95pt;">
                                            <td style="width: 9.0cm; border-top: none; border-left: solid black 1.0pt; border-bottom: none; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 22.95pt;" colspan="2">
                                                <p style="margin: 5.85pt 0cm .0001pt 3.2pt;">&Ccedil;alıştığım Yıllar: ...........................</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 26.35pt;">
                                            <td style="width: 227.55pt; border: none; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 26.35pt;border-left: 1pt solid black;border-bottom: 1pt solid black;">
                                                <p style="margin: 8.0pt 0cm .0001pt 4.3pt;">Diğer: ....................................</p>
                                            </td>
                                            <td style="width: 251.55pt; border: none; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 26.35pt;">
                                                <p style="margin: 8.0pt 0cm .0001pt 4.4pt;">&Uuml;&ccedil;&uuml;nc&uuml; Kişi Firma &Ccedil;alışanıyım</p>
                                            </td>
                                        </tr>
                                        <tr style="height: 20.55pt;">
                                            <td style="width: 279.15pt; border: none; border-right: solid black 1.0pt; padding: 0cm 0cm 0cm 0cm; height: 20.55pt;" colspan="2">
                                                <p style="margin: .05pt 0cm .0001pt 3.35pt;"><em>L&uuml;tfen &ccedil;alıştığınız firma ve pozisyon bilgisini belirtiniz</em></p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <span style="font-size: 11.0pt; font-family: 'Calibri',sans-serif;"><br style="page-break-before: always;" /></span>
                                    <ol>
                                        <li style="text-indent: -11.25pt; tab-stops: 17.1pt; margin: 1.85pt 0cm .0001pt 17.05pt;"><strong>L&uuml;tfen KVK Kanunu kapsamındaki talebinizi detaylı olarak belirtiniz:</strong></li>
                                    </ol>
                                    <p style="margin-top: 1.1pt;">........................................</p>
                                    <p>.................................................................</p>
                                    <p>.................................................................</p>
                                    <p style="margin-top: .95pt;">.....................................</p>
                                    <p>................................................................</p>
                                    <p>...............................................................</p>
                                    <p>...............................................................</p>
                                    <p style="margin-top: .9pt;">.............................</p>
                                    <p>......................................................</p>
                                    <p>.......................................................</p>
                                    <p style="margin: 0cm; margin-bottom: .0001pt;">&nbsp;</p>
                                    <p style="margin: .2pt 0cm .0001pt 0cm;"><span style="font-size: 8.0pt;">&nbsp;</span></p>
                                    <ol>
                                        <li style="margin-left: 18.25pt; text-indent: -12.45pt; tab-stops: 18.3pt;"><strong>Lütfen başvurunuza vereceğimiz yanıtın tarafınıza bildirilme yöntemini seçiniz:</strong></li>
                                    </ol>
                                    <p style="margin: 9.1pt 0cm .0001pt 5.8pt;">Adresime gönderilmesini istiyorum.</p>
                                    <p style="line-height: 130%; margin: 6.65pt 0cm .0001pt 5.8pt;">E-posta adresime gönderilmesini istiyorum. (E-posta yöntemini se&ccedil;meniz h&acirc;linde size daha hızlı yanıt verebileceğiz.)</p>
                                    <p style="line-height: 130%; margin: 2.5pt 0cm .0001pt 5.8pt;">Elden teslim almak istiyorum. (Vek&acirc;leten teslim alınması durumunda noter tasdikli vek&acirc;letname veya yetki belgesi olması gerekmektedir.)</p>
                                    <p style="line-height: 107%; margin: 2.7pt 15.5pt .0001pt 5.8pt;">İşbu başvuru formu, Şirketimiz ile olan ilişkinizi tespit ederek, varsa, Şirketimiz tarafından işlenen kişisel verilerinizi eksiksiz olarak belirleyerek, ilgili başvurunuza doğru ve kanuni s&uuml;resinde cevap verilebilmesi i&ccedil;in tanzim edilmiştir. Hukuka aykırı ve haksız bir şekilde veri paylaşımından kaynaklanabilecek hukuki risklerin bertaraf edilmesi ve özellikle kişisel verilerinizin g&uuml;venliğinin sağlanması amacıyla, kimlik ve yetki tespiti i&ccedil;in Şirketimiz ek evrak ve malumat (N&uuml;fus c&uuml;zdanı veya s&uuml;r&uuml;c&uuml; belgesi sureti vb.) talep etme hakkını saklı tutar. Form kapsamında iletmekte olduğunuz taleplerinize ilişkin bilgilerin doğru ve g&uuml;ncel olmaması ya da yetkisiz bir başvuru yapılması halinde Şirketimiz, söz konusu yanlış bilgi ya da yetkisiz başvuru kaynaklı taleplerden dolayı mesuliyet kabul etmemektedir.</p>
                                    <p style="line-height: 166%; tab-stops: 76.6pt; margin: 8.05pt 306.55pt .0001pt 5.8pt;">Başvuru Sahibi (Kişisel Veri Sahibi) Adı Soyadı&nbsp; :</p>
                                    <p style="line-height: 166%; margin: .1pt 369.4pt .0001pt 5.8pt;">Başvuru Tarihi : İmza :</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "kvkdata"
}
</script>

<style scoped>
.btn-link {
    color: #212529 !important;
    font-weight: 600;
}
</style>
