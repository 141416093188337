<template>
  <div>
    <div class="theme-hero-area theme-hero-area-primary">
      <div class="theme-hero-area-bg-wrap">
        <div class="theme-hero-area-bg ws-action bg-home" data-parallax="true"></div>
        <div class="theme-hero-area-mask theme-hero-area-mask-half"></div>
        <div class="theme-hero-area-inner-shadow theme-hero-area-inner-shadow-light"></div>
      </div>
      <div class="theme-hero-area-body">
        <div class="_pt-100 _pb-200 _pv-mob-50">
          <div class="container">
            <div class="theme-search-area-tabs">
              <div class="theme-search-area-tabs-header _c-w _ta-mob-c">
                <h1 class="theme-search-area-tabs-title">POPYCAR ARAÇ KİRALAMA</h1>
                <p class="theme-search-area-tabs-subtitle">Popy'den otomobil kiraladığınız anda benzersiz bir rent a car deneyiminin kapılarını aralarsınız.</p>
              </div>
              <div class="tabbable">
                <div class="tab-content _pt-20">
                  <div class="tab-pane active" id="SearchBooking">
                    <div class="theme-search-area theme-search-area-stacked">
                      <form name="Searchbox" @submit.prevent="submit">
                        <div class="theme-search-area-form">
                          <div class="row">
                            <div class="col-md-5">
                              <div class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-no-border theme-search-area-section-mr">
                                <div class="theme-search-area-section-inner">
                                  <i class="theme-search-area-section-icon fas fa-map-marker-alt"></i>
                                  <md-autocomplete v-model="selectedAlisYeri" :md-options="alisYeri" md-layout="box" md-dense class="theme-search-area-section-input">
                                    <label>Nereden Alacaksınız?</label>
                                  </md-autocomplete>
                                </div>
                              </div>
                              <div v-if="farkliLokasyon==true" class="farkliIade-input theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-no-border theme-search-area-section-mr">
                                <div class="theme-search-area-section-inner">
                                  <i class="theme-search-area-section-icon fas fa-map-marker-alt"></i>
                                  <md-autocomplete v-model="selectedIadeYeri"  :md-options="alisYeri"  md-layout="box"  md-dense class="theme-search-area-section-input">
                                    <label>Nereye Bırakacaksınız?</label>
                                  </md-autocomplete>
                                </div>
                              </div>
                              <div v-if="PromosyonKodumVar==true" class="farkliIade-input theme-search-area-section first theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-no-border theme-search-area-section-mr">
                                <div class="theme-search-area-section-inner">
                                  <i class="theme-search-area-section-icon fas fa-hashtag"></i>
                                  <md-field md-layout="box" md-dense class="theme-search-area-section-input">
                                    <label class="promosyon">Promosyon Kodu</label>
                                    <md-input v-model="PromotionCode"  />
                                  </md-field>
                                </div>
                              </div>
                              <div class="theme-search-area-options _mob-h theme-search-area-options-white theme-search-area-options-dot-primary-inverse clearfix">
                                <div class="btn-group theme-search-area farkliIade">
                                    <md-checkbox type="checkbox" class="farkliIade_checkbox whiteBorder" v-model="farkliLokasyon" @change="FarkliLokasyonBosalt">Farklı Yere İade</md-checkbox>
                                    <md-checkbox type="checkbox" class="farkliIade_checkbox whiteBorder" v-model="PromosyonKodumVar" @change="PromosyonBosalt">Promosyon Kodu</md-checkbox>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-no-border theme-search-area-section-mr">
                                    <div class="theme-search-area-section-inner md-elevation-2">
                                      <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                        <datetime type="datetime" v-model="datetimeAlisTarihi" class="vdatetime-input theme-red col-md-5 theme-search-area-section-input datePickerStart _mob-h"
                                                  placeholder="Alış Tarihi"
                                                  :format="{ year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' }"
                                                  :phrases="{ok: 'Devam', cancel: 'Kapat'}"
                                                  :minute-step="15"
                                                  auto ref="datepickerAlis"
                                                  input-id="datetimeAlisTarihi"
                                                  :week-start="1"
                                                  :min-datetime="new Date().toISOString()">
                                        </datetime>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-bg-white theme-search-area-section-no-border theme-search-area-section-mr">
                                    <div class="theme-search-area-section-inner md-elevation-2">
                                      <i class="theme-search-area-section-icon fas fa-calendar-alt"></i>
                                        <datetime type="datetime" v-model="datetimeIadeTarihi" class="vdatetime-input theme-red col-md-5 theme-search-area-section-input datePickerEnd _mob-h"
                                                    :format="{ year: 'numeric', day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' }"
                                                    :phrases="{ok: 'Devam', cancel: 'Kapat'}"
                                                    :minute-step="15"
                                                    auto ref="datepickerIade"
                                                    input-id="datetimeIadeTarihi"
                                                    :week-start="1" placeholder="İade Tarihi"
                                                    :min-datetime="datetimeAlisTarihi && new Date(datetimeAlisTarihi).toISOString()">
                                        </datetime>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <button type="submit" class="theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved  md-elevation-1">ARA</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section id="homeCampaign">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <hr>
        </div>
          <div class="col-md-4">
              <div class="col-md-12 text-center">
                <h2><router-link to="/kampanyalar" style="color:#2c3e50 !important;text-decoration: none;" title="Tüm Kapmanyaları Görüntüle">Kampanyalar</router-link></h2>
                <p> </p>
              </div>
              <carousel :mouse-drag="true" :perPageCustom="[[50, 1],[480, 1], [768, 1], [1200, 1]]" :autoplay="true" :loop="true" :autoplayTimeout="5000" >
                <slide class="instagramSlider" v-for="result in Kampanya"  v-bind:data="result" v-bind:key="result.Id">
                  <span class="label" @click="tagManagerKampanyaClik(result.Id,result.Title,result.sira)">
                    <router-link v-bind:to="`/kampanya/${result.Title_SEO}/${result.Id}`" v-bind:title="result.Title">
                    <img v-bind:src="result.Image" v-bind:alt="result.Title" class="KampanyaFotograf">
                    </router-link>
                  </span>
                </slide>
              </carousel>
          </div>
          <div class="col-md-4">
              <div class="col-md-12 text-center">
                <h2><router-link to="/ayin-firsatlari" style="color:#2c3e50 !important;text-decoration: none;" title="Ayın Fırsatları">Ayın Fırsatları</router-link></h2>
                <p> </p>
              </div>
              <carousel :mouse-drag="true" :perPageCustom="[[50, 1],[480, 1], [768, 1], [1200, 1]]" :autoplay="true" :loop="true" :autoplayTimeout="5000" >
                <slide class="instagramSlider" v-for="result in ayinFirsatlari"  v-bind:data="result" v-bind:key="result.Id">
                  <span class="label" @click="tagManagerKampanyaClik(result.Id,result.Title,result.sira)">
                    <router-link v-bind:to="`/ayin-firsatlari/${result.Title_SEO}/${result.Id}`" v-bind:title="result.Title">
                    <img v-bind:src="result.Image" v-bind:alt="result.Title" class="KampanyaFotograf">
                    </router-link>
                  </span>
                </slide>
              </carousel>
          </div>
          <div class="col-md-4">
              <div class="col-md-12 text-center">
                <h2><router-link to="/is-birlikleri" style="color:#2c3e50 !important;text-decoration: none;" title="İş Birlikleri">İş Birlikleri</router-link></h2>
                <p> </p>
              </div>
              <carousel :mouse-drag="true" :perPageCustom="[[50, 1],[480, 1], [768, 1], [1200, 1]]" :autoplay="true" :loop="true" :autoplayTimeout="5000" >
                <slide class="instagramSlider" v-for="result in isBirlikleri"  v-bind:data="result" v-bind:key="result.Id">
                  <span class="label" @click="tagManagerKampanyaClik(result.Id,result.Title,result.sira)">
                    <router-link v-bind:to="`/is-birlikleri/${result.Title_SEO}/${result.Id}`" v-bind:title="result.Title">
                    <img v-bind:src="result.Image" v-bind:alt="result.Title" class="KampanyaFotograf">
                    </router-link>
                  </span>
                </slide>
              </carousel>
          </div>
        </div>
    </div>

    <div class="container" style="margin-top: 30px;" v-if="Duyurular.length > 0">
      <div class="row">

          <div class="col-md-12" >
              <div class="col-md-12 text-center">
                <h2><router-link to="/duyurular" style="color:#2c3e50 !important;text-decoration: none;" title="Tüm Duyurular Görüntüle">Duyurular</router-link></h2>
                <p> </p>
              </div>
              <carousel :mouse-drag="true" :perPageCustom="[[50, 1],[480, 1], [768, 1], [1200, 1]]" :autoplay="true" :loop="true" :autoplayTimeout="5000" >
                <slide class="duyuruSlider" v-for="result in Duyurular"  v-bind:data="result" v-bind:key="result.Id">
                  <span class="duyuruLabel" @click="tagManagerKampanyaClik(result.Id,result.Title,result.sira)">

										<!-- Yönlendirilecek link (DirectLink) var ise _blank ile işlem yapıldı -->
										<a v-if="result.DirectLink" :href="result.DirectLink" target="_blank">
											<img v-bind:src="result.Image" v-bind:alt="result.Title" class="duyuruFotograf">
										</a>

										<!-- Yönlendirilecek link (DirectLink) yok ise normal sayfa içi link verildi -->
                    <router-link v-if="!result.DirectLink" v-bind:to="`/duyurular/${result.Title_SEO}/${result.Id}`" v-bind:title="result.Title">
                    	<img v-bind:src="result.Image" v-bind:alt="result.Title" class="duyuruFotograf">
                    </router-link>
                  </span>
                </slide>
              </carousel>
          </div>
        </div>
    </div>
  </section>
  <section id="homeAboutus">
    <img src="../../static/assets/img/home_aboutus.jpg" alt="" class="haImg">
    <div class="container">
      <div class="row">
        <div class="col-md-6 align-self-center">
          <span>S.S.S.</span>
          <h3>Nasıl Araç Kiralayabilirim?</h3>
          <p>"Yepyeni bir araç kiralama deneyimi" anlayışı ile çok kısa sürede önemli atılımlar yaparak büyüyen marka, her geçen gün artan hizmet noktaları ile müşterilerine kaliteden ödün vermeden hizmet vermeye devam etmektedir.</p>
          <router-link class="redLink" to="/sss" title="">Detaylı Bilgi<i class="fas fa-long-arrow-alt-right"></i></router-link>
        </div>
        <div class="col-md-6 xsHidden"><img src="../../static/assets/img/sss.jpg" alt="PopyCar rent a car ile nasıl araç kiralayabilirim"></div>
      </div>
    </div>
  </section>

  <div class="container" style="margin-top:25px;" v-if="populerLokasyonlar.length > 0">
      <div class="row">
        <div class="col-md-12 text-center">
            <h2>Popüler Lokasyonlar</h2>
            <p></p>
        </div>

        <div class="col-lg-3 col-md-3 col-xs-12" v-for="populerLokasyon in populerLokasyonlar" v-bind:data="populerLokasyon" v-bind:key="populerLokasyon.Id">
          <router-link :to="`/${populerLokasyon.Title_SEO}/${populerLokasyon.Id}`">
              <img :src="require('../../static/assets/img/subeler/'+populerLokasyon.img_url)" class="bg">
              {{ populerLokasyon.StationName }}
          </router-link>
        </div>
        <!--
        <div class="col-lg-4 col-md-6 col-xs-12">
          <router-link to="/istanbul-havalimani-arac-kiralama/45" class="" title="Şube Detayını Görüntüle">
              <img src="../../static/assets/img/subeler/istanbulhvl.jpg" class="bg">
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12">
          <router-link to="/istanbul-havalimani-arac-kiralama/45" class="" title="Şube Detayını Görüntüle">
              <img src="../../static/assets/img/subeler/istanbulhvl.jpg" class="bg">
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12">
          <router-link to="/istanbul-havalimani-arac-kiralama/45" class="" title="Şube Detayını Görüntüle">
              <img src="../../static/assets/img/subeler/istanbulhvl.jpg" class="bg">
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12">
          <router-link to="/istanbul-havalimani-arac-kiralama/45" class="" title="Şube Detayını Görüntüle">
              <img src="../../static/assets/img/subeler/istanbulhvl.jpg" class="bg">
          </router-link>
        </div>
        <div class="col-lg-4 col-md-6 col-xs-12">
          <router-link to="/istanbul-havalimani-arac-kiralama/45" class="" title="Şube Detayını Görüntüle">
              <img src="../../static/assets/img/subeler/istanbulhvl.jpg" class="bg">
          </router-link>
        </div>
        -->
      </div>
  </div>

<section id="homeIcon">
	<div class="container text-center">

    <md-dialog-alert
      :md-active.sync="hataMesajiDurum"
      :md-title="`${hataMesajiBaslik}`"
      :md-content="`${hataMesaji}`" />

      <!-- <md-dialog-alert
      :md-active.sync="popup"
      md-title=""
      md-confirm-text="Kapat"
      :md-content="popupImg"
      @md-confirm="onConfirm()"/> -->

      <md-dialog :md-active.sync="popup" class="popupEkran">
        <md-dialog-actions>
          <md-button class="md-primary" @click="onConfirm()">Kapat</md-button>
        </md-dialog-actions>
        <md-dialog-content>
          <a :href="popupImgUrl" title=''><img :src="popupImg" style='width:500px;'></a>
        </md-dialog-content>
      </md-dialog>
	</div>
</section>

  </div>
</template>
<script>
import { Datetime } from 'vue-datetime';
import { Settings } from 'luxon'
import { Carousel, Slide } from 'vue-carousel';
import axios from 'axios'
import store from '../store'
import {mapState} from 'vuex'
import '../assets/css/slider.css'
export default {
  name: 'Home',
  metaInfo: () => ({
    title: 'PopyCar ',
    titleTemplate: "%s - Her Zaman İyi Fiyatlar",
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: "Popy'den otomobil kiraladığınız anda benzersiz bir rent a car deneyiminin kapılarını aralarsınız." }
    ]
  }),
  components: {
    Carousel,
    Slide,
    datetime: Datetime
  },
  data () {
    return {
      popup: false,
      popupImg: '',
      popupImgUrl: '',
      popupId: '',
      isLoading: false,
      fullPage: true,
      farkliLokasyon:false,
      PromosyonKodumVar:false,
      loader: 'bars',
      color: '#d2202f',
      datetime: Datetime,
      datetimeAlisTarihi:'',
      datetimeIadeTarihi:'',
      selectedAlisYeri:'',
      selectedIadeYeri:'',
      PromotionCode:'',
      alisYeri: [],
      CalismaSaati: '',
      hataMesajiDurum: false,
      hataMesaji: false,
      hataMesajiBaslik: false,
      Kampanya : [],
      ayinFirsatlari: [],
      isBirlikleri: [],
      Duyurular: [],
      populerLokasyonlar: [],
      tagDatalari: []
    }
  },
  created () {
    axios.get(process.env.VUE_APP_API_URL+'popup.php', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(sonuc => {
      this.$forceUpdate()
        if (sonuc.data.kampanyaPopup==1){
          this.popup=true
          this.popupImg=sonuc.data.kampanya
          this.popupImgUrl=sonuc.data.kampanyaUrl
          this.popupId=sonuc.data.popupId
          if (localStorage.getItem(sonuc.data.popupId)==1){
            this.popup=false
          } else {
            this.popup=true
          }
        }
    })
      let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
      /*height: '120px',
      width: '120px',*/
      });
    var locationList = []
    axios.get(process.env.VUE_APP_API_URL+'serviceSearchingStations.php?query=1', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(sonuc => {
       this.$forceUpdate()
      for (var i = 0; i < sonuc.data.length; i++) {
        locationList[i] = sonuc.data[i].Name
      }
      this.alisYeri = locationList
    });
    axios.get(process.env.VUE_APP_API_URL+'kampanyalar.php?kampanyaList=1&limit=3', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(response => {
      this.$forceUpdate()
      this.Kampanya = response.data
      response.data.forEach((data)=>{
        this.tagDatalari.push({
            id: data.Id,
            name: data.Title,
            creative: "Ana Sayfa Kampanya",
            position: data.sira
        })
      })
    });
    axios.get(process.env.VUE_APP_API_URL+'kampanyalar.php?ayinFirsatlariList=1&limit=3', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(response => {
      this.$forceUpdate()
      this.ayinFirsatlari = response.data
      response.data.forEach((data)=>{
        this.tagDatalari.push({
            id: data.Id,
            name: data.Title,
            creative: "Ana Sayfa Kampanya",
            position: data.sira
        })
      })
    });
    axios.get(process.env.VUE_APP_API_URL+'kampanyalar.php?isBirlikleriList=1&limit=3', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(response => {
      this.$forceUpdate()
      this.isBirlikleri = response.data
      response.data.forEach((data)=>{
        this.tagDatalari.push({
            id: data.Id,
            name: data.Title,
            creative: "Ana Sayfa Kampanya",
            position: data.sira
        })
      })
    });
    axios.get(process.env.VUE_APP_API_URL+'kampanyalar.php?duyuruList=1&limit=3', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(response => {
      this.$forceUpdate()
      this.Duyurular = response.data
      response.data.forEach((data)=>{
        this.tagDatalari.push({
            id: data.Id,
            name: data.Title,
            creative: "Ana Sayfa Kampanya",
            position: data.sira
        })
      })
    });
    axios.get(process.env.VUE_APP_API_URL+'populerLokasyonlar.php?populerLokasyonList=1', {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(response => {
      this.$forceUpdate()
      this.populerLokasyonlar = response.data
      response.data.forEach((data)=>{
        this.tagDatalari.push({
            id: data.Id,
            name: data.Title,
            creative: "Ana Sayfa Kampanya",
            position: data.sira
        })
      })
    });
   loader.hide()
    // this.$gtm.trackEvent({
    //   event: "EEpromoImp",
    //   ecommerce: {
    //     promoView: {
    //       promotions: this.tagDatalari
    //     }
    //   }
    // });
  },
  mounted: function() {
    if (this.$store?.state?.GetServerData?.BookingDates) {
      this.farkliLokasyon = this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.alisYeri !=  this.$store.state.GetServerData.BookingDates.iadeYeri ? true : false
      this.PromosyonKodumVar = this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.promosyon !=  '' && this.$store.state.GetServerData.BookingDates.promosyon != undefined ? true : false
      this.datetimeAlisTarihi = this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.pickupdate !='' ? this.$store.state.GetServerData.BookingDates.pickupdate : ''
      this.datetimeIadeTarihi = this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.dropoffdate!='' ? this.$store.state.GetServerData.BookingDates.dropoffdate : ''
      this.selectedAlisYeri = this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.alisYeri !='' ? this.$store.state.GetServerData.BookingDates.alisYeri : ''
      this.selectedIadeYeri = this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.alisYeri !=  this.$store.state.GetServerData.BookingDates.iadeYeri ? this.$store.state.GetServerData.BookingDates.iadeYeri : ''
      this.PromotionCode = this.$store.state.GetServerData.BookingDates && this.$store.state.GetServerData.BookingDates.promosyon != '' ? this.$store.state.GetServerData.BookingDates.promosyon : ''
    } else {
    }

  },
  computed: mapState({
    GetServer: state => state.GetServerData,
  }),
  methods: {
    onConfirm () {
      this.popup=false
      localStorage.setItem(this.popupId, '1');
      $(".popupEkran").hide();
      $(".md-dialog-overlay").hide();
    },
    openIade() {
      if(this.$refs.datepickerIade){
        this.$refs.datepickerIade.open(event);
      }
    },
    openAlis() {
      if(this.$refs.datepickerAlis){
    	  this.$refs.datepickerAlis.open(event);
      }
    },
    submit: function () {
      let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
      /*height: '120px',
      width: '120px',*/
      });
      let PromosyonKoduSecimi;
      let farkliLokasyonSecimi;
      this.selectedAlisYeri && this.selectedIadeYeri==null ? this.selectedIadeYeri=this.selectedAlisYeri : ''
      this.PromosyonKodumVar && this.PromosyonKodumVar=='' || this.PromosyonKodumVar==false ? PromosyonKoduSecimi='0' : PromosyonKoduSecimi='1'
      this.farkliLokasyon && this.farkliLokasyon==true ? farkliLokasyonSecimi='1' : farkliLokasyonSecimi='0'
      axios.get(process.env.VUE_APP_API_URL+'serviceHoursDay.php?PickUpStation=' + encodeURIComponent(this.selectedAlisYeri) + '&DropOffStation=' + encodeURIComponent(this.selectedIadeYeri) + '&pickupdate=' + this.datetimeAlisTarihi + '&dropoffdate=' + this.datetimeIadeTarihi + '&Promo=' + this.PromotionCode+ '&KampanyaId=&PromosyonKodumVar='+PromosyonKoduSecimi+'&farkliLokasyon='+farkliLokasyonSecimi, {withCredentials: true, headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
      }}).then(res => {
        if (res.data.status=='success') {
          loader.hide();
          //this.$store.state.GetServerData.BookingDates=res.data;
           store.commit('UpdateServerData', {
            BookingDates : res.data
           });

           //this.$sess.set('test', 'Hello world!', 1);
          this.$router.push('/kiralik-araclar/' + res.data.alisCode + '/' + res.data.iadeCode + '/' + this.datetimeAlisTarihi + '/' + this.datetimeIadeTarihi)
        }else{
          this.hataMesajiDurum = true
          this.hataMesajiBaslik=res.data.baslik
          this.hataMesaji=res.data.mesaj
          loader.hide()
        }
      })
    },
    FarkliLokasyonBosalt:function() {
      if (this.farkliLokasyon==false){
        this.selectedIadeYeri=''
      }
    },
    PromosyonBosalt:function() {
      if (this.PromosyonKodumVar==false){
        this.PromotionCode=''
      }
    },
    tagManagerKampanyaClik(Id,Title,Sira) {
    // this.$gtm.trackEvent({
    //   event: "EEpromoClick",
    //   ecommerce: {
    //     promoClick: {
    //       promotions: [
    //         {
    //           id: Id,
    //           name: Title,
    //           creative: "Ana Sayfa Kampanya",
    //           position: Sira
    //         }
    //       ]
    //     }
    //   }
    // })
  }
  }
}
Settings.defaultLocale = 'tr'
Settings.defaultOuputCalendar = 'turkish'
</script>
<style>
.VueCarousel-slide {
  position: relative;
  color: #fff;
  font-family: Arial;
  font-size: 24px;
  text-align: center;
}
.KampanyaSlider{
  width: 100%;
}
.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.duyuruLabel{
    position: initial;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.instagramSlider{
  min-height: 350px;
}
.duyuruSlider{
  min-height: auto;
}
#homeCampaign {
    padding: 30px 0px 30px 0px;
}
.yollarSeninBaslik{
  font-size:40px!important;font-weight:bold!important;
}
.KampanyaFotograf {
    max-width: none!important;
    height: 350px!important;
}
.duyuruFotograf{
    width: 100%;
}
.promosyon {left: 60px!important;margin-top: -5px!important;}
.TitleClass {
  margin-bottom: 2rem !important;
}
.bg{
  width: 95%;
  min-height: 180px;
  margin-bottom: 30px;
}

.bg-home{
  background-image: url('../../static/assets/img/background_new.jpg');
}
</style>
