import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import moment from 'moment'
Vue.prototype.moment = moment
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

  const store = new Vuex.Store({
    state: {
      GetServerData: null,
      UyeOlmadanDevamState: 0
    },
    mutations: {
      UpdateServerData: function (state, ServerData) {
          state.GetServerData = ServerData
      },
      UyeOlmadanDevamFunc: function (state, ServerData) {
        state.UyeOlmadanDevamState = ServerData
      },
    },
    getters: {
      IsLogin: function(state)  {
        if(state.GetServerData.member || state.GetServerData.uyeOlmadanDevamEt == 1){
          return 1
        }
        else{
          return 0
        }
      }
    },
    plugins: [
      createPersistedState({
        storage: {
          getItem: key => ls.get(key),
          setItem: (key, value) => ls.set(key, value),
          removeItem: key => ls.remove(key)
        }
      })
    ]
  })

  export default store;
