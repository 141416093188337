<template>

				<div class="leftBlockMenu">
					<h2>PROFİLİM</h2>
					<ul>
						<li><router-link to="/panelim" class="btnMenu"><i class="fas fa-long-arrow-alt-right"></i> Hesabım</router-link></li>
						<li><router-link to="/parola-guncelle" class="btnMenu"><i class="fas fa-long-arrow-alt-right"></i> Parola Güncelle</router-link></li>
                        <li><router-link to="/rezervasyonlarim" class="btnMenu"><i class="fas fa-long-arrow-alt-right"></i> Rezervasyonlarım</router-link></li>
                        <li><router-link to="/kiralamalarim" class="btnMenu"><i class="fas fa-long-arrow-alt-right"></i> Kiralamalarım</router-link></li>
                        <li><router-link to="/trafik-cezalarim" class="btnMenu"><i class="fas fa-long-arrow-alt-right"></i> Trafik Cezalarım</router-link></li>
					</ul>
					<div class="clearfix"></div>
				</div>
			
</template>
<script>
export default {
  name: 'MenuPanelim'
}
</script>
