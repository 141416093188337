<template>
  <section id="subPage">
    <div class="container cont contDetails navbarTwo">

      <div class="row ">
        <div class="col-md-4" style="margin-bottom: 20px;">
          <img :src="require('../../static/assets/img/subeler/'+sonuc?.img_url)" :alt="sonuc.StationName+''">
        </div>
        <div class="col-md-8">
          <nav class="navbar navbar-expand-lg navbar-light" style="display: flow-root;">
            <h1>{{sonuc.Title}}</h1>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><router-link to="/subeler" class="nav-link">Şubeler</router-link></li>
                <li class="nav-item"><span class="nav-link disabled" >{{sonuc.StationName}}</span></li>
              </ul>
            </div>
          </nav>
          <div class="row">
            <div class="col-md-12">
              <p class="contact">
                <span>Adres</span>{{sonuc.StationAddress}}
                <br>
                <span>Telefon</span><a v-bind:href="`tel:${sonuc.GsmNumber}`">{{sonuc.GsmNumber}}</a>
                <br>
                <span>Çalışma Saatleri</span>{{sonuc.WorkHours}}
              </p>
              <p v-html="sonuc.aciklama"></p>
              <div>
                <router-link class="btnGray float-left mr-3" to="/kampanyalar" > KAMPANYALAR</router-link>
                <router-link class="btnRed float-left" to="/">HEMEN KİRALA </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="row map">
        <div class="col-md-4 align-self-center">
          <h4>{{sonuc.StationName}} Harita Görünümü</h4>
          <a target="_blank" :href="'http://maps.google.com/maps?q=' +sonuc.Longitude+',' + sonuc.Latitude" class="redLink"  title="">Haritayı büyük görüntüle<i class="fas fa-long-arrow-alt-right"></i></a>
        </div>
        <div class="col-md-8">
          <div id="mapOffices" v-html="sonuc.map_url"></div>
        </div>
      </div>

      <div class="row video" v-if="sonuc.video_desc">
        <div class="col-md-6 OfficeVideo" v-html="sonuc.video_url">
        </div>
        <div class="col-md-6 align-self-center">
          <h4>{{sonuc.StationName}} Video</h4>
          <p v-html="sonuc.video_desc"></p>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import axios from 'axios'
    export default {
    name: "office-detail",
     metaInfo() {
      return {
        title: this.sonuc.seoTitle,
        titleTemplate: '%s - PopyCar',
        htmlAttrs: {
          lang: 'tr',
          amp: true
        },
        meta: [{ name: 'description', content: this.sonuc.seoDescription }]
      }
    },
    data: () => ({
    isLoading: false,
    fullPage: true,
    loader: 'bars',
    color: '#d2202f',
    sonuc: [],
        center: { lat: 40.9851586, lng: 29.1089362 },
        zoom: 13,
        markers: { lat: 40.9851586, lng: 29.1089362 },
      mapLoaded: false,
  }),
  watch: {
    distance: function (n) {
      this.getMarkers()
    },
    markers: function (newMarkers, o) {
        const bounds = new google.maps.LatLngBounds()
          bounds.extend({lat: newMarkers.lat, lng: newMarkers.lng})
        this.$refs.map.fitBounds(bounds)
    }
  },
  mounted() {
    let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
    });
     axios.get(process.env.VUE_APP_API_URL+'lokasyonlar.php?lokasyonId='+this.$route.params.Id,{withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }}).then(response => {
      this.sonuc = response.data
    });
      loader.hide();
    this.getMarkers();
  },
  methods: {
    getMarkers: function () {
      this.$refs.map.$mapPromise.then(() => {
        axios.get(process.env.VUE_APP_API_URL+'lokasyonlar.php?lokasyonId='+this.$route.params.Id,{withCredentials: true, headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Access-Control-Allow-Origin': '*'
        }}).then(response => {
          this.markers = {
                  lat: parseFloat(response.data.Longitude),
                  lng: parseFloat(response.data.Latitude)
              }
        })
        this.mapLoaded = true
      })
    },


  }


    }
</script>

<style>
  .videoClass{
    width: 100%;
    height: 390px!important;
  }
  iframe:not(.md-image) {
    height: 315px!important;
}

  #mapOffices iframe{
    width: 800px !important;
    height: 390px!important;
  }
  iframe:not(.md-image) {
    height: 315px!important;
}
.btnGray{
    padding: 10px 18px 0px 18px !important;
    text-decoration: none !important;
  }
</style>
