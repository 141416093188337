<template>
<section id="subPage">
    <div class="container cont contBox">
        <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">{{this.$route.name}}</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link class="nav-link" to="/">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><span class="nav-link disabled">{{this.$route.name}}</span></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
        <div class="row box">
            <div class="col-md-12">
                <div id="contentPage"><p></p><h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Nasıl rezervasyon yaptırabilirim?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Online olarak www.popycar.com internet sayfası üzerinden basit bir şekilde rezervasyon yaptırabilirsiniz. Unutmayın erken rezervasyon yaptırmanız halinde çok daha ekonomik fiyatlar alırsınız. Bunun dışında 444 5 649 numaralı rezervasyon merkezini arayarak veya info@popycar.com adresine e-posta göndererek rezervasyon yaptırabilirsiniz.  </p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Aracı nasıl teslim alabilirim?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Rezervasyonunuz anında ilgili ofise iletilir ve sizin için en uygun POPY aracı hazırlanır. Rezervasyon bilgilerinizle ofise gitmeniz yeterlidir.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Aracı nasıl iade edebilirim?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Kiralama sürenizin sonunda araçla birlikte önceden belirttiğiniz lokasyona gitmeniz yeterlidir. Kiralama süresince her türlü soru ve sorunlarınız için size bildirilen telefon numarasından yetkili çalışanlarımıza ulaşabilirsiniz.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Rezerve ettiğim aracı kesinlikle alabiliyor muyum?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Rezervasyonlar grup bazlı yapılmaktadır ve marka garantisi verilmemektedir. Rezervasyonunuzun yapıldığı grupta araç o tarihte müsait değilse bir üst segment araç aynı fiyattan verilecektir.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Sözleşme doldurulurken neler gerekmektedir?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Ehliyet (yurt dışından geliyorsanız ayrıca pasaport ),adınıza kredi kartınız, adres ve telefon bilgileriniz sözleşmemize personelimiz tarafından kaydedilir. Ödediğiniz kira bedelini ve depozitoyu da gösteren imzalı kira anlaşmanızın bir nüshasını mutlaka isteyiniz.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;"><span style="box-sizing: border-box; font-weight: normal;">Başkasına ait kredi kartı ile kiralama yapabilir miyim?</span></h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Maalesef başkasına ait kredi kartı ile kiralama yapılamamaktadır. Mutlaka kredi kartı sahibinin sizinle beraber ofise gelmesi gerekmektedir.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Otomobili arkadaşlarım/eşim/bir yakınım sürebilir mi?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Aracın, kiralayan şahıs dışındaki kişi/kişilerce kullanılabilmesi ilave sürücü/sürücülere ait sürücü belgesi bilgilerinin kira sözleşmesinin üzerinde gösterilmesi ve ek sürücü bedeli ödenmesi ile mümkündür. Aksi durumun belirlenmesi ve/veya herhangi kaza durumunda tüm güvenceler geçersiz sayılarak gerek kiralayan, gerekse aracı kullanan kişi ayrı ayrı ve müştereken sorumlu tutulurlar.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Araçlarınızda kilometre sınırı var mı?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Günlük araç kiralamalarında 500 km, aylık araç kiralamalarında 4.000 km kullanım hakkı vardır.<br>
<br>
1 gün 500 KM - 2 gün 1000 KM - 3 gün 1500 KM - 4 gün 2000 KM - 5 gün 2500 KM&nbsp; &nbsp; &nbsp; &nbsp;<br>
6 gün 3000 KM - 7 gün 3500 KM - 8 gün 4000 KM - 9 / 30 gün arası kiralamalarda 4000 KM kullanım hakkı vardır. <br>
Daha uzun mesafeler için kilometre paketlerimizden satın alabilirsiniz.  Detaylı bilgiyi rezervasyon merkezimizden alabilirsiniz.
<br></p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Fiyat politikanız nasıl belirleniyor?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Talep edilen bölgedeki anlık talep ve yoğunluğa bağlı olarak dinamik ve değişkenlik gösteren bir fiyat politikamız bulunmaktadır.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Kredi kartı dışında bir ödeme şekli kabul edilebilir mi, kredi kartı koşulları nelerdir?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">POPY bünyesinde yapılacak tüm kiralamalarda kredi kartı talep edilmektedir. Kredi kartının dışında nakit, debit kart, sanal kart, senet vb. ödeme şekilleri kabul edilmemektedir. Kredi kartı istenmesinin nedeni oluşabilecek trafik cezaları, karayolları geçişleri, kiralama uzatma talepleri, hasar bedeli vb. durumlarda tahsilat yapılabilmesidir.<br style="box-sizing: border-box;"></p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Kredi kartı puanları ile kiralama yapabilir miyim?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">POPY’de kredi kartı puanları ile araç kiralaması yapılmamaktadır.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Rezervasyon iptalinde para iadesi ne zaman kredi kartıma yansır?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">İade talebi 24 saat içinde bankaya bildirilmektedir. Bankanın işlem hızına göre 5-7 gün arasında para hesabınıza geçer.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Kiralama başında alınan depozito bedeli ne zaman iade edilir?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Kiralamanız bittiğinde depozito tutarınız POPY tarafından iade edilir. İade, banka iç iletişimine bağlı olarak hesabınıza 3-5 iş günü içerisinde yansımaktadır. Yabancı kredi kartı ile kiralama yapılan durumlarda iadenin hesaba yansıma süresi 10-15 iş gününü bulmaktadır.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Araçlarda HGS var mı? Ücreti nasıl ödeniyor?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Tüm araçlarımızda HGS bulunmaktadır. Kiralamanız bittiğinde Karayolları tarafından HGS kullanım bilginiz tarafımıza ulaşmaktadır ve kiralamanızı yapmış olduğunuz kredi kartınızdan HGS Hizmet Bedeli ile birlikte tahsil edilmektedir. Tahsil sürecinde Karayollarına bağlı olunması nedeniyle geç tahsilat yapıldığı durumlar olmaktadır. </p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Kampanyalar birleştirilebilir mi?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">İndirim, promosyon vb. kampanyalar birleştirilemez.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Kiraladığım araç ile yurtdışına çıkabilir miyim?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Hayır, kiralık araçlarımız yurtdışına çıkartılamaz.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Trafik cezası durumunda ceza ödemesi nasıl yapılmaktadır?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Trafik cezası TC numaranıza yazıldı ise, ödemeyi aracın kullanıcısı yapmalıdır. Plakaya yazılan trafik cezalarında trafik cezası tarafımıza tebliğ edildiğinde indirimli olarak ödenmektedir ve müşterilerimizin kredi kartından tahsil edilmektedir. Eş zamanlı olarak trafik cezasına ait hizmet bedeli de tahsil edilmektedir.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Kaza olması durumunda aracın tamirde geçirdiği günler için para alıyor musunuz?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Hayır, almıyoruz. Ancak aracın; alkollü, uyuşturucu etkisi altında ya da yasal hız sınırları dışında kullanılmaması gerekmektedir. Aksi takdirde; aracı kullanan hem tüm hasardan sorumlu tutulur hem de tamir nedeni ile kaybedilen kiralama ücretini ödemekle yükümlü olur. Herhangi kaza durumunda, aracın yeri değiştirilmeden en yakın polis veya jandarma karakoluna başvurularak kaza, hırsızlık ve alkol tespiti raporları alınmalıdır. Aksi halde satın alınan tüm güvenceler geçersiz sayılarak meydana gelen her türlü zarar, 3’cü şahıslara verilen maddi ve manevi kayıpları da içermek üzere kiracı öder.</p>
</blockquote>
<h3 style="box-sizing: border-box; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; font-weight: 500; line-height: 1.1; color: #333333; margin-top: 20px; margin-bottom: 10px; font-size: 24px; background-color: #ffffff;">Kaza halinde ne yapmalıyım?</h3>
<blockquote style="box-sizing: border-box; padding: 10px 20px; margin: 0px 0px 20px; font-size: 17.5px; border-left: 5px solid #eeeeee; color: #333333; font-family: &quot;helvetica neue&quot;, helvetica, arial, sans-serif; background-color: #ffffff;">
<p style="box-sizing: border-box; margin: 0px; line-height: 23px;">Aracın yerini değiştirmeden, en yakın polis veya jandarma karakoluna haber vermeli, 444 5 649 numaralı telefondan POPY Destek Hattını aramalısınız.</p>
</blockquote>
<p></p></div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
export default {
  name: "faq",
  metaInfo: () => ({
    title: 'Sıkça Sorulan Sorular',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Araç kiralama hizmeti almadan önce aklınıza takılan sorulara cevap arıyorsanız sıkça sorulan sorular sayfamızda aradığınız cevapları bulabilirsiniz. PopyCar genel müdürlüğü adres, telefon bilgileri ve iletişim formu üzerinden bize ulaşabilirsiniz.' }
    ]
  }),
  data: () =>{
    return {
    }
  }
}
</script>

<style scoped>
.btn-link {
    color: #212529 !important;
    font-weight: 600;
    text-align: left;

}
</style>
