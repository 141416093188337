<template>
<section id="subPage">
    <div class="container cont contBox">
        <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">ÇEREZ POLİTİKASI</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Çerez Politikası</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
        <div class="row box">
            <div class="col-md-12" style="text-align:justify;">
              <p><b>I.	Politikanın Kapsamı</b><a href="#" @click="ayarlariAc=true" style="float:right;">Ayarları Aç</a></p>
              <p>PopyCar Araç Kiralama A.Ş. (“PopyCar Oto Kiralama”, “biz” veya “Şirket”), www.popycar.com/ internet sitesini (“Site”) ziyaret eden kullanıcıların ziyaretlerini uygun bir şekilde gerçekleştirmelerini sağlamak ve ziyaretçi deneyimlerini iyileştirmek amacıyla sitede çerezler (cookies) kullanmaktadır. Çerez kullanılmasını tercih etmezseniz tarayıcınızın ayarlarından Çerezleri silebilir ya da engelleyebilirsiniz. Ancak bunun internet sitemizi kullanımınızı etkileyebileceğini hatırlatmak isteriz.
                <b>Tarayıcınızdan çerez ayarlarını değiştirmediğiniz ve/veya sitemiz ana sayfasında çıkan çerez  kutucukların seçilmemesi halinde gerekli/zorunlu çerezlerin kullanımı haricinde diğer çerez çeşitleri kullanılmayacaktır.</b> İşbu Çerez Politikası (“Politika”), 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) ve ilgili mevzuat uyarınca, internet sitesinin kullanımı sırasında toplanan kişisel verilerinizin nasıl işlendiği hakkında sizleri bilgilendirmek amacıyla hazırlanmıştır.
              </p>
              <p>Bu Politika, müşterilerimiz, iş ortaklarımız, iştiraklerimiz, tedarikçilerimiz, bayilerimiz ve yetkili bayi olma başvurusunda bulunanlar dahil www.popycar.com/ internet sitesini ziyaret eden ve/veya site üzerinde işlem gerçekleştiren tüm gerçek kişileri kapsamaktadır.</p>
              <p>PopyCar Oto Kiralama, Politikayı gerekli olduğu zamanlarda, sitede yeni versiyonunu yayınlayarak güncelleme hakkına sahiptir. Bu nedenle zaman zaman internet sitemizi ziyaret ederek Politika’nın son versiyonunu kontrol etmenizi veya dilediğiniz zaman bizden Politika’nın güncel halini talep etmenizi tavsiye ederiz. İncelemekte olduğunuz Politika’nın son güncelleme tarihini, Politika’nın ilk kısmında bulabilirsiniz.</p>
              <p>Anonim hale getirilen veriler Kanun kapsamında kişisel veri olarak kabul edilmemektedir. Bu nedenle, bu verilere ilişkin işleme faaliyetleri Politika kapsamına girmemektedir.</p>
              <p></p>
              <p><b>REŞİT OLMAYANLAR</b></p>
              <p>18 yaşın altındaysanız Web Sitesi’ni ebeveynlerinizin refakati altında ziyaret etmeniz gerekmektedir. Ebeveynlerinizin refakati olmadan bu Web Sitesi’ni kullanmamanız ve hiçbir kişisel verinizi paylaşmamanız gerekmektedir.</p>
              <p><b>II.	Veri Sorumlusu</b></p>
              <p>PopyCar Oto Kiralama, internet sitesi üzerinden ziyaretçilerin/kullanıcıların kişisel verilerinin toplanması ve işlenmesi süreci kapsamında, Kanuna göre veri sorumlusu olarak hareket etmektedir. Veri sorumlusu olarak, kişisel verilerinizin işleme amaçlarını ve hangi vasıtalarla işleneceğini belirliyoruz.</p>
              <p>İnternet sitemiz bazı üçüncü taraf internet sitelerine yönlendirmeler içerebilir. Bu Politika, sizi PopyCar Oto Kiralama’nın çerezler vasıtasıyla topladığı kişisel verilerin işlenme amaçları ve vasıtaları ile ilgili bilgilendirmekte olup; diğer veri sorumlularının çerez kullanımları ve kişisel verilerinizi ne şekilde işlediği hakkında bilgi sahibi olabilmeniz için, diğer veri sorumlularının kendi internet sitelerinde yer alan politikalarına göz atmanızı tavsiye ederiz.</p>
              <p><b>III.	Çerezler (Cookies) Nedir ve Nasıl Çalışır?</b></p>
              <p>Çerezler, bir internet sitesini ziyaret ettiğinizde tarayıcınıza, bilgisayarınıza veya mobil cihazınıza depolanan ve genelde harf ve rakamlardan oluşan çok küçük boyutlu metin dosyalarıdır. Çerezler vasıtasıyla site, ziyaretçilerinin kişisel verileri toplayabilmekte ise de çerezler, ziyaretçilere ilişkin isim, cinsiyet veya adres gibi kişisel verileri toplamazlar.</p>
              <p>Çerezler, bir internet sitesini ziyaret ettiğinizde bazı bilgileri tarayıcınıza, bilgisayarınıza veya mobil cihazınıza depolar. Depolanan bu bilgiler, internet sitesini her ziyaret edişinizde internet sitesine gönderilir. Bu sayede internet sitesini bir sonraki ziyaretinizde önceki ziyaretiniz ile ilgili bilgiler (kullanıcı adınız ve tercihleriniz gibi) hatırlanır.</p>
              <p>Çerezler konusundan daha detaylı bilgi için <b>www.aboutcookies.org</b> ve <b>www.allaboutcookies.org</b> adreslerini ziyaret edebilirsiniz.</p>
              <p></p>
              <p style="font-weight: bold;text-decoration: underline;">Çerez türleri, ziyaret ettiğiniz internet sitesi tarafından oluşturulan çerezler (birinci taraf çerezler) veya görüntülediğiniz sayfada içerik sunan diğer internet siteleri tarafından oluşturulan çerezler (üçüncü taraf çerezler) olarak ikiye ayrılmaktadır.</p>
              <p>Çerezler ayrıca, kullanım amaçlarına göre farklı kategorilere ayrılmaktadır. Aşağıdaki tabloda hangi çerez kategorilerinin ne kadar süre ve hangi amaçlarla kullanıldığına ilişkin açıklamalar bulunmaktadır:</p>
              <p>
                <table>
                  <tr style="text-align:center">
                    <th style="width: 200px;">ÇEREZ KATEGORİLERİ (Amaç Bakımından)</th>
                    <th>AÇIKLAMALAR</th>
                  </tr>
                  <tr>
                    <td>Teknik/Zorunlu Çerezler</td>
                    <td>Sitenin kullanımı ve işlerliği için gerekli olan çerezlerdir. Teknik çerezler devre dışı kaldığı takdirde Site’nin bir kısmına veya tümüne erişim mümkün olmayabilir. İşbu çerez kategorisi altında işlenen kişisel verileriniz, KVKK’nun 5. maddesinde yer alan “veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu oması” ve “ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydı ile, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebine dayanarak otomatik yolla işlenmektedir. Bu sebeple bu çerez kategorisi altında veri işleme faaliyeti sırasında tarafınızdan ayrıca açık rıza alınmayacaktır. </td>
                  </tr>
                  <tr>
                    <td>İstatistik Çerezleri (Performans Çerezleri) </td>
                    <td>Site ziyaretçilerinin internet sitesini nasıl kullandığı hakkında bilgi almak ve ziyaretçi deneyimlerini iyileştirmek/geliştirmek amacıyla kullanılan çerezlerdir. Bu çerezler, ziyaretçi sayısını, ziyaretçiler tarafından görüntülenen sayfalar, tıklanma sayıları, sayfaları kaydırma hareketleri ve bu sayfalara giriş ve çıkış zamanları gibi bilgileri içerir.</td>
                  </tr>
                  <tr>
                    <td>İşlevsel/Tercihler Çerezleri</td>
                    <td>Site ziyaretçilerine kişiselleştirilmiş bir deneyim sunmak amacıyla kullanılan çerezlerdir. Bu çerezler dil tercihleriniz ve kullanıcı adınız gibi tercihlerinizin internet sitesini ziyaret edişinizde hatırlanmasını sağlar.</td>
                  </tr>
                  <tr>
                    <td>Pazarlama Çerezleri</td>
                    <td>Ziyaretçilerin ilgi alanlarına göre reklam sunulması ve sunulan reklamların etkinliğini ölçmek amacıyla kullanılan çerezlerdir. Bu çerezlerin engellenmesi, reklam içeriklerini tamamen engellemek yerine genel içerikli reklamlar sunulmasına sebep olacaktır. Bu çerezler, reklam verenlerin daha alakalı reklamlar sunmasına yardımcı olmak veya bir reklamı kaç kez gördüğünüzü sınırlamak için çevrimiçi etkinliğinizi izler. Bu çerezler bu bilgileri diğer kuruluşlarla veya reklam verenlerle paylaşabilir. Bunlar kalıcı çerezler ve neredeyse her zaman üçüncü taraf kaynaklıdır.</td>
                  </tr>
                  <tr>
                    <td>Otantikasyon Çerezleri</td>
                    <td>Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş yapmaları durumunda, bu tür çerezler ile ziyaretçinin internet sitesinde ziyaret ettiği her bir sayfada şifresini yeniden girmesi önlenir. </td>
                  </tr>
                </table>
              </p>
              <p><b>IV.	Çerezler Vasıtasıyla Toplanan Kişisel Verilerinizin İşlenme Amaçları</b></p>
              <p>Çerezler vasıtasıyla toplanan kişisel verileriniz, aşağıdaki amaçlarla işlenmektedir:</p>

              <p>−	Site ziyaretçilerinin hareketlerini takip etmek,</p>

              <p>−	Ziyaretlerini uygun bir şekilde gerçekleştirmelerini temin etmek ve bir sonraki ziyaretinizde bilgilerinizin hatırlanmasını sağlamak,</p>

              <p>−	Site yapısını ve içeriğini iyileştirmek ve geliştirmek,</p>

              <p>−	Ziyaretçi kullanımlarını istatistiksel olarak değerlendirmek,</p>

              <p>−	Çevrimiçi reklamcılık faaliyetlerinde bulunabilmek,</p>

              <p>−	İnternet sitesinde yer alan içeriğin sizin ve bilgisayarınız için en etkili şekilde sunulmasını sağlamak,</p>

              <p>−	Seçmeniz durumunda hizmetlerimizden interaktif olarak yararlanmanızı sağlamak,</p>

              <p>−	Ziyaretçi tercih ve ihtiyaçları doğrultusunda özelleştirilmiş hizmetler sunabilmek,</p>

              <p>−	PopyCar Oto Kiralama’nın 5651 sayılı Internet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun ve Internet Ortamında Yapılan Yayınların Düzenlenmesine Dair Usul ve Esaslar Hakkında Yönetmelik'ten kaynaklananlar başta olmak üzere, kanuni ve sözleşmesel yükümlülüklerini yerine getirebilmesi için IP adresiniz gibi kişisel verilerinize ihtiyaç duyması,</p>
              <p><b>V.	Çerezler Vasıtasıyla Kişisel Veri Toplamanın Hukuki Sebebi</b></p>
              <p>PopyCar Oto Kiralama’nın, çerezler vasıtasıyla toplanan kişisel verilerinizi, PopyCar Oto Kiralama’nın Site’nin performans ve işlevselliğinin arttırılması, internet sitesi kullanıcılarının Site tecrübesini iyileştirme ve websitenin güvenli alanlarına erişiminin sağlanmasında bulunan meşru menfaati nedeniyle kişisel veri işlemenin KVKK’nun 5. maddesinde yer alan “veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu oması” ve “ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydı ile, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebine dayanarak işlemektedir. Zorunlu olmayan sebepler bakımından rızanız alınmadan işleme faaliyetinde bulunulmamaktadır.</p>
              <p><b>VI.	Çerezler Vasıtasıyla Toplanan Kişisel Verilerin Paylaşılması</b></p>
              <p>Çerezler vasıtasıyla topladığımız ve/veya bunları işleyerek elde ettiğimiz kişisel verilerinizi, bu Politika’nın ilgili bölümünde yer alan kişisel veri işleme amaçlarının gerçekleştirilebilmesi için internet sitemizin yayını ve işletilmesi konularında destek ve hizmet aldığımız yurt içinde ve yurtdışında bulunan tedarikçiler ve iş ortaklarımızla paylaşmaktayız. Bununla birlikte, Site’nin ve site kullanıcılarının ziyaret deneyiminin geliştirilmesi ve yazılımsal hatalardan kurtarılması amacıyla da kişisel verileriniz söz konusu taraflarla paylaşılmaktadır.</p>
              <p>Kişisel verileriniz, yasal bir talepte bulunulması durumunda hukuki yükümlülüğümüzün yerine getirilmesi amacıyla yetkili kurum ve kuruluşlarla, adli ve idari makamlarla da paylaşılabilecektir.</p>
              <p>Kişisel verileriniz, üçüncü taraf çerezleri kullanımı söz konusu olduğunda çeşitli ülkelerde bulunan sunucularda saklanabilecektir. Çerezlerin kullanımını engellemek istiyorsanız bu Politika’nın “VIII. Çerez Kullanımının Engellenmesi” başlıklı kısmına göz atmanızı rica ederiz.</p>
              <p><b>VII.	Kullanılan Çerez Çeşitleri</b></p>
              <p>PopyCar Oto Kiralama olarak, internet sitemizde aşağıda belirtilen çerez çeşitlerini kullanmaktayız:</p>
              <p>1- Zorunlu/Teknik Çerezler: Zorunlu diğer bir deyişle teknik çerezler, sayfa gezinme ve internet sitesinin güvenli alanlarına erişim gibi temel işlevleri etkinleştirerek bir internet sitesini ziyaretçiler için kullanılabilir hale getirmeye yardımcı olur. İnternet sitesi bu çerezler olmadan düzgün çalışamaz.</p>
              <p><table>
                  <tr style="text-align:center">
                    <th>Sağlayıcı</th>
                    <th>Kullanılan Çerez İsmi </th>
                    <th>Çerez Kullanımına İlişkin Açıklamalar </th>
                    <th>Tür</th>
                    <th>Sonlanma</th>
                  </tr>
                  <tr>
                    <td>popycar.com</td>
                    <td>ASP.NET_Sessionld</td>
                    <td>Bir tür oturum çerezi (session cookies) olup; internet sitesi
kullanıcısı bir sayfadan diğer
sayfaya geçerken kullanıcının
oturum durumuna ilişkin bilgileri
tutar. Bu bilgiler oturum sırasında
geçici olarak depolanır ve en kısa
sürede tarayıcınızı kapattıktan
sonra kaldırılır. </td>
                    <td>HTTP</td>
                    <td>Oturum</td>
                  </tr>
                </table>
                </p>
              <p>2- İstatistik (Performans) Çerezleri: Analitik çerezler, internet sitesi sahiplerine, bilgileri anonim olarak toplayıp bildirerek ziyaretçilerin web siteleriyle nasıl etkileşime girdiklerini anlamalarına yardımcı olur.</p>
              <p>
                <table>
                <tr style="text-align:center">
                    <th>Sağlayıcı</th>
                    <th>Kullanılan Çerez İsmi </th>
                    <th>Çerez Kullanımına İlişkin Açıklamalar </th>
                    <th>Tür</th>
                    <th>Sonlanma</th>
                  </tr>
                  <tr>
                    <td>popycar.com</td>
                    <td>_ga</td>
                    <td>İnternet sitesi ziyaretçisinin internet
sitesini nasıl kullandığı hakkında
istatistiksel veri üretmek için kullanılan
tekil ID kaydederek çalışır.
</td>
                    <td>HTTP</td>
                    <td>2 Yıl</td>
                  </tr>
                  <tr>
                    <td>popycar.com</td>
                    <td>_gat</td>
                    <td>Google Analytics tarafından internet
sitesi üzerinden istek oranını kısmak ve
ziyaretçilerin internet sitesi kullanımını
izlemek için kullanılır.
</td>
                    <td>HTTP</td>
                    <td>1 Gün</td>
                  </tr>
                  <tr>
                    <td>popycar.com</td>
                    <td>_gid</td>
                    <td>	İnternet sitesi ziyaretçisinin internet
	sitesini nasıl kullandığı hakkında
	istatistiksel veri üretmek için kullanılan
	tekil ID kaydederek çalışır.
</td>
                    <td>HTTP</td>
                    <td>1 Gün</td>
                  </tr>
                </table>
                </p>
              <p>3- Pazarlama Çerezleri: Hedefleme/reklam çerezleri internet sitelerini ziyaret edenleri izlemek için kullanılır. Amaç, bireysel kullanıcı için alakalı ve ilgi çekici reklamlar göstermektir.</p>
              <p>
                 <table>
                <tr style="text-align:center">
                    <th>Sağlayıcı</th>
                    <th>Kullanılan Çerez İsmi </th>
                    <th>Çerez Kullanımına İlişkin Açıklamalar </th>
                    <th>Tür</th>
                    <th>Sonlanma</th>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>GPS</td>
                    <td>Coğrafi GPS konumuna göre
izlemeyi etkinleştirmek için mobil
cihazlara özgün bir kimlik
numarası (ID) kaydeder.
</td>
                    <td>HTTP</td>
                    <td>1 gün</td>
                  </tr>
                  <tr>
                    <td>doubleclick.net</td>
                    <td>IDE</td>
                    <td>Bir reklamın etkinliğini ölçmek ve
kullanıcıya hedefli reklamlar
sunmak amacıyla, reklamverenin
reklamlarından birini
görüntüledikten veya tıkladıktan
sonra internet sitesi kullanıcısının
eylemlerini kaydetmek ve
raporlamak için Google
DoubleClick tarafından kullanılır.

</td>
                    <td>HTTP</td>
                    <td>1 Yıl</td>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>PREF</td>
                    <td>Ziyaretçinin YouTube videolarını
farklı internet sitelerinde nasıl
kullandığına ilişkin istatistikleri
tutmak için Google tarafından
kullanılan özgün bir kimlik
numarası (ID) kaydeder.
</td>
                    <td>HTTP</td>
                    <td>8 ay</td>
                  </tr>
                  <tr>
                    <td>doubleclick.net</td>
                    <td>test_cookie</td>
                    <td>Ziyaretçinin tarayıcısının çerezleri
destekleyip desteklemediğini
kontrol etmek için kullanılır.
</td>
                    <td>HTTP</td>
                    <td>1 gün</td>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>VISITOR_INFO1_LIVE</td>
                    <td>Entegre YouTube videoları içeren
sayfalarda kullanıcıların bant
genişliğini tahmin etmeye çalışır.
</td>
                    <td>HTTP</td>
                    <td>179 gün</td>
                  </tr>
                 </table>
              </p>
              <p> <table>
                <tr style="text-align:center">
                    <th>Sağlayıcı</th>
                    <th>Kullanılan Çerez İsmi </th>
                    <th>Çerez Kullanımına İlişkin Açıklamalar </th>
                    <th>Tür</th>
                    <th>Sonlanma</th>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>YSC</td>
                    <td>Ziyaretçinin YouTube’da gördüğü
videoların istatistiklerini tutmak
için özgün bir kimlik numarası (ID)
kaydeder.
</td>
                    <td>HTTP</td>
                    <td>Oturum</td>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>	yt-remote-cast-
	installed
</td>
                    <td>Ziyaretçinin video oynatıcı
tercihlerini yerleşik YouTube
videosu kullanarak saklar.
</td>
                    <td>HTTP</td>
                    <td>Oturum</td>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>yt-remote-
connected-devices
</td>
                    <td>Ziyaretçinin video oynatıcı
tercihlerini yerleşik YouTube
videosu kullanarak saklar.
</td>
                    <td>HTTP</td>
                    <td>Kalıcı</td>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>yt-remote-device-id</td>
                    <td>Ziyaretçinin video oynatıcı
tercihlerini yerleşik YouTube
videosu kullanarak saklar.

</td>
                    <td>HTTP</td>
                    <td>Kaılıcı</td>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>yt-remote-fast-
check-period
</td>
                    <td>Ziyaretçinin video oynatıcı
tercihlerini yerleşik YouTube
videosu kullanarak saklar.

</td>
                    <td>HTTP</td>
                    <td>Oturum</td>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>	yt-remote-session-
	app
</td>
                    <td>Ziyaretçinin video oynatıcı
tercihlerini yerleşik YouTube
videosu kullanarak saklar.

</td>
                    <td>HTTP</td>
                    <td>Oturum</td>
                  </tr>
                  <tr>
                    <td>youtube.com</td>
                    <td>yt-remote-session-name</td>
                    <td>Ziyaretçinin video oynatıcı
tercihlerini yerleşik YouTube
videosu kullanarak saklar.</td>
                    <td>HTTP</td>
                    <td>Oturum</td>
                  </tr>
                 </table>
                 </p>
              <p><b>VIII.	Çerez Kullanımının Engellenmesi</b></p>
              <p>Tarayıcınızın ayarlarını değiştirerek çerezleri etkinleştirebilir veya devre dışı bırakabilirsiniz. Fakat, çerezleri devre dışı bırakmanız durumunda Sitenin bazı özelliklerinden yararlanmanız mümkün olmayabilir. Çerezleri nasıl devre dışı bırakacağınız hakkında, aşağıda tarayıcınıza özgü linke tıklayarak yardım alabilirsiniz:</p>
              <p>Google Chrome: 	<a href="support.google.com/chrome/answer/95647" target="_blank">support.google.com/chrome/answer/95647</a></p>
              <p>Internet Explorer:	<a href="support.microsoft.com/tr/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">support.microsoft.com/tr/help/17442/windows-internet-explorer-delete-manage-cookies</a></p>
              <p>Mozilla Firefox	<a href="support.mozilla.org/tr/kb/cerezleri-silme-web-sitelerinin-bilgilerini-kaldirma" target="_blank">support.mozilla.org/tr/kb/cerezleri-silme-web-sitelerinin-bilgilerini-kaldirma</a></p>
              <p>Safari	<a href="support.apple.com/tr-tr/guide/safari/sfri11471/mac" target="_blank">support.apple.com/tr-tr/guide/safari/sfri11471/mac</a></p>
              <p>Opera	<a href="www.operaturkiye.net/arsiv/yardim/Windows/10.00/tr/cookies.html" target="_blank">www.operaturkiye.net/arsiv/yardim/Windows/10.00/tr/cookies.html</a></p>
              <p>Google Analytics	<a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a></p>
              <p>Google AdWords	<a href="https://support.google.com/ads/answer/2662922?hl=en" target="_blank">https://support.google.com/ads/answer/2662922?hl=en</a></p>
              <p>Adobe Analytics	<a href="http://www.adobe.com/uk/privacy/opt-out.html" target="_blank">http://www.adobe.com/uk/privacy/opt-out.html</a></p>
              <p><b>IX.	Çerezler Vasıtasıyla Toplanan Kişisel Verileri İşlerken Uyduğumuz İlkeler</b></p>
              <p>PopyCar Oto Kiralama olarak, kişisel verilerinizi işlediğimiz tüm süreçlerde aşağıdaki ilkeleri dikkate alıyor ve kişisel verilerinizi bu ilkelere uygun olarak işliyoruz:</p>
              <p>(a)	Hukuka ve dürüstlük kurallarına uygun olma,</p>

              <p>(b)	Doğru ve gerektiğinde güncel olma,</p>

              <p>(c)	Belirli, açık ve meşru amaçlar için işlenme,</p>

              <p>(d)	İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma ilkesi,</p>

              <p>(e)	İlgili mevzuatta öngörülen ve işlendikleri amaç için gerekli olan süre kadar muhafaza edilme ilkesi.</p>

              <p>X.	Çerez Kullanımı Kapsamında Sahip Olduğunuz Haklar</p>
              <p>PopyCar Oto Kiralama tarafından, çerezler vasıtasıyla toplanan kişisel verilerinize ilişkin aşağıda belirtilen haklara sahipsiniz:</p>
              <p>1.	Kişisel verilerinizin işlenip işlenmediğini öğrenme ve işliyor isek bunlara ilişkin bilgi edinme,</p>

              <p>2.	Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</p>

              <p>3.	Yurt içinde veya yurt dışında kişisel verilerinizi – var ise- aktardığımız üçüncü kişileri öğrenme,</p>

              <p>4.	Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin -var ise- kişisel verileri aktardığımız üçüncü kişilere bildirmemizi isteme,</p>

              <p>5.	Kanun ve ilgili mevzuata uygun olarak işlemiş olmamıza rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin -var ise- kişisel verilerinizi aktardığımız üçüncü kişilere bildirmemizi isteme,</p>

              <p>6.	İşlediğimiz kişisel verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhine bir sonucun ortaya çıktığı durumlar var ise bunlara itiraz etme,</p>

              <p>7.	Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararınızın giderilmesini talep etme.</p>

              <p>Yukarıda belirtilen haklardan bir veya birkaçını kullanmak isterseniz, taleplerinizi, “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe” göre “www.popycar.com/” web adresindeki “Veri Sahibi Başvuru Formu” nu doldurarak;</p>
              <p>Fatih Sultan Mehmet Mah. Poligon Cd. BuYaka Kuleler Kule:3 Kat:6 Daire No 37-42 Ümraniye/İstanbul adresine bizzat teslim etme sureti ile, </p>
<p>-	Noter kanalı ile,</p>
<p>-	centraloto@hs03.kep.tr adresine güvenli elektronik ya da mobil imzalı olarak, </p>
<p>-	veya sistemimizde kayıtlı elektronik e-posta adresiniz aracılığıyla</p>

              <p>iletebileceğiniz gibi isbu taleplerinizi Kişisel Verilerin Korunması Kurulu’nun belirlediği diğer yöntemleri kullanmak sureti ile de Şirketimize iletmeniz mümkündür./p>
              <p>Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit edici gerekli bilgiler ve kullanmak istediğiniz hakkınıza yönelik açıklamalarınızla birlikte talebinizi KVKK’un 11. maddesinde belirtilen hangi hakkınızın kullanımına ilişkin olduğunu da belirterek bizlere iletmeniz takdirde talebinize ilişkin başvurunuzun en kısa sürede ve en geç 30 (otuz) gün içinde cevaplandırılması sağlanacaktır.</p>
              <p>Başvuruya yetkili olup olmadığınızı tespit etmek veya talebinizi daha hızlı yanıtlayabilmek için sizden bazı ek bilgiler istenebilecektir. Bu konuda detaylı açıklamalar Veri Sahibi Başvuru Formumuzda yer almaktadır.</p>
              <p>PopyCar Oto Kiralama’nın faaliyet ve operasyonlarını sürdürürken kişisel verilerinizin işlenmesine ilişkin takip ettiği genel prensiplerle ilgili bilgi almak için  www.popycar.com/kvkk internet sitesinde yer alan ana PopyCar Oto Kiralama A.Ş. Kişisel Verilerin İşlenmesi Ve Korunması Politikası’nı inceleyebilirsiniz.</p>
              <p></p>
              <p></p>
              <p></p>
            </div>
        </div>
<md-dialog-alert
:md-active.sync="ayarlariAc"
md-title="Çerez Ayaları"
md-content='<div class="col-md-12">
  <div class="form-bottom">
      <div class="form-group">
          <div class="form-check">
              <input class="form-check-input is-invalid" type="checkbox" name="teknik" checked="" disabled="" value="1" id="teknik" required="">
              <label class="form-check-label" for="teknik">
                  Teknik/Zorunlu Çerezler
              </label>
              <div class="invalid-feedback">
                  Sitenin kullanımı ve işlerliği için gerekli olan çerezlerdir. Teknik çerezler devre dışı kaldığı takdirde Site’nin bir kısmına veya tümüne erişim mümkün olmayabilir. İşbu çerez kategorisi altında işlenen kişisel verileriniz, KVKK’nun 5. maddesinde yer alan “veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu oması” ve “ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydı ile, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebine dayanarak otomatik yolla işlenmektedir. Bu sebeple bu çerez kategorisi altında veri işleme faaliyeti sırasında tarafınızdan ayrıca açık rıza alınmayacaktır.                                     </div>
          </div>
      </div>
      <div class="form-group">
          <div class="form-check">
              <input class="form-check-input is-invalid" type="checkbox" name="analitik" checked="" value="1" id="analitik">
              <label class="form-check-label" for="analitik">
                  İstatistik Çerezleri(Performans Çerezleri)
              </label>
              <div class="invalid-feedback">
                  Site ziyaretçilerinin internet sitesini nasıl kullandığı hakkında bilgi almak ve ziyaretçi deneyimlerini iyileştirmek/geliştirmek amacıyla kullanılan çerezlerdir. Bu çerezler, ziyaretçi sayısını, ziyaretçiler tarafından görüntülenen sayfalar, tıklanma sayıları, sayfaları kaydırma hareketleri ve bu sayfalara giriş ve çıkış zamanları gibi bilgileri içerir.
              </div>
          </div>
      </div>
      <div class="form-group">
          <div class="form-check">
              <input class="form-check-input is-invalid" type="checkbox" name="tercih" checked="" value="1" id="tercih">
              <label class="form-check-label" for="islevsel">
                  İşlevsel/Tercihler Çerezleri
              </label>
              <div class="invalid-feedback">
                  Site ziyaretçilerine kişiselleştirilmiş bir deneyim sunmak amacıyla kullanılan çerezlerdir. Bu çerezler dil tercihleriniz ve kullanıcı adınız gibi tercihlerinizin internet sitesini ziyaret edişinizde hatırlanmasını sağlar.
              </div>
          </div>
      </div>
      <div class="form-group">
          <div class="form-check">
              <input class="form-check-input is-invalid" type="checkbox" name="pazarlama" checked="" value="1" id="pazarlama">
              <label class="form-check-label" for="tercih">
                  Pazarlama Çerezleri
              </label>
              <div class="invalid-feedback">
                  Ziyaretçilerin ilgi alanlarına göre reklam sunulması ve sunulan reklamların etkinliğini ölçmek amacıyla kullanılan çerezlerdir. Bu çerezlerin engellenmesi, reklam içeriklerini tamamen engellemek yerine genel içerikli reklamlar sunulmasına sebep olacaktır.
                  Bu çerezler, reklam verenlerin daha alakalı reklamlar sunmasına yardımcı olmak veya bir reklamı kaç kez gördüğünüzü sınırlamak için çevrimiçi etkinliğinizi izler. Bu çerezler bu bilgileri diğer kuruluşlarla veya reklam verenlerle paylaşabilir. Bunlar kalıcı çerezler ve neredeyse her zaman üçüncü taraf kaynaklıdır.
              </div>
          </div>
      </div>
      <div class="form-group">
          <div class="form-check">
              <input class="form-check-input is-invalid" type="checkbox" name="otantikasyon" checked="" value="1" id="otantikasyon">
              <label class="form-check-label" for="pazarlama">
                  Otantikasyon Çerezleri
              </label>
              <div class="invalid-feedback">
                  Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş yapmaları durumunda, bu tür çerezler ile ziyaretçinin internet sitesinde ziyaret ettiği her bir sayfada şifresini yeniden girmesi önlenir.                                 </div>
          </div>
      </div>
</div>
</div>'
md-confirm-text="Devam" />
    </div>
</section>
</template>
<script>
export default {
  name: "cookiepolicy",
  data: () =>{
    return {
      ayarlariAc: false
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.btn-link {
    color: #212529 !important;
    font-weight: 600;
}
td, th {
  border: 1px solid #dddddd;
  padding: 8px;
}
</style>
