<template>
 <section id="subPage">
	<div class="container">
		<div class="row">
        <div class="col-md-3">
          <MenuPanelim></MenuPanelim>
        </div>
            <div class="col-md-9 cont contProfil">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">Kiralama Detayı <small>#{{this.$route.params.kiralama_id}}</small></a>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                             <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/panelim">Panelim</router-link></li>
                <li class="nav-item"><span class="nav-link disabled">{{this.$route.name}}</span></li>
                        </ul>
                    </div>
                </nav>
                  <div class="row">
                      <div class="col-md-12" ref="formContainer">
                        <div class="card" style="border: solid 2px #b5111f;-webkit-border-radius: 2px;-moz-border-radius: 2px;border-radius: 2px;">
                                <div class="card-header" style="border-bottom: solid 1px #e54250;">
                                    <span style="font-size: 1.25rem;font-weight: 500;line-height: 1.2;">{{sonuc.VehicleGroupLong}}
                                        <small>veya benzeri</small>
                                    </span>
                                    <strong class="float-sm-right">
                                      <span>{{sonuc.durum}}</span>
                                    </strong>
                                </div>
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-6">
                                        <h5 class="card-title">Genel Bilgiler</h5>
                                        <table class="table">
                                          <tr>
                                            <td><strong>Sözleşme No</strong></td>
                                            <td>#{{this.$route.params.kiralama_id}}</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Başlangıç</strong></td>
                                            <td>{{sonuc.StartLocation}} <br> {{sonuc.StartDate}} {{sonuc.StartHour}}</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Bitiş</strong></td>
                                            <td>{{sonuc.EndLocation}} <br> {{sonuc.EndDate}} {{sonuc.EndHour}}</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Kiralama Süresi</strong></td>
                                            <td>{{sonuc.kiralamaGun}} Gün</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Teminat Tutarı</strong></td>
                                            <td>{{sonuc.provizyon_tutari}} TL</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Günlük Tutar</strong></td>
                                            <td>{{sonuc.GunlukTutar}} TL</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Oluşturulma</strong></td>
                                            <td>{{sonuc.OlusturulmaTarihi}}</td>
                                          </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-6" v-if="sonuc.EkSurucu==1">

                                            <h5 class="card-title">Ek Sürücü Bilgileri</h5>

                                            <table class="table">
                                          <tr>
                                            <td><strong>Ad Soyad</strong></td>
                                            <td>{{sonuc.EkSurucuAd}} {{sonuc.EkSurucuSoyad}}</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Uyruk</strong></td>
                                            <td>{{sonuc.EkSurucuUyruk}}</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Kimlik No</strong></td>
                                            <td>{{sonuc.EkSurucuKimlikNo}}</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Telefon</strong></td>
                                            <td>{{sonuc.EkSurucuGsm}}</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Ehliyet Numarası</strong></td>
                                            <td>{{sonuc.EkSurucuEhliyetNo}}</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Ehliyet Tarihi</strong></td>
                                            <td>{{sonuc.EkSurucuEhliyetTarih}}</td>
                                          </tr>

                                        </table>
                                     </div>

                                    <div class="col-md-6" >
                                         <img v-bind:src="`${sonuc.VehiclePhoto}`" class="leaseDetailArac">
                                        <h5 class="card-title">Kiralama Tutarı</h5>
                                        <table class="table">
                                          <tr v-if="sonuc.SarjAleti==1">
                                            <td><strong>{{sonuc.NavigasyonTitle}} :</strong></td>
                                            <td>{{sonuc.NavigasyonTutar}} TL</td>
                                          </tr>
                                          <tr v-if="sonuc.KisLastigi==1">
                                            <td><strong>{{sonuc.KisLastigiTitle}} :</strong></td>
                                            <td>{{sonuc.KisLastigiTutar}} TL</td>
                                          </tr>
                                          <tr v-if="sonuc.BebekKoltugu==1">
                                            <td><strong>{{sonuc.BebekKoltuguTitle}} :</strong></td>
                                            <td>{{sonuc.BebekKoltuguTutar}} TL</td>
                                          </tr>
                                          <tr v-if="sonuc.CocukKoltugu==1">
                                            <td><strong>{{sonuc.CocukKoltuguTitle}} :</strong></td>
                                            <td>{{sonuc.CocukKoltuguTutar}} TL</td>
                                          </tr>
                                          <tr v-if="sonuc.SCDW==1">
                                            <td><strong>{{sonuc.SCDWTitle}} :</strong></td>
                                            <td>{{sonuc.SCDWTutar}} TL</td>
                                          </tr>
                                          <tr v-if="sonuc.SuperSCDW==1">
                                            <td><strong>{{sonuc.SuperSCDWTitle}} :</strong></td>
                                            <td>{{sonuc.SuperSCDWTutar}} TL</td>
                                          </tr>
                                          <tr v-if="sonuc.LCF==1">
                                            <td><strong>{{sonuc.LCFTitle}} :</strong></td>
                                            <td>{{sonuc.LCFTutar}} TL</td>
                                          </tr>
                                          <tr v-if="sonuc.TPEkstra==1">
                                            <td><strong>{{sonuc.TPEkstraTitle}} :</strong></td>
                                            <td>{{sonuc.TPEkstraTutar}} TL</td>
                                          </tr>
                                          <tr v-if="sonuc.GencSurucuSigortasi==1">
                                            <td><strong>{{sonuc.GencSurucuSigortasiTitle}} :</strong></td>
                                            <td>{{sonuc.GencSurucuSigortasiTutar}} TL</td>
                                          </tr>
                                          <tr v-if="sonuc.EkSurucu==1">
                                            <td><strong>{{sonuc.EkSurucuTitle}} :</strong></td>
                                            <td>{{sonuc.EkSurucuTutar}} TL</td>
                                          </tr>

                                          <tr v-if="sonuc.MaximumGuvence==1">
                                            <td><strong>{{sonuc.MaximumGuvenceTitle}} :</strong></td>
                                            <td>{{sonuc.MaximumGuvenceTutar}} TL</td>
                                          </tr>

                                          <tr v-if="sonuc.MaliMesuliyet==1">
                                            <td><strong>{{sonuc.MaliMesuliyetTitle}} :</strong></td>
                                            <td>{{sonuc.MaliMesuliyetTutar}} TL</td>
                                          </tr>

                                          <tr v-if="sonuc.FerdiKaza==1">
                                            <td><strong>{{sonuc.FerdiKazaTitle}} :</strong></td>
                                            <td>{{sonuc.FerdiKazaTutar}} TL</td>
                                          </tr>

                                          <tr v-if="sonuc.IptalGuvence==1">
                                            <td><strong>{{sonuc.IptalGuvenceTitle}} :</strong></td>
                                            <td>{{sonuc.IptalGuvenceTutar}} TL</td>
                                          </tr>

                                          <tr>
                                            <td><strong>Kiralama Tutarı :</strong></td>
                                            <td>{{sonuc.KiralamaTutar}} TL</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Toplam Tutar  :</strong></td>
                                            <td>{{sonuc.Toplam}} TL</td>
                                          </tr>


                                        </table>


                                    </div>
<div class="col-md-12">
    <div class="row corporateTab">
			<div class="col-md-12">
				<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
					<li class="nav-item"><a class="nav-link active" id="tab_1" data-toggle="pill" href="#tab_id_1" role="tab" aria-controls="tab_id_1" aria-selected="true">Ödemeler</a></li>
					<li class="nav-item"><a class="nav-link" id="tab_2" data-toggle="pill" href="#tab_id_2" role="tab" aria-controls="tab_id_2" aria-selected="false">HGS Geçişlerim</a></li>
					<li class="nav-item"><a class="nav-link" id="tab_3" data-toggle="pill" href="#tab_id_3" role="tab" aria-controls="tab_id_3" aria-selected="false">Trafik Cezalarım</a></li>
				</ul>
				<div class="navBorder"></div>

			</div>
		</div>
        	<div class="tab-content" id="pills-tabContent">
			<!-- tab_id_1 start -->
			<div class="tab-pane fade show active" id="tab_id_1" role="tabpanel" aria-labelledby="tab_1">
			<md-table>
                <md-table-row>
                    <md-table-head>Makbuz No</md-table-head>
                    <md-table-head>Tarih</md-table-head>
                    <md-table-head>Tip</md-table-head>
                    <md-table-head>Tutar</md-table-head>
                    <md-table-head>Kredi Kartı</md-table-head>
                </md-table-row>
                <md-table-row v-for="odeme in sonuc.Odemeler"  v-bind:key="odeme.CollectionId" v-bind:data="odeme">
                    <md-table-cell>{{odeme.VoucherNumber}}</md-table-cell>
                    <md-table-cell>{{odeme.CollectionDate}}</md-table-cell>
                    <md-table-cell>{{odeme.CollectionType}}</md-table-cell>
                    <md-table-cell>{{odeme.Amount}}</md-table-cell>
                    <md-table-cell>{{odeme.CreditCardNumber}}</md-table-cell>
                </md-table-row>
            </md-table>
			</div>
			<!-- tab_id_1 end -->

			<!-- tab_id_2 start -->
			<div class="tab-pane fade" id="tab_id_2" role="tabpanel" aria-labelledby="tab_2">
			<md-table>
                <md-table-row>
                    <md-table-head>Kayıt Tarihi</md-table-head>
                    <md-table-head>Geçiş Tarihi</md-table-head>
                    <md-table-head>Geçiş Yeri</md-table-head>
                    <md-table-head>Tutar</md-table-head>
                </md-table-row>
                <md-table-row v-for="hgs in sonuc.HGSGecislerim"  v-bind:key="hgs.GecisId" v-bind:data="hgs">
                    <md-table-cell>{{hgs.kayit_tarihi}}</md-table-cell>
                    <md-table-cell>{{hgs.ExitDateTime}}</md-table-cell>
                    <md-table-cell>{{hgs.ExitLocation}}</md-table-cell>
                    <md-table-cell>{{hgs.Amount}}</md-table-cell>
                </md-table-row>
            </md-table>
			</div>
			<!-- tab_id_2 end -->

			<!-- tab_id_3 start -->
			<div class="tab-pane fade" id="tab_id_3" role="tabpanel" aria-labelledby="tab_3">
			<md-table>
                <md-table-row>
                    <md-table-head>Makbuz No</md-table-head>
                    <md-table-head>Ceza Tarihi</md-table-head>
                    <md-table-head>Ceza Yeri</md-table-head>
                    <md-table-head>Tutar</md-table-head>
                </md-table-row>
                <md-table-row v-for="tpc in sonuc.TPClerim"  v-bind:key="tpc.TPCId" v-bind:data="tpc">
                    <md-table-cell>{{tpc.makbuz_no}}</md-table-cell>
                    <md-table-cell>{{tpc.tarih}}</md-table-cell>
                    <md-table-cell>{{tpc.adres}}</md-table-cell>
                    <md-table-cell>{{tpc.PenaltyNetAmount}}</md-table-cell>
                </md-table-row>
            </md-table>
			</div>
			<!-- tab_id_3 end -->

		</div>

  </div>
                                </div>
                                </div>
                            </div>
                       </div>
                  </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
import axios from 'axios'
import MenuPanelim from './MenuPanelim'
export default {
  name: 'profileleasedetail',
  metaInfo: () => ({
    title: 'Kiralamalarım Detay',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Kiralamalarım Detay' }
    ]
  }),
  components: {
    MenuPanelim,
  },
  data: () => ({
    sonuc: [],
    isLoading: false,
    fullPage: true,
    loader: 'bars',
    color: '#d2202f',
  }),
  mounted () {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
      /*height: '120px',
      width: '120px',*/
    });
    axios.get('https://taiga.rentsys.net/API/WEB/MyLeaseDetail.php?Auth='+this.$store.state.GetServerData.member.Token +'&KiralamaId='+this.$route.params.kiralama_id, {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }}).then(response => {
      this.sonuc = response.data
      loader.hide()
    });
  },
}
</script>

<style scoped>
  .rezler {
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 10px 20px 30px 20px;
}
.md-list-item-content {
    min-height: 18px;
}
#subPage .corporateTab .nav-pills .nav-link {
    border-radius: 0;
    border-bottom: solid 6px rgba(0, 0, 0, 0);
    font-size: 17px;
    line-height: 47px;
    font-weight: 700;
    background-color: unset;
    color: #a5a5a5;
    padding: 0px 5px 0px 0px;
    margin: 0px 70px 0px 0px;
}
#subPage .corporateTab .navBorder {
    height: 3px;
    background-color: #cecece;
    display: block;
    margin-top: -3px;
    margin-bottom: 0px;
}
.leaseDetailArac{
    padding: 10px 10px 40px 10px;
}
</style>
