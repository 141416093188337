<template>
  <section id="subPage">
    <div class="container cont contBox">
      <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">KAMPANYALAR</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><span class="nav-link disabled">Kampanyalar</span></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="row box">
        <div class="col-md-4 details" v-for="result in sonuc"   v-bind:data="result" v-bind:key="result.Id">
          <router-link :to="`/kampanya/${result.Title_SEO}/${result.Id}`" v-bind:title="`${result.Title}`">
            <img v-bind:src="`${result.Image}`" v-bind:alt="`${result.Title}`">
            <h2>{{result.Title}}</h2>
            <span>Detaylı bilgi <i class="fas fa-long-arrow-alt-right"></i></span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
export default {
  name: 'campaigns',
  metaInfo: () => ({
    title: 'Araç Kiralama Kampanyaları',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: "Araç kiralama kampanyaları ile avantajlı fiyatlarla araç kiralama hizmeti almak ister misiniz? PopyCar kampanyalarını incelemek için tıklayın!" }
    ]
  }),
  data: () => ({
    isLoading: false,
    fullPage: true,
    loader: 'bars',
    color: '#d2202f',
    sonuc: []
  }),
  mounted() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
    });
    axios.get(process.env.VUE_APP_API_URL+'kampanyalar.php?kampanyaList=1',  {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
    }}).then(response => {
      this.sonuc = response.data;
      loader.hide()
    });
  }
}
</script>
