<template>
<section id="subPage">
    <div class="container cont contBox">
        <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">GİZLİLİK</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Gizlilik</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
        <div class="row box">
            <div class="col-md-12">
                <p>Kişisel Bilgileri Koruma Politikası</p>
                <p>Sitenin bazı bölümlerinde sizden TC kimlik nosu, telefon, adres gibi kişisel bilgileriniz istenmektedir. Rezervasyon bilgilerinizin doğruluğunun teyit edilebilmesi ve gerektiğinde size ulaşabilmemiz açısından önem taşıyan bu bilgileri gerekli şekilde kayıt altına alıyoruz. Bilgilerinizin internet üzerinden bir başkası tarafından izlenmemesi için Secure Socket Layer ( SSL ) adı verilen şifreleme teknolojisi kullanılıyor. Gizlilik politikamız gereği kişisel bilgileriniz en yüksek derecede şirketimizce koruma altında tutulmakta ve amacı dışında kullanılmamaktadır. Yasaların bizi zorunlu kıldığı hallerde, açılan veya açılacak olan yasal soruşturmalar sebebiyle, yasal haklarımızı korumak amacıyla kişisel bilgilerinizi yetkili mercilerle paylaşabiliriz.

Şirketimiz , “Gizlilik Politikamızda ” değişiklik yapma hakkını saklı tutar. Bu ilkelerinin maddelerinde bir değişiklik yapılması durumunda, söz konusu düzenleme burada görünecektir. Gizlilik ilkesinde yapılan herhangi bir değişiklikten sonra bu siteyi kullanmanız söz konusu değişikliği kabul ettiğiniz anlamına geleceği için, bu sayfayı düzenli aralıklarla kontrol etmenizi öneririz.</p>
            </div>
        </div>
    </div>
</section>
</template>
<script>
export default {
  name: "privacy",
  metaInfo: () => ({
    title: 'Kişisel Bilgileri Koruma Politikası',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Kişisel Bilgileri Koruma Politikası' }
    ]
  }),
  data: () =>{
    return {
    }
  }
}
</script>

<style scoped>
.btn-link {
    color: #212529 !important;
    font-weight: 600;
}
</style>
