<template>
 <section id="subPage">
	<div class="container">
		<div class="row">
			<div class="col-md-4">
				<MenuPanelim></MenuPanelim>
			</div>
            <div class="col-md-8 cont contProfil">
    <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">Trafik Cezalarım</a>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/panelim">Panelim</router-link></li>
                <li class="nav-item"><span class="nav-link disabled">{{this.$route.name}}</span></li>
            </ul>
        </div>
    </nav>

        <div class="row" style="text-align:center">

<div class="col-md-12" ref="formContainer">
                        <div class="card" style="border: solid 2px #b5111f;-webkit-border-radius: 2px;-moz-border-radius: 2px;border-radius: 2px;" v-if="sonuc.length">
                                <div class="card-header" style="border-bottom: solid 1px #e54250;">
                                    <span style="font-size: 1.25rem;font-weight: 500;line-height: 1.2;">
                                        Trafik Cezalarım
                                    </span>
                                </div>
                                <div class="card-body">
                                  <div class="row">




                                <md-table>
                                    <md-table-row>
                                        <md-table-head>Sözleşme No</md-table-head>
                                        <md-table-head>Makbuz No</md-table-head>
                                        <md-table-head>Ceza Tarihi</md-table-head>
                                        <md-table-head>Ceza Yeri</md-table-head>
                                        <md-table-head>Tutar</md-table-head>
                                    </md-table-row>
                                    <md-table-row v-for="tpc in sonuc"  v-bind:key="tpc.TPCId" v-bind:data="tpc">
                                        <md-table-cell><router-link :to="`/kiralama-detay-${tpc.KiralamaId}`">{{tpc.KiralamaId}}</router-link></md-table-cell>
                                        <md-table-cell>{{tpc.makbuz_no}}</md-table-cell>
                                        <md-table-cell>{{tpc.tarih}}</md-table-cell>
                                        <md-table-cell>{{tpc.adres}}</md-table-cell>
                                        <md-table-cell>{{tpc.PenaltyNetAmount}}</md-table-cell>
                                    </md-table-row>
                                </md-table>



                                        </div>
                                </div>

                                </div>
                                <div class="row" v-if="!sonuc.length">
                                    <div class="col-md-12">
                                      <img style="width:35%" src="../../static/assets/img/car-not-found.png"><br>
                                      <h3>Hiç cezanız yok</h3><br>
                                      <md-button class="md-primary md-raised" to="/">HEMEN ARAÇ KİRALA</md-button>

                                    </div>
                                </div>
                            </div>
                       </div>
                  </div>
        </div>
</div>
</section>
</template>
<script>
import axios from 'axios'
import MenuPanelim from './MenuPanelim'
export default {
  name: 'ProfilePayments',
  metaInfo: () => ({
    title: 'Trafik Cezalarım',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Trafik Cezalarım' }
    ]
  }),
  components: {
    MenuPanelim,
  },
  data: () => ({
    sonuc: [],
    isLoading: false,
    fullPage: true,
    loader: 'bars',
    color: '#d2202f',
  }),
  mounted () {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.$refs.formContainer,
      loader: this.loader,
      color: this.color,
      opacity: 0.90,
      /*height: '120px',
      width: '120px',*/
    });
    axios.get('https://taiga.rentsys.net/API/WEB/MyTrafficFines.php?Auth='+this.$store.state.GetServerData.member.Token, {withCredentials: true, headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }}).then(response => {
      this.sonuc = response.data
      loader.hide()
    });
  },
}
</script>

<style scoped>
.baslik{
    display: inline-block;
    padding: 0;
    line-height: 43px;
    font-size: 20px!important;
    font-weight: 400;
    border-bottom: solid 2px #d01827;
    color: #000;
    text-transform: capitalize;
}
.rezler {
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 10px 20px 30px 20px;
}
.md-list-item-content {
    min-height: 18px;
}
#subPage .corporateTab .nav-pills .nav-link {
    border-radius: 0;
    border-bottom: solid 6px rgba(0, 0, 0, 0);
    font-size: 17px;
    line-height: 47px;
    font-weight: 700;
    background-color: unset;
    color: #a5a5a5;
    padding: 0px 5px 0px 0px;
    margin: 0px 70px 0px 0px;
}
#subPage .corporateTab .navBorder {
    height: 3px;
    background-color: #cecece;
    display: block;
    margin-top: -3px;
    margin-bottom: 0px;
}
.leaseDetailArac{
    padding: 10px 10px 40px 10px;
}
</style>
