<template>
<section id="subPage">
    <div class="container cont contBox">
        <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" href="#" style="text-decoration: none;pointer-events: none;">GENEL KİRALAMA KOŞULLARI</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#">Örnek</a></li> -->
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Genel Kiralama Koşulları</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
        <div class="row box">
            <div class="col-md-12">
                <div class='text-container'>
                   <p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Sürücü Belgesi ve Yaş Sınırlamaları</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">Araç kiralayabilmek için 21 yaşını doldurmuş olmanız en az 2 yıllık sürücü belgesine sahip olmanız gerekmektedir. Minimum yaş sınırı farklı araç sınıflarına göre değişkenlik gösterebilir.</span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Kiralama Süresi</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">Araç kiralama süresi en az 24 saattir. Her bir saatlik geç teslim için günlük kiralama bedelinin %30'u kadar bir tutar talep edilebilir. Araç tesliminde onay alınmaksızın yaşanan gecikmeler Sigortadan Yararlandırma Teminatının ( Tam veya Muafiyetli Kaza Güvencesi ) devre dışı kalmasına yol açabileceği gibi ilave saat farkı ücretinin de doğmasına sebep olabilecektir.</span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Kiralama yaşı</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">- Ekonomik grup araçlar için 21 yaş (Renault Clio, Opel Corsa, Peugeot 208 vb.)- Orta grup araçlar için 25 yaş (Renault Megane, Opel Astra, Toyota Corolla vb.)- Üst grup araçlar için 30 yaş ve üzerinde olmak (MB C Serisi, BMW 3 Serisi vb.)- Lüks grup araçlar için 30 yaş ve üzerinde olmak. (MB E Serisi, BMW 5 Serisi, Volco XC90 vb.)
Kiralama yapan müşterilerimizden kiralama esnasında kendi adlarına düzenlenmiş bir kredi kartına sahip olmaları istenmektedir. Bu kredi kartının internet kullanımına açık olması gerekmektedir. Bu kredi kartından kiraladıkları aracın grubuna göre değişmekle birlikte, ekonomik araçlar için 1500 TL, orta grup araçlar için 2500 TL ve üst grup araçlar için 4000 TL ve lüks grup araçlar için 10000 TL (çift kredi kartı) tutarında ön provizyon alınacaktır. Kiralamanızın bitiminden 10 iş günü sonra provizyon işleminin kaldırılması için ilgili bankaya bilgi geçilir.
Daha güvenli bir sürüş, kiralama ve tarafınızla akdedilen sözleşmenin ifası ve sözleşmeden doğan risklerin azaltılması amacıyla, son 6 ayda firmamızdan araç kiralamamış müşterilerimizin finansal analizleri findeks.com (KKB Kredi Kayıt Bürosu A.Ş ) sitesi üzerinden yapılması talep edilebilir. Müşteri tarafından rapor Popycar ile paylaşıldıktan sonra Popycar raporu kendisine ait bir hesaplama yöntemiyle ile tekrar değerlendirecek ve kiralama şartlarına uyup uymadığına bakacaktır. Şartlara uymayan araç kiralama talepleri için rezervasyon yapılmış olsa dahi araç teslimi yapılmayacak ve ödemesi yapıldı ise de müşteriye ödeme tutarı iade edilecektir.
</span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Ehliyet süresi</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">- Ekonomik grup araçlar için en az 2 yıllık- Orta grup araçlar için en az 3 yıllık- Üst ve lüks grup araçlar için en az 5 yıllık ehliyete sahip olmak
Kiralama esnasında yukarıdaki ön şartlar aranacağı için ehliyet ve kredi kartınızın yanınızda olması halinde aracı teslim alabilirsiniz. Türk vatandaşı olmayan müşterilerimizin pasaport ve Türkiye'ye giriş tarihinin 6 aydan daha kısa olduğunu gösterir bir belgeyi de ilave olarak yanlarında bulundurmaları gerekmektedir. 6 aydan daha uzun süredir Türkiye'de bulunan yabancı ehliyetli müşterilerimizin Türk Ehliyeti göstermeleri gerekmektedir.
</span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Ödeme Koşulları</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">
  ◦ Kira anlaşması kiralama esnasında açılır ve kiralama yapılan ülkenin kanun ve uygulamalarına göre düzenlenir. Gerçekleşecek kiralamalarda Popy Genel Kiralama Koşulları geçerlidir.
<br>◦ Kiralamanın sonunda oluşabilecek fatura tutarı farkı ise kiralama ofisi tarafından tahsil veya iade edilir.
<br>◦ Kredi kartı sahibinin kiralama esnasında şahsen bulunması gerekmektedir. Başkasına ait kredi kartı ile kiralama yapılamaz.
<br>◦ Yabancı ehliyete sahip kullanıcıların pasaportunu kiralama yapacağı ofise ibraz etme zorunluluğu bulunmaktadır aksi halde ön ödeme tutarı iade edilmeyecektir.
<br>◦ Olası para iadeleri bankanın işlem yoğunluğuna bağlı olarak 5-7 iş günü arasında kartınıza yansıyacaktır.
<br>◦ Ön Ödemeli Rezervasyonlarda araçların müşterilerimize teslim edilebilmesi için Genel Kiralama Koşullarının ve aşağıdaki şartların yerine getiriliyor olması gerekmektedir. Aşağıdaki şartların sağlanamaması durumunda araç tahsis edilemeyecek olup, iade gerçekleşmeyecektir.
<br>▪ Ödeme şekli sadece kredi kartı olup nakit ödeme ve debit kart uygulaması bulunmamaktadır.
<br>▪ Kiralama ve araç grubuna göre değişen provizyon tutarının kişiye ait kredi kartından sağlanıyor olması gerekmektedir. Kredi kartının üzerinde kiralama yapacak kişiye ait isim soy isim bilgisi bulunmalıdır.
<br><br>▪ Aracı teslim alacak kişinin Ehliyetini Popy ofisine ibraz etmesi gerekmektedir.
<br>- YakıtAracın teslim edilen yakıt seviyesinden daha düşük bir şekilde iade edilmesi sonucu eksik takıt bedeline ek olarak 200 TL “Yakıt Dolum Hizmet Bedeli” ayrıca kiralayandan talep edilecektir.
<br>- HGSParalı otoyol ve köprü geçişlerinde kullanılan HGS servisi için kullanım ücreti ve ilaveten 45 TL/kiralama servis bedeli kiralama ücretinden ayrı olarak tahsil edilir.
<br>- İlave KM PaketiKiralamalarınızda sınırlı kilometre uygulaması yapılmaktadır. Bir kiralamada günlük 500 km yapabilirsiniz. Kilometre aşımı yapmanız halinde 4TL/km olarak ücretlendirilecektir.
<br>- İlave SürücüEhliyetin gösterilmesi ve kiralama ön şartlarına uyması halinde (depozit ön şartı hariç) ilave olarak 3 sürücü ücret karşılığında eklenebilir.
<br>- Bebek ve Çocuk KoltuğuAraçlara bebek ve çocuk koltuğu sağlanabilmektedir. Rezervasyonunuz esnasında belirtmeniz halinde rezervasyon sorumlularımız size bilgi vereceklerdir.

</span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Kiralamanın Uzaması</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">Ön ödemeli rezervasyon ile yapılan bir kiralamada, kiralama süresinin rezervasyon formunda belirtilen dönüş tarihini aşması durumunda, kira anlaşması uzatıldığında oluşacak ilave bedel kiralama başlangıcında müşterimizin ibraz ettiği kredi kartından, uzama günlerinin yapıldığı tarihte geçerli Popy Liste fiyatlarından tahsil edilir.</span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Diğer Hizmetler</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">1 aydan daha uzun süreli araç kiralama talepleriniz için ofisimizle irtibat kurunuz.</span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Önemli Notlar</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">
  Herhangi bir kaza durumunda, aracın yeri değiştirilmeden kazanın şekline uygun kaza tespit tutanağı tutulması ya da en yakın polis veya jandarma karakoluna başvurularak kaza, hırsızlık ve alkol tespit raporları alınması ve 444 5 649 numaralı Tam Destek Hattına bilgi verilmesi zorunludur. Aracın çalınması durumunda da en yakın polis veya jandarma karakoluna başvurularak 444 5 649 numaralı Tam Destek Hattına bilgi verilmesi zorunludur. Aksi halde satın alınan tüm güvenceler geçersiz sayılarak meydana gelen her türlü zarar, 3’cü şahıslara verilen maddi ve manevi kayıpları da içermek üzere kiracıdan tahsil edilir. Aracın arızalanması veya herhangi bir nedenle desteğe ihtiyaç duyulması durumunda,444 5 649 telefondan yardım temin edilmesi gerekmektedir.
<br>- Lütfen kiralama başlangıcında ve iadesi sırasında Araç teslim etme ve teslim alma formunun doldurulup karşılıklı olarak imzalandığından emin olunuz.
<br>– Kiralama süresini uzatmak, dönüş saatini ve gününü bildirmek / değiştirmek için lütfen kiralamayı yaptığınız ofisi veya Rezervasyon merkezimizi arayınız.
<br>– Kiralama sırasında kaza, aracın arızalanması veya herhangi bir nedenle desteğe ihtiyaç duyulması durumunda 444 5 649 numaralı Tam Destek Hattı aranmalıdır.
<br>– Kullanımınız sırasında aracın periyodik bakım kilometresi gelmesi durumunda, güvenli bir şekilde yolculuğa devam edebilmeniz için lütfen Tam Destek Hattımızı arayınız.
<br>– Aracın TEK TARAFLI olarak kazaya uğraması veya camının kırılarak hırsızlığa maruz kalması veya çalınması durumunda, (aracın) yeri değiştirilmeden en yakın polis veya jandarma karakoluna başvurarak kaza, hırsızlık ve alkol tespiti raporları alınmalıdır. ÇİFT TARAFLI kazalarda ise, aşağıdaki maddelerde bildirilen haller dışında, kazanın oluş şekline göre sürücüler, Maddi Hasarlı Kaza Tespit Tutanağını tutanak üzerinde belirtilen şekilde doldurmakla yükümlüdürler. Çift taraflı kazalarda, sadece aşağıdaki hallerde trafik kazası tespit tutanağı, trafik polisi tarafından düzenlenmektedir.
<br>– Sürücü belgesiz motorlu araç kullanılıyorsa,
<br>– Sürücüde yaş küçüklüğü varsa,
<br>– Sürücüde alkol veya akıl sağlığı şüphesi varsa,
<br>– Kazaya karışan araçlardan birinin kamu kurumlarına ait olması durumunda,
<br>– Kamu kurumlarına ait eşyada zarar meydana gelirse,
<br>– Trafik Kazasında sadece 3.kişilere ait eşyalara zarar gelirse,
<br>– Kazaya karışan araçlardan birinin trafik güvencesinin bulunmaması durumunda,
<br>– Trafik kazası ölüm ve / veya bedeni zarar ile sonuçlanmışsa.

</span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Yurtdışı Çıkış</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">Kiralanan araçların yurtdışına çıkarılması yasaktır.</span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong>Yabancı Ehliyet İle Kiralama</strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;">
  <br>a) KİRACI Türk vatandaşları dış ülkelerden aldıkları sürücü belgeleri ile ülkemizde araç kullanmalarının yalnızca aşağıdaki şart ve koşullarda mümkün olduğunu bilir ve kabul eder:
  <br>1) Dış ülkelerden alınan sürücü belgeleri ile ülkemize giriş yapıldığı tarihten itibaren en fazla 1 yıl süreyle araç kullanılabilir.
  <br>2) Altı aydan fazla ülkemizde bulunanların, araç kullanırken sürücü belgelerinin dış temsilcilikler veya noter tarafından onaylanmış Türkçe
tercümelerini sürücü belgesi ile birlikte yanlarında bulundurmaları zorunludur.
<br><br>3) Bir yılın sonunda ülkemizde araç kullanılabilmesi için dış ülkelerden alınan sürücü belgelerinin ülkemiz sürücü belgesi ile değiştirilmesi
zorunludur.
<br>b) Yabancı ülke vatandaşı KİRACILAR ise dış ülkelerden aldıkları sürücü belgeleri ile ülkemizde araç kullanmalarının aşağıdaki şart ve koşullarda mümkün olduğunu bilir ve kabul eder:
1) Dış ülkelerden alınan sürücü belgeleri ile ülkemize giriş yapıldığı tarihten itibaren en fazla altı ay süreyle araç kullanılabilir.
2) Altı ayın sonunda ülkemizde araç kullanılabilmesi için dış ülkelerden alınan sürücü belgelerinin ülkemiz sürücü belgesi ile değiştirilmesi
zorunludur.
◦ İlgili kanunlar ile ikili ve çok taraflı anlaşma hükümleri saklı kalmak üzere dış ülkelerden alınan sürücü belgelerinin, ülkemiz sürücü belgeleri ile değiştirilmesine ilişkin usul ve esaslar aşağıda belirtilmiştir:
<br>a) Müracaat herhangi bir trafik tescil kuruluşuna yapılabilir.
<br>b) Müracaat sırasında ibraz edilmesi zorunlu olan bilgi ve belgeler Emniyet Genel Müdürlüğünce belirlenir.
<br>c) Sürücü belgesi alacaklarda, 2918 sayılı Kanunun 41 inci maddesinde belirtilen eğitim ve sınav şartı hariç, aynı maddedeki hükümlü olmama, yaş, öğrenim ve sağlık şartları aynen aranır. Ancak ikili veya çok taraflı anlaşma hükümleri ile uygulamalar saklı kalmak üzere diplomatik muafiyeti olan yabancılar için, gerekli şartları taşıdıklarına dair yazılı beyanları esas alınır.
<br>ç) Gerektiğinde Dışişleri Bakanlığı veya dış temsilciliklerimiz aracılığıyla sürücü belgesinin doğruluğu hakkında inceleme ve araştırma yapılabilir.
<br>d) Geçici olarak alınmış olan yabancı sürücü belgeleri değiştirilmez.
<br>e) Karayolu Trafiği Konvansiyonuna taraf olmayan ve sürücü belgelerinin karşılıklı olarak tanınması ve değişimine ilişkin ikili bir anlaşmamız bulunmayan ülkelerden alınan sürücü belgeleri ülkemiz sürücü belgesi ile değiştirilmez.
<br>f) Türk vatandaşlarının Karayolu Trafiği Konvansiyonuna taraf olan ülkelerden aldıkları sürücü belgeleri, karşılığı veya dengi olan sürücü belgeleri ile değiştirilir.
<br>g) Yabancıların Karayolu Trafiği Konvansiyonuna taraf olan ülkelerden aldıkları motorlu bisiklet (moped), motosiklet, lastik tekerlekli traktör, iş makinesi veya otomobil cinsi araçları kullanmak üzere verilen sürücü belgeleri karşılığı olan ülkemiz sürücü belgeleriyle, diğer araç cinslerini kullanmak üzere verilen sürücü belgeleri ise ülkemiz B sınıfı sürücü belgesi ile değiştirilir, ancak bu araçları römork takarak kullanma yetkisi olanların belgeleri ülkemiz BE sınıfı sürücü belgesi ile değiştirilir. Bunların dışındaki sınıf sürücü belgelerinin talep edilmesi halinde eğitim ve sınav şartı da aranır.
<br>ğ) Dış ülkelerden sürücü belgesi almış kişilerin ülkemizde farklı bir sınıf sürücü belgesi alabilmeleri için dış ülkelerden almış oldukları sürücü belgesini ülkemiz sürücü belgesine dönüştürmüş olmaları zorunludur.
<br>h) Ülkemiz sürücü belgeleri ile değiştirilen dış ülkelerden alınmış sürücü belgeleri ilgili ülkeye gönderilir.
<br>ı) Dış ülkelerden almış oldukları sürücü belgeleri ile bu maddede belirtilen şartları sağlamadan araç kullananlar hakkında 2918 sayılı Kanunun 39 uncu maddesine istinaden işlem yapılır.

</span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong></strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;"></span></p>

<p style="background: white;"><span style="font-size: 18px; color: #333333;"><strong></strong></span></p>
<p style="background: white;"><span style="font-size: 18px; color: #333333;"></span></p>

                  </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
export default {
  name: "generalagreement",
  metaInfo: () => ({
    title: 'Genel Kiralama Koşulları',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Genel Kiralama Koşulları' }
    ]
  }),
  data: () =>{
    return {
    }
  }
}
</script>

<style scoped>
.btn-link {
    color: #212529 !important;
    font-weight: 600;
}
</style>
