<template>
  <div>
  <section id="subPageBannerZeroTop">
      <div class="row">
        <div class="col">
          <div id="centralFade" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active"></div>
            </div>
          </div>
        </div>
      </div>
  </section>
  <section id="subPage" class="mb-0">
    <div class="container cont">
      <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" v-bind:href="`${this.$route.path}`">{{this.$route.name}}</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">{{this.$route.name}}</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="row corporateTab">
        <div class="col-md-12">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="tab_1" data-toggle="pill" href="#tab_id_1" role="tab" aria-controls="tab_id_1" aria-selected="true">Bayimiz Olun</a></li>
            <li class="nav-item"><router-link class="nav-link" to="/hakkimizda">Hakkımızda</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/iletisim">İletişim</router-link></li>
          </ul>
          <div class="navBorder"></div>
        </div>
      </div>
    </div>
  </section>
  <section id="subPageCorporate">
    <div class="container">
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="tab_id_1" role="tabpanel" aria-labelledby="tab_1">
          <div class="row justify-content-md-center">
            <div class="col col-lg-9 text-justify">
              <h1>PopyCar İş Ortağı Olun, Hayatınızı Kolaylaştırın</h1>
              <img alt="user" class="media-box-object mr-2"  src="../../static/bayi.jpeg" ><br><br>

              <md-button class="md-raised md-accent bayilikButton" @click="BayilikFormu()">Bayilik Başvuru Formu İçin Tıklayın.</md-button>
              <p style="margin-top:30px;font-weight:bold;">Detaylı bilgi için: 444 5 649</p>
            </div>
            <div class="col col-lg-9 text-justify" v-if="BayilikFormuAc" style="margin-top:20px;">
                  <div class="alert alert-success" role="alert" v-if="hataMesajiDurum==1 && hataMesaji!=''">
                    <strong>{{hataMesajiBaslik}}</strong> {{hataMesaji}}
                  </div>
                  <div class="alert alert-danger" role="alert" v-if="hataMesajiDurum==0 && hataMesaji!=''">
                    <strong>{{hataMesajiBaslik}}</strong> {{hataMesaji}}
                  </div>
              <form @submit.prevent="handleSubmit" v-if="hataMesajiDurum==0">
                      <div class="row subPage contBox" ref="formContainer">
                          <div class="col">
                              <div class="form-row">
                                  <div class="form-group col-md-6">
                                      <label for="firmaAdi">FİRMA ADI*</label>
                                      <input type="text" v-model="dealer.firmaAdi" class="form-control" id="firmaAdi" name="firmaAdi" :class="{ 'is-invalid': submitted && $v.dealer.firmaAdi.$error }">
                                      <div v-if="submitted && !$v.dealer.firmaAdi.required" class="invalid-feedback">Firma adınızı giriniz</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                      <label for="firmaTelefonu">FİRMA TELEFONU*</label>
                                      <input type="tel" v-model="dealer.firmaTelefonu" class="form-control" id="firmaTelefonu" name="firmaTelefonu" :class="{ 'is-invalid': submitted && $v.dealer.firmaTelefonu.$error }">
                                      <div v-if="submitted && !$v.dealer.firmaTelefonu.required" class="invalid-feedback">Firam telefon numaranızı giriniz</div>
                                  </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-md-6">
                                      <label for="sorumluAdi">FİRMA SORUMLU ADI*</label>
                                      <input type="text" v-model="dealer.sorumluAdi" class="form-control" id="sorumluAdi" name="sorumluAdi" :class="{ 'is-invalid': submitted && $v.dealer.sorumluAdi.$error }" >
                                      <div v-if="submitted && !$v.dealer.sorumluAdi.required" class="invalid-feedback">Firma sorumlu adı giriniz</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                      <label for="sorumluSoyadi">FİRMA SORUMLU SOYADI*</label>
                                      <input type="text" v-model="dealer.sorumluSoyadi" class="form-control" id="sorumluSoyadi" name="sorumluSoyadi" :class="{ 'is-invalid': submitted && $v.dealer.sorumluSoyadi.$error }" >
                                      <div v-if="submitted && !$v.dealer.sorumluSoyadi.required" class="invalid-feedback">Firma sorumlu soyadınızı giriniz</div>
                                  </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-md-6">
                                      <label for="firmaEpostasi">FİRMA E-POSTA ADRESİ*</label>
                                      <input type="email" v-model="dealer.firmaEpostasi" class="form-control" id="firmaEpostasi" name="firmaEpostasi" :class="{ 'is-invalid': submitted && $v.dealer.firmaEpostasi.$error }">
                                      <div v-if="submitted && $v.dealer.firmaEpostasi.$error" class="invalid-feedback">
                                          <span v-if="!$v.dealer.firmaEpostasi.required">Firma e-posta adresinizi giriniz</span>
                                          <span v-if="!$v.dealer.firmaEpostasi.email">Geçerli bir e-posta adresi giriniz</span>
                                      </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                      <label for="sorumluTelefonu">FİRMA SORUMLUSU TELEFONU*</label>
                                      <input type="tel" v-model="dealer.sorumluTelefonu" class="form-control" id="sorumluTelefonu" name="sorumluTelefonu" :class="{ 'is-invalid': submitted && $v.dealer.sorumluTelefonu.$error }">
                                      <div v-if="submitted && !$v.dealer.sorumluTelefonu.required" class="invalid-feedback">Firma sorumlu telefon numaranısını giriniz</div>
                                  </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-md-12">
                                      <label for="firmaAdresi">FİRMA ADRESİ*</label>
                                      <textarea class="form-control" v-model="dealer.firmaAdresi" id="firmaAdresi" name="firmaAdresi" :class="{ 'is-invalid': submitted && $v.dealer.firmaAdresi.$error }"></textarea>
                                      <div v-if="submitted && !$v.dealer.firmaAdresi.required" class="invalid-feedback">Firma adresiniz yazınız</div>
                                  </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-md-12">
                                      <label for="firmaBolge">İŞ YAPMAYI PLANLADIĞINIZ BÖLGE*</label>
                                      <textarea class="form-control" v-model="dealer.firmaBolge" id="firmaBolge" name="firmaBolge" :class="{ 'is-invalid': submitted && $v.dealer.firmaBolge.$error }"></textarea>
                                      <div v-if="submitted && !$v.dealer.firmaBolge.required" class="invalid-feedback">İş yapmayı planladığınız bölgeyi yazınız</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12" style="margin-bottom: -15px !important;">
                          <md-checkbox v-model="PersonelData" value="1"></md-checkbox>
                          <span @click="PersonelDataDialog=true" style="cursor: pointer;">Kişisel Verilerin Korunması Metnini Okudum ve kabul ediyorum.</span>
                        </div>
                      </div>
                      <div class="row">
                            <div class="col">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <button type="submit" class="btnRed float-right" style="padding-top:4px!important;">Gönder <i class="fas fa-long-arrow-alt-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </form>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tab_id_2" role="tabpanel" aria-labelledby="tab_2">
          <div class="row justify-content-md-center">
            <div class="col col-lg-9 text-justify">
              <h1></h1>
              <p></p>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tab_id_3" role="tabpanel" aria-labelledby="tab_3">
          <div class="row justify-content-md-center">
            <div class="col col-lg-9 text-justify">
              <h1></h1>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <md-dialog-alert
:md-active.sync="PersonelDataDialog"
md-title="Kişisel Verilerin Korunması"
md-content='<div class="col-md-12">
                 <p><span style="font-size: 22px; font-family: arial, sans-serif; color: #222222;">KVKK AYDINLATMA FORMU</span></p>
<p><span style="font-size: 14px;">
</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">POPYCAR ARAÇ KİRALAMA ANONİM ŞİRKETİ (“Şirket”)&nbsp; olarak güvenliğinize önem veriyoruz. Bu doğrultuda başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verilerin korunması bamacıyla düzenlenen "Kişisel Verilerin Korunması Kanunu" hakkında sizleri bilgilendirmek isteriz.</span></p>
<span style="font-size: 14px;">
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">KİŞİSEL VERİLERİN KORUNMASI HAKKINDA BİLGİLENDİRME</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca, gerçek kişilerin kimliğini belirli ya da belirlenebilir hale getirmeye yarayan her türlü bilgi kişisel veri olarak tanımlanmaktadır. Şirketimiz nezdindeki kişisel verileriniz aşağıda açıklanan sebep, amaç ve yöntemlerle, ilgili yasal düzenlemeler ve yasal otoritelerce öngörülen bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uyum gereği, Veri Sorumlusu sıfatıyla Şirketimizce, sistemlerimize kaydedilecek, depolanacak, muhafaza edilecek, saklanacak, yasal ya da finansal gerekler, nedenler ile sınıflandırılacak, güncellenecek ve mevzuatın izin verdiği durumlarda ve yasal sınırlar dahilinde 3. kişilere açıklanabilecek/devredilebilecek, sınıflandırılabilecek ve KVKK’da sayılan şekillerde işlenebilecektir. Aşağıda yer alan yasal hususlar hakkında bilgi edinilmesini rica ederiz.</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Ayrıca, kullanıcı olarak internet sitemizde, iş başvurusu yapmak, ürün ve hizmetlerimizden yararlanmak, dilek ve önerilerde bulunmak, kendinizi daha iyi tanıtmak ve kullanıcı tercihlerinizi daha yakından takip edebilmemiz amacıyla, internet sitemize üye olurlarken; ad, soyad, e-posta, vb. dahil ve bunlarla sınırlı olmaksızın, ürün ve hizmet talebi, teklif alımı veya iş başvuruları için paylaşacağınız veya paylaştığınız tüm kişisel verileri, çerezler (<em>cookies</em>) aracılığı ile paylaştığınız kişisel verileriniz de dahil, internet sitesi ile KVKK md. 5/f.1 ve md. 6/f.2 uyarınca tamamen kendi isteğiniz doğrultusunda&nbsp; paylaştığınızı, bu verilerin kendinizi tanıtmak ve size daha iyi hizmet sunulabilmesi, yaptığınız veya yapacağınız başvuru veya şikayetler ve site etkinlikleri ve yeniliklerinden haberdar edilebilmeniz amacıyla bizzat talep ettiğinizi kabul etmektesiniz. Şirketimize yaptığınız iş başvurusunun değerlendirilmesi neticesinde işe alım gerçekleşmediği takdirde dahi, Şirketimiz sağlamış olduğunuz kişisel verilerinizi saklamak ve/veya Grup Şirketlerimiz, iştiraklerimiz ve/veya bağlı ortaklıklarımız ile paylaşmak suretiyle işleyebilecektir. Bunun yanı sıra internet sitemizi ziyaret ettiğinizde, yer sağlayıcı olarak, 5651 sayılı Kanun md. 2/f.1,j bendi uyarınca trafik bilgilerinizin işleneceğini dikkatinize sunmak isteriz.&nbsp;</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Kişisel verilerin işlenme amaçları ve hukuki sebepleri:</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Kişisel verileriniz;</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Şirketimiz tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi ve Şirketimiz tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için ilgili iş birimleri tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;İnsan kaynakları süreçlerimiz, aday belirleme, görüşme ve değerlendirmelerinin yapılması,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Şirketimiz tarafından sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek size önerilmesi ve tanıtılması,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Size özel satış ve pazarlama faaliyetlerinin planlanması ve gerçekleştirilmesi,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Müşteri hizmetlerinin ve ilişkilerinin yürütülmesi ve icrası,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Şirketimiz ve/veya grup şirketlerimiz tarafından sunulan ürün ve hizmetler ile Şirketimizin, Grup Şirketlerimizin ve Şirketimizle ve/veya grup şirketlerimizle iş ilişkisi içerisinde olan üçüncü kişilerin hukuki ve ticari güvenliğinin temini, hukuksal süreçlerin takibi ve mevzuattan doğan hakların tesisi, kullanılması ve korunması,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;İş faaliyetlerinin ve operasyonel süreçlerin planlanması ve icrası,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Şirket faaliyetlerinin Şirket prosedürleri veya ilgili mevzuata uygun olarak yürütülmesinin temini,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anlaşmalı olduğumuz “kredi sağlayıcı şirketler” tarafından sunulan kredi imkanlarına başvurunuzun olması halinde “kredi sağlayıcı şirketlerce” yapılacak kredi değerlendirme işlemlerinin bu kuruluşlar nezdinde takibi,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anlaşmalı olduğumuz kredi sağlayıcı şirketlere sağlayabileceğimiz pazarlama hizmetleri çerçevesinde kredi ve finansman işlemlerinizin bu kuruluşlar nezdinde takibi,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Şirketimizden aldığınız hizmetlerle ilgili olarak finansal süreçlerin risk yönetiminin gerçekleştirilmesi,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Şirket’in finansal risk süreçlerinin planlanması veya icrası,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Satış sonrası destek hizmetleri ile yükümlülüklerinin, müşteri memnuniyetinin, kurumsal iletişim faaliyetlerinin, müşteri ilişkileri ile müşteri talep ve şikayetlerinin yönetimi süreçlerinin planlanması ve icrası,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;İş sürekliliğinin sağlanması faaliyetlerinin planlanması veya icrası,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Satış, kiralama ve sair sözleşme süreçlerinin veya hukuki taleplerin takibi,</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Finans veya muhasebe işlerinin takibi;</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kurumsal sürdürülebilirlik, kurumsal yönetim, stratejik planlama ve bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası; iş ortakları, operasyonel kiralama ve filo kiralama faaliyeti çerçevesinde iş ortağımız olan otomotiv şirketleri, telekomünikasyon şirketleri, elektronik haberleşme sektöründeki şirketler ve iş ortağımız olan diğer şirketler, bayileri veya tedarikçilerle yürütülen işlerin icrası ve ilişkilerin yönetimi;</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">gibi amaçlarla (“Amaçlar”), 6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde kişisel veriler işlenebilecektir.&nbsp;</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Yukarıda belirtilen Amaçlarla, kişisel verilerin aktarılabileceği kişi / kuruluşlar:</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Türk Ticaret Kanunu, Vergi Usul Kanunu, MASAK mevzuatı gibi yasal düzenlemelerde yer alan müşterilere ilişkin kimlik bilgileri, adres gibi erişim/iletişim bilgileri, kullanılan finansal ürünlere ve tüketici alışkanlığına ilişkin bilgileri (krediler, rating, skoring, ödemeler vb.), finansal ürünlere erişimde kullandığı yöntemlere ilişkin bilgiler (IP, mobil-tel markası-modeli, browser tipi, versiyonu, ekranlar üzerindeki hareketleri, sosyal medya bilgisi vb.), sesli ve görüntülü görüşmelerde veya Şirketimizin siz müşterimiz ile imzaladığı sözleşmeler kapsamında gerçekleşen işlemler sonucu edindiği müşterinin kimliğini belirli veya belirlenebilir hale getirmeye yarayan her türlü bilgi ve belge (kimlik fotokopisinde yer alan kan grubu ve/veya özgeçmişte yer alan dernek üyeliği de&nbsp;<em>özel nitelikli kişisel veri</em>&nbsp;olarak) kişisel veri kapsamındadır.&nbsp;</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Kişisel verileriniz, Şirketimiz tarafından şirketlerinin kuruluş, işleyiş ve faaliyetlerine dair her türlü mevzuat hükümlerinin ve diğer mevzuat hükümlerinin izin verdiği kişi veya kuruluşlar; bunlarla sınırlı olmamak üzere 5411 sayılı Bankacılık Kanunu madde 73/4’te sayılan finansal kuruluşlar ve diğer 3. kişilere; BTK, BDDK, Sermaye Piyasası Kurumu (“SPK”), TCMB, Hazine Müsteşarlığı, Bakanlıklar gibi kamu tüzel kişileri ve yasal otoritelerle; müşteri bilgilerini istemeye kanunen yetkili resmi mercilere; KKB ve Risk Merkezi’ne, Şirketimiz ana hissedarı, doğrudan/dolaylı yurtiçi/yurtdışı iştiraklerine; Şirketimizin ticari faaliyetleri tahtında operasyonel kiralama faaliyetlerini yürütmek üzere hizmet aldığı, işbirliği yaptığı, program ortağı, ticari iş ortağı kuruluşları, bağlı krediye konu mal ve/veya hizmeti sunan satıcı/sağlayıcıları,&nbsp;</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">operasyonel kiralama ve filo kiralama faaliyeti çerçevesinde iş ortağımız olan otomotiv şirketleri, bayileri veya tedarikçileri, yurtiçi/yurtdışı bankalar, hukuki, finansal, vergisel ve operasyonel danışmanlar, servis sağlayıcılar ve sigorta tedarikçileri de dahil tedarikçi ve/veya taşeron firmaları, telekomünikasyon alanındaki iş ortaklarımız, arşiv hizmeti sağlayıcılarımız, denetçilerimiz, kurye ve sair taşıma şirketleri, müşavirleri, ve diğer üçüncü kişilerle paylaşılabilecek ve bu doğrultuda işlenebilecektir.</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Kişisel verilerin toplanma yöntemi:</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Kişisel verileriniz, Şirketimizce, sözlü, yazılı veya elektronik ortamda, genel müdürlük, çağrı merkezi, kurumsal web sitemiz, işbirliği ortaklarımız, online işlemler, satıcı ve/veya bayi ağı ve diğer dağıtım kanalları gibi kanallar aracılığıyla doğrudan siz müşterimizden veya KKB, Risk Merkezi gibi kurumlar, sair resmi kurumlar yahut doğrudan dolaylı bağlı ortaklıklarımız veyahut hakim hissedarımız veya hizmet aldığımız ya da verdiğimiz gerçek ya da tüzel üçüncü kişiler aracılığıyla toplanabilir.</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">KVKK’nın 11. maddesi gereği haklarınız:</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Şirketimize başvurarak, kişisel verilerinizin; a) işlenip işlenmediğini öğrenme, b) işlenmişse bilgi talep etme, c) işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme, ç) yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme, d) eksik / yanlış işlenmişse düzeltilmesini isteme, e) KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme, f) aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme, g) münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme, ğ) kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme hakkına sahipsiniz. Bu kapsamdaki haklarınız 07.10.2016 tarihi itibarıyla yürürlüğe girmiştir.</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">KVKK’nun 11. Maddesi uyarınca; kişisel verilerinize ilişkin olarak, Şirketimizin bu konularla ilgili e-posta adresi olan&nbsp;</span><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;"><a href="mailto:kvkk@popycar.com.tr">kvkk@popycar.com.tr</a>&nbsp;e-posta adresine veya &nbsp;Fatih Sultan Mehmet Mah. Poligon Cd. BuYaka Kuleler Kule:3 Kat:6 Daire No 37-42 Ümraniye/İstanbul adresine yazılı olarak başvuruda bulunarak yukarıda belirtilen haklarınızı kullanabilirsiniz.</span></p>
<p style="font-weight: normal; margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 14px; font-family: arial, sans-serif; color: #222222;">Finansal kuruluşların müşterileri ile gerçekleştirdiği işlemlere ilişkin kayıt ve belgeleri yasal düzenlemeler kapsamında belirli bir süre ile saklama yükümlülüğü olduğundan; kişisel verilerinizin silinmesini veya yok edilmesini ya da anonimleştirilmesini istemeniz halinde bu talebiniz Şirketimiz tarafından yasal düzenlemeler ile belirlenen süre sonunda yerine getirilebilecektir. Ancak bu süre zarfında kişisel verileriniz Şirketimiz tarafından hukuken zorunlu olan durumlar dışında işlenmeyecek ve ulusal ve uluslararası yasal düzenlemeler ile sözleşmelerden kaynaklı zorunluluklar haricinde üçüncü kişiler ile paylaşılmayacaktır.</span></p>
<p style="margin: 19.5pt 0cm 0.0001pt; line-height: 21.6pt; background: white; text-align: justify;"><span style="font-size: 22px; font-family: arial, sans-serif; color: #222222;">POPYCAR ARAÇ KİRALAMA ANONİM ŞİRKETİ</span></p>

                </div>'
md-confirm-text="Devam" />
    </div>
  </section>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'
    export default {
        name: "beourdealer",
        metaInfo: () => ({
    title: 'Bayimiz Olun',
    titleTemplate: '%s - PopyCar',
    htmlAttrs: {
      lang: 'tr',
      amp: true
    },
    meta: [
      { name: 'description', content: 'Bayimiz olmak ister misiniz? Siz de PopyCar ile iş ortağı olun, hayatınızı kolaylaştırın. Detaylı bilgi için tıklayın!' }
    ]
  }),
        data: () => {
          return {
            BayilikFormuAc: false,
            hataMesajiDurum: '',
            hataMesaji: false,
            hataMesajiBaslik: false,
            PersonelDataDialog: false,
            PersonelData: false,
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            color: '#d2202f',
            dealer: {
                firmaAdi: '',
                firmaTelefonu: '',
                firmaEpostasi: '',
                firmaAdresi: '',
                sorumluAdi: '',
                sorumluSoyadi: '',
                sorumluTelefonu: '',
                firmaBolge: ''
            },
              submitted: false,
          }
        },
        validations: {
          dealer: {
                firmaAdi: { required },
                firmaTelefonu: { required },
                firmaEpostasi: { required, email},
                firmaAdresi: { required },
                sorumluAdi: { required },
                sorumluSoyadi: { required },
                sorumluTelefonu: { required },
                firmaBolge: { required }
            }
        },
        methods: {
          BayilikFormu(){
            this.BayilikFormuAc=true
          },
          handleSubmit (e) {
              this.submitted = true
              this.$v.$touch()
              if (this.$v.$invalid) {
                return
              }
              let loader = this.$loading.show({
                container: this.$refs.formContainer,
                loader: this.loader,
                color: this.color,
                opacity: 0.90,
              });
              var params = JSON.stringify(this.dealer);
              axios.post(process.env.VUE_APP_API_URL+'bayilikFormu.php', params, {withCredentials: true, headers: {
                'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
                }}).then(response => {
                loader.hide()
                this.hataMesajiDurum=response.data.basarili
                this.hataMesaji=response.data.mesaj
                this.hataMesajiBaslik=response.data.baslik
              })
          }
        }
    }
</script>

<style scoped>
.bayilikButton{
  margin: 0!important;
}
.btnRed{
    background-color: #d2202f;
    color: #fff;
    text-decoration: none !important;
    margin: 15px 0px 0px;
    padding: 10px 18px 0px 18px !important;
    height: 40px;
    border: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}

</style>
