import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import './assets/css/bootstrap.min.css'
import './assets/css/custom.css'
import './assets/css/custom_mobile.css'
import './assets/css/searchBox.css'
import './vue-loading/vue-loading.css'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)

import { Datetime } from 'vue-datetime'
Vue.use(Datetime)

import Loading from 'vue-loading-overlay'
Vue.use(Loading)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueMaterial from 'vue-material'
Vue.use(VueMaterial)

import VueCarousel from 'vue-carousel'
Vue.use(VueCarousel)

import VueMask from 'v-mask'
Vue.use(VueMask);

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCUI7HRHmvREtGidDA9_kulgwGgRwQ7wlc',
  },
  installComponents: true
})


// import VueGtm from '@gtm-support/vue2-gtm';
// Vue.use(VueGtm, {
//   id: 'GTM-NL5M6TDXXX',
//   queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
//     gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
//     gtm_preview:'env-4',
//     gtm_cookies_win:'x'
//   },
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   debug: false, // Whether or not display console logs debugs (optional)
//   loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//   ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
// });

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.config.productionTip = false

Vue.directive("uppercase", {
  update: function (el) {

      var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
      el.value = el.value.replace(/(([iışğüçö]))/g, function(letter){ return letters[letter]; }).toUpperCase()
  }
})
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
  render: h => h(App)

})


