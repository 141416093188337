<template>
  <div>
  <section id="subPageBannerZeroTop">
      <div class="row">
        <div class="col">
          <div id="centralFade" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active"></div>
            </div>
          </div>
        </div>
      </div>
  </section>
  <section id="subPage" class="mb-0">
    <div class="container cont">
      <div class="row ">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" v-bind:href="`${this.$route.path}`">{{this.$route.name}}</a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active"><router-link to="/" class="nav-link">Ana Sayfa <span class="sr-only">(current)</span></router-link></li>
                <li class="nav-item"><a class="nav-link" href="#">Kurumsal</a></li>
                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Hakkımızda</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div class="row corporateTab">
        <div class="col-md-12">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="tab_1" data-toggle="pill" href="#tab_id_1" role="tab" aria-controls="tab_id_1" aria-selected="true">{{this.$route.name}}</a></li>
            <li class="nav-item"><a class="nav-link" id="tab_2" data-toggle="pill" href="#tab_id_2" role="tab" aria-controls="tab_id_2" aria-selected="true">Kurumsal Talep Formu</a></li>
          </ul>
          <div class="navBorder"></div>
        </div>
      </div>
    </div>
  </section>
  <section id="subPageCorporate">
    <div class="container">
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="tab_id_1" role="tabpanel" aria-labelledby="tab_1">
          <div class="row justify-content-md-center">
            <div class="col col-lg-9 text-justify">
              <p><strong>Kurumsal araç kiralamada kaliteli hizmet ve hızlı çözüm arıyorsanız, ihtiyacınızı karşılamak için buradayız.</strong></p>
              <p>Kurumsal müşterilerimizin günlük araç kiralama taleplerini; çağrı merkezi ve e-posta yolu ile yapılan taleplerle karşılanmaktayız. Araçların teslimatı Türkiye çapında standart hizmet sunan şubelerimizden gerçekleşmektedir.</p>
              <p>Kurumsal müşterilerimize sunduğumuz özel cazip indirim oranları ve esnek çalışma koşulları, müşterilerimiz için ayrıcalıklı avantajlar yaratmaktadır.</p>
              <p>Kurumsal kiralama hizmetimiz hakkındaki sorularınız ve talepleriniz için  kurumsalkiralama@popycar.com adresinden bize mail atabilir veya 444 5 649 çağrı merkezimizden bizlere ulaşabilirsiniz.</p>

              <p style="margin-top:30px;font-weight:bold;">Kurumsal Kiralama Talepleriniz İçin: kurumsalkiralama@popycar.com</p>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tab_id_2" role="tabpanel" aria-labelledby="tab_2">
          <div class="row justify-content-md-center">
            <div class="col col-lg-9 text-justify">
                  <div class="alert alert-success" role="alert" v-if="hataMesajiDurum==1 && hataMesaji!=''">
                    <strong>{{hataMesajiBaslik}}</strong> {{hataMesaji}}
                  </div>
                  <div class="alert alert-danger" role="alert" v-if="hataMesajiDurum==0 && hataMesaji!=''">
                    <strong>{{hataMesajiBaslik}}</strong> {{hataMesaji}}
                  </div>
                  <form @submit.prevent="handleSubmit" v-if="hataMesajiDurum==0">
                      <div class="row subPage contBox" ref="formContainer">
                          <div class="col">
                              <div class="form-row">
                                  <div class="form-group col-md-6">
                                      <label for="firmaAdi">FİRMA ADI*</label>
                                      <input type="text" v-model="dealer.firmaAdi" class="form-control" id="firmaAdi" name="firmaAdi" :class="{ 'is-invalid': submitted && $v.dealer.firmaAdi.$error }">
                                      <div v-if="submitted && !$v.dealer.firmaAdi.required" class="invalid-feedback">Firma adınızı giriniz</div>
                                  </div>
                                  <div class="form-group col-md-3">
                                      <label for="firmaVergi">FİRMA VERGİ NO*</label>
                                      <input type="tel" v-mask="'##########'" v-model="dealer.firmaVergi" class="form-control" id="firmaVergi" name="firmaVergi" :class="{ 'is-invalid': submitted && $v.dealer.firmaVergi.$error }">
                                      <div v-if="submitted && !$v.dealer.firmaVergi.required" class="invalid-feedback">Firam vergi numaranızı giriniz</div>
                                  </div>
                                  <div class="form-group col-md-3">
                                      <label for="firmaVergiDairesi">FİRMA VERGİ DAİRESİ*</label>
                                      <input type="tel" v-model="dealer.firmaVergiDairesi" class="form-control" id="firmaVergiDairesi" name="firmaVergiDairesi" :class="{ 'is-invalid': submitted && $v.dealer.firmaVergiDairesi.$error }">
                                      <div v-if="submitted && !$v.dealer.firmaVergiDairesi.required" class="invalid-feedback">Firam vergi dairesi giriniz</div>
                                  </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-md-6">
                                      <label for="sorumluAdi">YETKİLİ ADI*</label>
                                      <input type="text" v-model="dealer.sorumluAdi" class="form-control" id="sorumluAdi" name="sorumluAdi" :class="{ 'is-invalid': submitted && $v.dealer.sorumluAdi.$error }" >
                                      <div v-if="submitted && !$v.dealer.sorumluAdi.required" class="invalid-feedback">Firma yetkili adı giriniz</div>
                                  </div>
                                  <div class="form-group col-md-6">
                                      <label for="sorumluSoyadi">YETKİLİ SOYADI*</label>
                                      <input type="text" v-model="dealer.sorumluSoyadi" class="form-control" id="sorumluSoyadi" name="sorumluSoyadi" :class="{ 'is-invalid': submitted && $v.dealer.sorumluSoyadi.$error }" >
                                      <div v-if="submitted && !$v.dealer.sorumluSoyadi.required" class="invalid-feedback">Firma yetkili soyadınızı giriniz</div>
                                  </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-md-6">
                                      <label for="sorumluEpostasi">YETKİLİ E-POSTA ADRESİ*</label>
                                      <input type="email" v-model="dealer.sorumluEpostasi" class="form-control" id="sorumluEpostasi" name="sorumluEpostasi" :class="{ 'is-invalid': submitted && $v.dealer.sorumluEpostasi.$error }">
                                      <div v-if="submitted && $v.dealer.sorumluEpostasi.$error" class="invalid-feedback">
                                          <span v-if="!$v.dealer.sorumluEpostasi.required">Firma yetkili e-posta adresinizi giriniz</span>
                                          <span v-if="!$v.dealer.sorumluEpostasi.email">Geçerli bir e-posta adresi giriniz</span>
                                      </div>
                                  </div>
                                  <div class="form-group col-md-6">
                                      <label for="sorumluTelefonu">YETKİLİ TELEFONU*</label>
                                      <input type="tel" v-model="dealer.sorumluTelefonu" class="form-control" id="sorumluTelefonu" name="sorumluTelefonu" :class="{ 'is-invalid': submitted && $v.dealer.sorumluTelefonu.$error }">
                                      <div v-if="submitted && !$v.dealer.sorumluTelefonu.required" class="invalid-feedback">Firma yekili telefon numaranısını giriniz</div>
                                  </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col-md-12">
                                      <label for="aciklama">AÇIKLAMA*</label>
                                      <textarea class="form-control" v-model="dealer.aciklama" id="aciklama" name="aciklama" :class="{ 'is-invalid': submitted && $v.dealer.aciklama.$error }"></textarea>
                                      <div v-if="submitted && !$v.dealer.aciklama.required" class="invalid-feedback">Açıklama yazınız</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                            <div class="col">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <button type="submit" class="btnRed float-right" style="padding-top:4px!important;">Gönder <i class="fas fa-long-arrow-alt-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </form>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tab_id_3" role="tabpanel" aria-labelledby="tab_3">
          <div class="row justify-content-md-center">
            <div class="col col-lg-9 text-justify">
              <h1>Tab 3</h1>
              <p>Örnek İçerik</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'
    export default {
    name: "bussinesrentals",
    metaInfo: () => ({
      title: 'Kurumsal Kiralama',
      titleTemplate: '%s - PopyCar',
      htmlAttrs: {
        lang: 'tr',
        amp: true
      },
      meta: [{ name: 'description', content: 'Kurumsal Kiralama' }]
    }),
    data () {
      return {
          BayilikFormuAc: false,
          hataMesajiDurum: '',
          hataMesaji: false,
          hataMesajiBaslik: false,
          isLoading: false,
          fullPage: true,
          loader: 'bars',
          color: '#d2202f',
          dealer: {
              firmaAdi: '',
              firmaVergi: '',
              firmaVergiDairesi: '',
              aciklama: '',
              sorumluEpostasi: '',
              sorumluAdi: '',
              sorumluSoyadi: '',
              sorumluTelefonu: '',
          },
          submitted: false,
      }
    },
    validations: {
      dealer: {
            firmaAdi: { required },
            firmaVergi: { required },
            firmaVergiDairesi: { required },
            aciklama: { required },
            sorumluEpostasi: { required, email},
            sorumluAdi: { required },
            sorumluSoyadi: { required },
            sorumluTelefonu: { required },
        }
    },
    methods: {
          handleSubmit (e) {
              this.submitted = true
              this.$v.$touch()
              if (this.$v.$invalid) {
                return
              }
              let loader = this.$loading.show({
                container: this.$refs.formContainer,
                loader: this.loader,
                color: this.color,
                opacity: 0.90,
              });
              var params = JSON.stringify(this.dealer);
              axios.post(process.env.VUE_APP_API_URL+'kurumsalTalepFormu.php', params, {withCredentials: true, headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Access-Control-Allow-Origin': '*'
                }}).then(response => {
                loader.hide()
                this.hataMesajiDurum=response.data.basarili
                this.hataMesaji=response.data.mesaj
                this.hataMesajiBaslik=response.data.baslik
              })
          }
        },
    }
</script>

<style scoped>
.btnRed{
    background-color: #d2202f;
    color: #fff;
    text-decoration: none !important;
    margin: 15px 0px 0px;
    padding: 10px 18px 0px 18px !important;
    height: 40px;
    border: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}

</style>
